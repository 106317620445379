import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  ButtonsWrapper,
  CenteredContainer,
  ReadableContainer,
  PillButton,
  Header,
  ProgressBar,
  HighlightWord,
} from './common';
import { TIME_EFFORT, TOPICS_DATA } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

export default function AskTimeEffort() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const topic = state.context.topic;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_TIME_EFFORT,
    state.context
  );

  if (!topic) {
    return null;
  }

  const topicData = TOPICS_DATA.options[topic];

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={6} />
        <LabelUppercase>Step 6</LabelUppercase>
        <Header>
          <HighlightWord text={topicData.effortQuestion} word={topicData.label} />
        </Header>
        <ButtonsWrapper>
          {Object.values(TIME_EFFORT.options).map((timeEffort) => (
            <PillButton
              key={timeEffort.value}
              isSelected={state.context.timeEffort === timeEffort.value}
              onClick={() => {
                send({
                  type: EVENTS.SELECT_TIME_EFFORT,
                  timeEffort: timeEffort.value,
                });
                send({
                  type: EVENTS.CONTINUE_ASK_TIME_EFFORT,
                });
              }}
              label={timeEffort.label}
            />
          ))}
        </ButtonsWrapper>
      </CenteredContainer>
    </ReadableContainer>
  );
}