import styled, { css, CSSProperties } from 'styled-components';
import { prop } from 'styled-tools';
import { Link } from 'react-router-dom';
import { DashboardPayload } from '../../hydra';
import { SmallTooltip } from '../Tooltip';
const IS_CAREERIST = process.env.REACT_APP_IS_CAREERIST === 'true';

const ActivityCardWrapper = styled(Link)<{
  $isLocked: boolean;
  $isHighlighted: boolean;
  $isStretch: boolean;
  $isFirstActivityInMission: boolean;
  $isLastActivityInMission: boolean;
}>`
  text-decoration: none;
  padding: 12px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 100px;

  @media screen and (min-width: 1280px) and (max-width: 1365px) {
    height: 120px;
  }

  ${({ $isFirstActivityInMission }) =>
    $isFirstActivityInMission &&
    `
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  `}

  ${({ $isLastActivityInMission }) =>
    $isLastActivityInMission &&
    `
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `}

  transition: all 0.3s ease-in-out;

  ${({ $isStretch, $isLocked, $isHighlighted, theme }) =>
    $isLocked
      ? css`
          cursor: not-allowed;
          // pointer-events: none;
          opacity: 0.25;
        `
      : $isStretch
      ? css`
          padding: 11px;
          border-style: dashed;
          border-width: 1px;
          border-color: ${theme.colors.bgDefaultSecondary};
          background-color: ${theme.colors.bgDefaultSecondary};
          opacity: 0.7;

          &:hover {
            opacity: 0.9;
          }
          &:active {
            opacity: 1;
            transition: opacity 0s;
            transition: background-color 0s;
            transition: border-color 0s;
          }
        `
      : $isHighlighted
      ? css`
          cursor: pointer;
          opacity: 1;
          background-color: #fca92a1a;

          &:hover {
            opacity: 1;
            background-color: #fca92a26 !important;
          }

          &:active {
            opacity: 1;
            background-color: #fca92a33 !important;
            transition: opacity 0s;
            transition: background-color 0s;
            transition: border-color 0s;
          }
        `
      : css`
          cursor: pointer;
          opacity: 0.8;
          background-color: ${({ theme }) => theme.colors.bgDefault};

          &:hover {
            opacity: 0.9;
          }
          &:active {
            opacity: 1;
            transition: background-color 0s;
            transition: opacity 0s;
          }
        `}
`;

const ActivityCardColumn = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

// same as .small + bold styles
const ActivityTitle = styled.p<{
  isHighlighted: boolean;
}>`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${prop('theme.colors.text')};

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: #fca92a;
    `}

  padding-bottom: 0px;
  margin-top: 0px !important;
`;

// same as .uppercase-label styles
const ActivityCaption = styled.p<{
  isHighlighted: boolean;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  padding-bottom: 0px;
  margin-top: 0px !important;
  color: ${prop('theme.colors.text')};
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: #fca92a;
    `}
  opacity: 0.6;
`;

export default function ActivityCard({
  activity,
  style,
  isFirstActivityInMission,
  isLastActivityInMission,
  isHighlightingOutstanding,
  href,
  isLocked = false,
}: {
  activity: DashboardPayload['missions'][number]['activities'][number];
  style?: CSSProperties;
  isFirstActivityInMission: boolean;
  isLastActivityInMission: boolean;
  isHighlightingOutstanding: boolean;
  href: string;
  isLocked?: boolean;
}) {
  const isOutstanding = activity.isSent && !activity.isSubmitted;
  const shouldDim = !isHighlightingOutstanding || !isOutstanding;
  const isHighlighted = !shouldDim && activity.isCore;

  const typeLabel = activity.isCore ? 'CORE' : 'STRETCH';

  return (
    <>
      {isLocked && (
        <SmallTooltip
          anchorSelect={`.grid-activity-item-${activity.slug}-locked`}
          opacity={1}
        >
          You have not unlocked this activity yet.
        </SmallTooltip>
      )}
      <ActivityCardWrapper
        to={href}
        style={style}
        className={`grid-activity-item-${activity.slug}-${
          isLocked ? 'locked' : 'unlocked'
        } ${shouldDim ? 'dim-content' : ''} ${
          isHighlighted ? 'highlighted-content' : ''
        }`}
        $isFirstActivityInMission={isFirstActivityInMission}
        $isLastActivityInMission={isLastActivityInMission}
        $isStretch={!activity.isCore}
        $isHighlighted={isHighlighted}
        $isLocked={isLocked}
      >
        <ActivityCardColumn
          style={{
            margin: '5px 4px 4px 8px',
          }}
        >
          <ActivityTitle isHighlighted={isHighlighted}>
            {activity.title}
          </ActivityTitle>
          <ActivityCaption isHighlighted={isHighlighted}>
            {typeLabel}
          </ActivityCaption>
        </ActivityCardColumn>
        <ActivityCardColumn
          style={{
            marginLeft: '16px',
          }}
        >
          <ActivityIcon activity={activity} />
        </ActivityCardColumn>
      </ActivityCardWrapper>
    </>
  );
}

const ActivityIconWrapper = styled.i`
  color: ${({ color = prop('theme.colors.primary') }) => color};
  font-size: 16px;
`;

function ActivityIcon({
  activity,
}: {
  activity: DashboardPayload['missions'][number]['activities'][number];
}) {
  if (activity.instructorReview || activity.instructorScore) {
    return (
      <ActivityIconWrapper
        className="fa-solid fa-message-check"
        title="Reviewed"
      />
    );
  }

  if (IS_CAREERIST ? activity.isCompleted : activity.isSubmitted) {
    return (
      <ActivityIconWrapper
        className="fa-solid fa-circle-check"
        title="Submitted"
      />
    );
  }
  return (
    <ActivityIconWrapper
      className="fa-solid fa-circle-check"
      color="transparent"
      title=""
    />
  );
}
