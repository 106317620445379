import styled from 'styled-components';
import { CardIcon, CardPSmall } from '../ContentCard';
import { H4, P } from '../elements/Text';
import theme from '../../theme';

const Card = styled.div`
  grid-column: span 2;
  border-radius: 8px;
  background-color: ${theme.colors.bgActive};
  transition: all 0.3s ease-in-out;
  border: 1px solid ${theme.colors.bgActive};
  padding: 20px;
`;

const ActivityCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.bgActive};
  width: 242px;
  display: flex;
  padding: 0;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 664px) {
    width: 100%;
    margin-top: 20px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  @media screen and (max-width: 664px) {
    flex-direction: column;
  }
`;

const HeaderRow = styled(Row)`
  gap: 16px;
  justify-content: flex-start;
`;

const HeaderText = styled(H4)`
  margin: 0;
  padding: 0;
  font-weight: 700;
`;

const InfoSection = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 664px) {
    width: 100%;
  }

  justify-content: space-between;
`;

const PrimaryButtonContainer = styled.div<{ shouldRoundBottom: boolean }>`
  border-radius: 8px 8px
    ${({ shouldRoundBottom }) => (shouldRoundBottom ? '8px 8px' : '0px 0px')};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bgActive};
  transition: all 300ms ease-in-out;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
  }
  padding: 10px 20px 0px 20px;
  cursor: pointer;
`;
const ActivityLabel = styled(P)`
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 25px;
  color: ${theme.colors.textActive};
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  line-height: 33px;
`;

const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0px;
  margin-top: 6px;
  height: 45px;
`;

const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0px;
  padding: 0px 20px 0px 20px;
  cursor: pointer;
  height: 46px;
  transition: all 300ms ease-in-out;
  border-radius: 0px 0px 8px 8px;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
  }
`;

const ButtonLabel = styled(P)`
  color: ${({ theme }) => theme.colors.textRegular};
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
`;
const ButtonIcon = styled.i`
  color: ${({ theme }) => theme.colors.textRegular};
  font-size: 16px;
`;

const TopicSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const MissionCountLink = styled.a`
  justify-self: end;
  cursor: pointer;
  text-decoration: none;
`;

export default function CTACard({
  title,
  nextItemTitle,
  description,
  imageUrl,
  dashboardUrl,
  primaryButton,
  secondaryButton,
  progressText,
}: {
  title: string;
  nextItemTitle: string;
  description: string;
  imageUrl: string;
  dashboardUrl: string | null;
  primaryButton: {
    ctaLabel: string;
    onCTAClick: () => void;
  };
  secondaryButton: null | {
    ctaLabel: string;
    onCTAClick: () => void;
  };
  progressText: string;
}) {
  return (
    <Card>
      <Row>
        <InfoSection style={{ flex: 1 }}>
          <TopicSection>
            <HeaderRow>
              <CardIcon url={imageUrl} title={title} />
              <HeaderText>{title}</HeaderText>
            </HeaderRow>
            <CardPSmall style={{ marginTop: 23 }}>{description}</CardPSmall>
          </TopicSection>
          {dashboardUrl ? (
            <MissionCountLink href={dashboardUrl}>
              <CardPSmall>{progressText}</CardPSmall>
            </MissionCountLink>
          ) : (
            <CardPSmall style={{ justifySelf: 'flex-end' }}>
              {progressText}
            </CardPSmall>
          )}
        </InfoSection>
        <ActivityCard>
          <PrimaryButtonContainer
            onClick={primaryButton.onCTAClick}
            shouldRoundBottom={secondaryButton ? false : true}
          >
            <ActivityLabel>{nextItemTitle}</ActivityLabel>
            <PrimaryButton>
              <ButtonLabel>{primaryButton.ctaLabel}</ButtonLabel>
              <ButtonIcon className="fa-solid fa-forward" />
            </PrimaryButton>
          </PrimaryButtonContainer>

          {secondaryButton && (
            <SecondaryButton onClick={secondaryButton.onCTAClick}>
              <ButtonLabel>{secondaryButton.ctaLabel}</ButtonLabel>
              <ButtonIcon className="fa-solid fa-calendar-days" />
            </SecondaryButton>
          )}
        </ActivityCard>
      </Row>
    </Card>
  );
}
