import styled from 'styled-components';
import {
  Header,
  ReadableContainer,
  CenteredContainer,
  HighlightSpan,
  CTAButton,
  CheckIcon,
  Label,
} from '../SoloOnboarding/Screens/common';
import {
  StaticPlanExplanationResponse,
  StaticLearningPlanUnit,
} from '../../hydra';
import LearningPlanUnitCard, {
  LEARNING_PLAN_UNIT_TYPE,
  LearningPlanUnit as LearningPlanUnitType,
} from './LearningPlanUnit';

const LearningPlanWrapper = styled.div`
  width: 730px;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BulletWrapper = styled.div``;

const BulletLabel = styled(Label)`
  margin: 10px;
`;

export function StaticLearningPlan({
  learningPlan,
  planExplanation,
  onNext,
}: {
  learningPlan: StaticLearningPlanUnit[];
  planExplanation: StaticPlanExplanationResponse | null;
  onNext?: () => void;
}) {
  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Your <HighlightSpan>personalized plan</HighlightSpan> is ready!
        </Header>
        {planExplanation && <Label>{planExplanation.summary}</Label>}
        <LearningPlanWrapper>
          {learningPlan.map((unit, ind) => (
            <LearningPlanUnitCard
              key={unit.slug}
              learningUnit={mapLearningPlanUnit(unit)}
              index={ind + 1}
              numItems={learningPlan.length}
              isPreviewingPlan
            />
          ))}
        </LearningPlanWrapper>
        <BulletWrapper>
          {planExplanation &&
            planExplanation.milestones.map((b) => (
              <BulletLabel key={b}>
                <CheckIcon className="fas fa-check" />
                {b}
              </BulletLabel>
            ))}
        </BulletWrapper>
        <Label style={{ color: 'rgba(228, 228, 231, 1)' }}>
          <i>Your plan will adapt to your activity or requests in real-time.</i>
        </Label>
        <CTAButton onClick={onNext} label="Great, continue!" />
      </CenteredContainer>
    </ReadableContainer>
  );
}

function mapLearningPlanUnit(
  unit: StaticLearningPlanUnit
): LearningPlanUnitType {
  if (unit.type === 'content-module') {
    return {
      type: LEARNING_PLAN_UNIT_TYPE.CONTENT_MODULE,
      slug: unit.slug,
      unitTitle: unit.content.title,
      moduleTitle: unit.content.topic,
      nextItemTitle: unit.content.title,
      reasoning: unit.reasoning,
      description: unit.content.description,
      subtitle: 'MODULE',
      imageUrl: unit.content.imageUrl,
      onClick: () => {},
      isCompleted: false,
      isInProgress: false,
      dashboardUrl: null,
      progressDescription: `${unit.content.lessonsCount} lessons`,
      isLocked: false,
    };
  } else {
    return {
      type: LEARNING_PLAN_UNIT_TYPE.PROJECT,
      slug: unit.slug,
      unitTitle: unit.project.name,
      moduleTitle: unit.project.topic,
      nextItemTitle: unit.project.name,
      reasoning: unit.reasoning,
      description: unit.project.description,
      subtitle: 'PROJECT',
      imageUrl: unit.project.imageUrl,
      onClick: () => {},
      isCompleted: false,
      isInProgress: false,
      dashboardUrl: null,
      progressDescription: `${unit.project.questionCount} exercises`,
      isLocked: false,
    };
  }
}
