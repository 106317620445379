import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type ClearResultAndReviewActionType = {
  type: typeof ACTIVITY_ACTION.CLEAR_RESULT_AND_REVIEW;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
  };
};

export function clearResultAndReviewReducer(
  state: ActivityContextType,
  action: ClearResultAndReviewActionType
): ActivityContextType {
  const { question } = action.payload;

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      result: null,
      review: null,
      isCorrect: null,
      isAnswered: false,
    },
  });
}

export type ChangeValueQuestionLLMPlaygroundType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_LLM_PLAYGROUND;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};

export function updateLLMPromptReducer(
  state: ActivityContextType,
  action: ChangeValueQuestionLLMPlaygroundType
): ActivityContextType {
  const { question, value } = action.payload;

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      value,
      isRan: false,
    },
  });
}
