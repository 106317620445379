import { H1 } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextFreeTextAIVerifiedQuestionAnswer,
} from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  getQuestionText,
} from './common';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';
import Button from '../../elements/Button';
import { useFreeTextValidationPoller } from '../../../hooks/use-activity-question-validation-polling';
import AIReview from '../AIComment';
import { toast } from 'react-toastify';

export default function FreeTextAIVerifiedQuestion({
  question,
  answer,
  submitAnswer,
  getActivity,
}: QuestionProps & {
  answer: ActivityContextFreeTextAIVerifiedQuestionAnswer;
}) {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const [checked, setChecked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];
  const { pollActivity, error: validationError } = useFreeTextValidationPoller({
    getActivity,
    dispatch,
  });
  React.useEffect(() => {
    if (checked && !isLoading) {
      if (answer.isCorrect) {
        toast.success('Correct!');
      } else if (answer.isCorrect === false) {
        toast.warn('Not quite!');
      }
      setChecked(false); // Reset after showing the toast
    }
  }, [answer.isCorrect, checked, isLoading]);

  React.useEffect(() => {
    if (checked && validationError) {
      toast.warn(
        'Something went wrong verifying your answer. Please try again.'
      );
      setChecked(false);
    }
  }, [checked, validationError]);

  const onFreeTextAIVerifiedQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_AI_VERIFIED_QUESTION,
      payload: {
        question,
        answer: value,
      },
    });
  };

  const onVerifyAnswer = React.useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dispatch({
      type: ACTIVITY_ACTION.CLEAR_RESULT_AND_REVIEW,
      payload: {
        question,
      },
    });

    await submitAnswer({
      value: answer.value,
      questionSlug: question.slug,
    });

    await pollActivity(answer.questionSlug);

    setIsLoading(false);
    setChecked(true);
  }, [isLoading, dispatch, answer, question, submitAnswer, pollActivity]);

  return (
    <FullScreenQuestion>
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) =>
            onFreeTextAIVerifiedQuestionTextChange(e.target.value)
          }
        />
        <ButtonsRow>
          <Button
            style={{ marginTop: 0, marginBottom: 20 }}
            isLoading={isLoading}
            isDisabled={!answer.value}
            type="secondary"
            label="Check Answer"
            faIcon="fa-solid fa-sparkles"
            onClick={onVerifyAnswer}
          />
        </ButtonsRow>
        {!!answer.review && <AIReview comment={answer.review} />}
      </ReadableContainer>
      <BottomSpacer />
    </FullScreenQuestion>
  );
}
