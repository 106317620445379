import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  Header,
  ButtonsWrapper,
  CenteredContainer,
  PillButton,
  ProgressBar,
  ReadableContainer,
  CTAButton,
  HighlightSpan,
  Label,
} from './common';
import { APPLICATIONS, TOPICS_DATA } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

export default function AskApplications() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const topic = state?.context?.topic;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_APPLICATIONS,
    state.context
  );

  if (!topic) {
    return null;
  }

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={3} />
        <LabelUppercase>Step 3</LabelUppercase>
        <Header>
          {getApplicationQuestion(topic)}
        </Header>
        <Label>
          Choose all that sound interesting. We’ll use this to design your
          learning plan.
        </Label>
        <ButtonsWrapper>
          {Object.values(APPLICATIONS.options).map((option) => (
            <PillButton
              key={option.value}
              isSelected={state?.context?.applications?.includes(option.value)}
              onClick={() =>
                send({
                  type: EVENTS.SELECT_APPLICATIONS,
                  applications: state.context.applications?.includes(
                    option.value
                  )
                    ? [
                        ...state.context.applications.filter(
                          (a) => a != option.value
                        ),
                      ]
                    : [...(state.context.applications ?? []), option.value],
                })
              }
              label={`${option.label}`}
            />
          ))}
        </ButtonsWrapper>

        <CTAButton
          isDisabled={!state?.context?.applications?.length}
          onClick={() => send(EVENTS.CONTINUE_ASK_APPLICATIONS)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}

function getApplicationQuestion(topic: string) {
  const [pre, post] = TOPICS_DATA.options[topic].applicationQuestion.split(TOPICS_DATA.options[topic].label);
  return (
    <>
      {pre}
      <HighlightSpan>{TOPICS_DATA.options[topic].label}</HighlightSpan>
      {post}
    </>
  )
}
