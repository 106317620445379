import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextSQLPlaygroundQuestionAnswer,
} from '../../../contexts/activity';
import { H1 } from '../../elements/Text';
import React, { useEffect } from 'react';
import { ACTIVITY_STEP } from '../Content';
import SQLPlayground from '../../SQLPlayground';
import useSQLRunner from '../../SQLPlayground/use-sql-runner';
import { SQLResult } from '../../../contexts/activity/helpers/is-sql-playground-question-correct';
import { QuestionProps } from '.';
import { BottomSpacer, SplitScreenQuestion, getQuestionText } from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import { toast } from 'react-toastify';
import MentorReview from '../MentorComment';
import IncorrectAnswerToast from '../../Toasts/TryAgain';
import {
  AIWorkflow,
  MESSAGE_CONTEXT_TYPES,
} from '../../../contexts/ai-workflow';
import { usePreviousDistinct } from 'react-use';

export default function SQLPlaygroundQuestion({
  question,
  answer,
}: Omit<QuestionProps, 'answer'> & {
  answer: ActivityContextSQLPlaygroundQuestionAnswer;
}) {
  const { setMessageContext } = React.useContext(AIWorkflow);
  const [activity, dispatch] = React.useContext(ActivityContext);
  const prevAnswer = usePreviousDistinct(answer);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const { runQuery, resetDb, schemas, isLoading, error } = useSQLRunner({
    id: question.id,
    setupSQL: question.setup_sql,
  });

  const onHint = React.useCallback(() => {
    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.QUESTION_HINT,
      context_content: {
        question_index: qIndex,
        attempted_answer: answer.value ?? '',
        is_correct: answer.isCorrect,
      },
    });
  }, [answer, qIndex, setMessageContext]);

  const onQueryChange = (query: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_QUERY_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        query,
      },
    });
  };

  const onReset = () => {
    resetDb();
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_QUERY_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        query: '',
      },
    });
  };

  useEffect(() => {
    if (prevAnswer?.questionSlug === answer.questionSlug && answer.isRan) {
      if (answer.isCorrect) {
        toast.success('Correct!');
      } else if (answer.isCorrect === false) {
        toast.warn(() => <IncorrectAnswerToast onHint={onHint} />);
      }
    }
  }, [answer, onHint, prevAnswer]);

  const onRunQuery = React.useCallback(
    (query: string) => {
      const result = runQuery(query) as SQLResult;
      const verificationResult = runQuery(
        question.verification_sql || question.answer_dql
      ) as SQLResult;

      dispatch({
        type: ACTIVITY_ACTION.SET_RESULT_SQL_PLAYGROUND_QUESTION,
        payload: {
          question,
          result,
          verificationResult,
        },
      });
    },
    [dispatch, question, runQuery]
  );

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        <ReadableMarkdown md={getQuestionText(question)} />
        <BottomSpacer />
      </>
      <SQLPlayground
        schemas={schemas}
        query={answer.value ?? ''}
        onQueryChange={onQueryChange}
        onRunQuery={onRunQuery}
        onReset={onReset}
        isLoading={isLoading}
        result={answer.result as SQLResult}
        error={error as unknown as Error}
      />
    </SplitScreenQuestion>
  );
}
