import styled from 'styled-components';
import { H3, P } from '../elements/Text';
import LearningPlanUnitCard, { LearningPlanUnit } from './LearningPlanUnit';
import {
  ShimmerDiv,
  SkeletonDiv,
  SkeletonLoaderWrapper,
} from '../SkeletonLoader';

const PlanWrapper = styled.div`
  border-radius: 8px;
  padding: 0;
  margin-top: 32px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const PlanCardsWrapper = styled.div`
  display: flex;
  gap: 0px;
  width: 100%;
  margin: 28px 0px;
  flex-direction: column;
`;

const SkeletonBar = styled(SkeletonDiv)`
  height: 82px;
  width: 100%;
`;

export default function LearningPlan({
  learningPlan,
  infoText,
}: {
  learningPlan?: Array<LearningPlanUnit>;
  infoText: string;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <H3>Your Learning Plan</H3>
      <PlanWrapper>
        {learningPlan ? (
          <>
            <P style={{ marginTop: 0 }}>{infoText}</P>
            <PlanCardsWrapper>
              {learningPlan.map((unit, ind) => (
                <LearningPlanUnitCard
                  key={unit.slug}
                  learningUnit={unit}
                  index={ind + 1}
                  numItems={learningPlan.length}
                />
              ))}
            </PlanCardsWrapper>
          </>
        ) : (
          <SkeletonLoaderWrapper>
            <ShimmerDiv />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
          </SkeletonLoaderWrapper>
        )}
      </PlanWrapper>
    </div>
  );
}
