import React, { Dispatch } from 'react';
import isEmpty from 'lodash.isempty';
import { addActionBreadcrumb } from '../helpers';
import getDefaultOpenQuestionIndex from './helpers/get-default-open-question-index';
import isSubmissionDifferent from './helpers/is-submission-different';
import { ActivityPayload, AnswerState } from '../../hydra';
import sqlPlaygroundQueryChangeReducer, {
  ChangeQuerySqlPlaygroundQuestionActionType,
} from './reducers/sql-playground-change-query';
import sqlPlaygroundResultChangeReducer, {
  SetResultSqlPlaygroundQuestionActionType,
} from './reducers/sql-playground-set-result';
import {
  SetResultPythonPlaygroundQuestionActionType,
  pythonPlaygroundResultChangeReducer,
} from './reducers/python-playground-set-result';
import questionValueChangeReducer, {
  ChangeValueFreeTextGoogleSheetsExternalQuestionActionType,
  ChangeValueFreeTextPowerBiExternalQuestionActionType,
  ChangeValueFreeTextQuestionActionType,
  ChangeValueFreeTextSqlPlaygroundQuestionActionType,
  ChangeValueGoogleSheetsExternalQuestionActionType,
  ChangeValueQuestionFreeTextLookerEmbedActionType,
  ChangeValueFreeTextTableauEmbedQuestionActionType,
  ChangeValueLookerExternalQuestionActionType,
  ChangeValueTableauExternalQuestionActionType,
  ChangeValueExternalDocSelfCheckQuestionActionType,
} from './reducers/free-text-question-change-value';
import sqlFreeTextPlaygroundQueryChangeReducer, {
  ChangeQueryFreeTextSqlPlaygroundQuestionActionType,
} from './reducers/free-text-sql-playground-change-query';
import sqlFreeTextPlaygroundResultChangeReducer, {
  SetResultFreeTextSqlPlaygroundQuestionActionType,
} from './reducers/free-text-sql-playground-set-result';
import pythonPlaygroundChangeValueReducer, {
  ChangeValuePythonPlaygroundQuestionActionType,
} from './reducers/python-playground-change-value';
import { ACTIVITY_STEP } from '../../components/Activity/Content';
import getActivityInitialStep from './helpers/get-activity-step';
import {
  ChangeAnswerMultipleChoiceQuestionActionType,
  ChangeCodeMultipleChoiceQuestionActionType,
  multipleChoiceAnswerChangeReducer,
  multipleChoiceCodeChangeReducer,
} from './reducers/multiple-choice';
import {
  ChangeValueQuestionLLMPlaygroundType,
  ClearResultAndReviewActionType,
  clearResultAndReviewReducer,
  updateLLMPromptReducer,
} from './reducers/clear-result-and-review';
import { QUESTION_TYPE } from '../../components/Activity/Question';
import { SQLResult } from './helpers/is-sql-playground-question-correct';
import {
  ChangeAnswerStrictTextQuestionActionType,
  strictQuestionChangeReducer,
  strictQuestionVerifyReducer,
  VerifyAnswerStrictTextQuestionActionType,
} from './reducers/strict-text-question';
import {
  ChangeAnswerFreeTextAIVerifiedQuestionActionType,
  freeTextAIVerifiedQuestionChangeReducer,
} from './reducers/free-text-ai-verified-question';

export type ActivityContextFreeTextQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType:
    | QUESTION_TYPE.FREE_TEXT
    | QUESTION_TYPE.FREE_TEXT_LOOKER_EMBED
    | QUESTION_TYPE.FREE_TEXT_POWERBI_EXTERNAL
    | QUESTION_TYPE.FREE_TEXT_SHEETS_EMBED
    | QUESTION_TYPE.SHEETS_EXTERNAL
    | QUESTION_TYPE.TABLEAU_EXTERNAL
    | QUESTION_TYPE.LOOKER_EXTERNAL
    | QUESTION_TYPE.FREE_TEXT_TABLEAU_EMBED
    | QUESTION_TYPE.FREE_TEXT_SQL_CODE
    | QUESTION_TYPE.FREE_TEXT_SELF_CHECK;
  value: string;
};

export type ActivityContextFreeTextAIVerifiedQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType: QUESTION_TYPE.FREE_TEXT_AI_VERIFIED;
  value: string;
  review: string | null;
};

export type ActivityContextStrictTextQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType: QUESTION_TYPE.STRICT_TEXT;
  value: string;
};

export type ActivityContextMultipleChoiceQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType:
    | QUESTION_TYPE.PYTHON_MULTIPLE_CHOICE
    | QUESTION_TYPE.SQL_MULTIPLE_CHOICE;
  value: string;
  isRan: boolean | null;
  working_code: string;
  result: SQLResult | string | null;
};

export type ActivityContextLLMPlaygroundQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  doesSatisfyAllRequirements: boolean | null;
  questionType: QUESTION_TYPE.LLM_PLAYGROUND;
  value: string;
  review: string | null;
  result: string | null;
  isRan: boolean | null;
};

export type ActivityContextPythonPlaygroundQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType: QUESTION_TYPE.PYTHON_PLAYGROUND;
  value: string;
  result: string | null;
  isRan: boolean | null;
};

export type ActivityContextSQLPlaygroundQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType: QUESTION_TYPE.SQL_PLAYGROUND;
  value: string;
  result: SQLResult;
  isRan: boolean | null;
};

export type ActivityContextFreeTextSQLPlaygroundQuestionAnswer = {
  questionSlug: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  questionType: QUESTION_TYPE.FREE_TEXT_SQL_PLAYGROUND;
  value: string;
  result: SQLResult;
  isRan: boolean | null;
  working_code?: string;
};
export type ActivityContextQuestionAnswer =
  | ActivityContextFreeTextQuestionAnswer
  | ActivityContextMultipleChoiceQuestionAnswer
  | ActivityContextLLMPlaygroundQuestionAnswer
  | ActivityContextPythonPlaygroundQuestionAnswer
  | ActivityContextSQLPlaygroundQuestionAnswer
  | ActivityContextFreeTextSQLPlaygroundQuestionAnswer
  | ActivityContextStrictTextQuestionAnswer
  | ActivityContextFreeTextAIVerifiedQuestionAnswer;

export type ActivityStepType =
  | {
      type: ACTIVITY_STEP.LESSON;
    }
  | {
      type: ACTIVITY_STEP.QUESTION;
      questionIndex: number;
      questionSlug: string;
    }
  | {
      type: ACTIVITY_STEP.FEEDBACK;
    }
  | null;

export type AIReview = {
  lesson_takeaways: string[];
  progress_suggestions: string | null;
  further_insights: string | null;
};

export enum ACTIVITY_TYPE {
  ACTIVITY = 'ACTIVITY',
  PROJECT = 'PROJECT',
}

export type ActivityContextType = {
  type: ACTIVITY_TYPE;
  id: number;
  title: string;
  slug: string;
  exercise: ActivityPayload['exercise'];
  content_id: number;
  mission_id: number | null;
  content_title: string;
  activity_session_id: number;
  latestSavedSubmission: null | {
    id: number;
    updated_at: string;
    created_at: string;
    difficulty_rating: number | null;
    feedback: string | null;
    seconds_spent: number | null;
    activity_session_id: number;
    ai_review: AIReview | null;
    answer_states: Record<string, AnswerState> | null;
  };

  mentorReview: {
    hasMentor: boolean;
    sessionId: number;
    isReviewed: boolean;
    review: string | null;
    score: number | null;
    questionComments: string[] | null;
  };

  submission: {
    currentSubmissionStatus: string;
    difficultyRating: number | null;
    feedback: string;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
    // isSubmitted: boolean;
  };

  pageFocusTimestampIntervals: Array<[Date, Date | undefined]>;
  didInteract: boolean;
  hasUnsavedWork: boolean;
  activeQuestionIndex: number;

  currentStep: ActivityStepType;
};

// const defaultDispatch: React.Dispatch<ActivityActionType> = () => {};

function getInitialState(): ActivityContextType {
  return {
    type: ACTIVITY_TYPE.ACTIVITY,
    id: -1,
    title: '',
    slug: '',
    exercise: {
      id: -1,
      updated_at: '',
      created_at: '',
      description: '',
      lesson: '',
      difficulty: 0,
      activity_id: null,
      slug: '',
      questions: [],
    },
    content_id: -1,
    activity_session_id: -1,
    mission_id: null,
    content_title: '',
    latestSavedSubmission: null,
    mentorReview: {
      hasMentor: false,
      sessionId: 0,
      isReviewed: false,
      review: null,
      score: null,
      questionComments: null,
    },
    submission: {
      currentSubmissionStatus: 'INITIAL',
      difficultyRating: null,
      feedback: '',
      questionSlugToAnswer: {},
      // isSubmitted: false,
    },
    pageFocusTimestampIntervals: [],
    didInteract: false,
    hasUnsavedWork: false,
    activeQuestionIndex: 0,

    currentStep: null,
  };
}

export const ActivityContext = React.createContext<
  [ActivityContextType, Dispatch<ActivityActionType>]
>([getInitialState(), () => {}]);

export function ActivityProvider({
  activity,
  type,
  children,
}: {
  activity: ActivityPayload;
  type: ACTIVITY_TYPE;
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(
    activityReducer,
    initialStateActivity({ activity, type })
  );

  React.useEffect(() => {
    dispatch({
      type: ACTIVITY_ACTION.REFRESH_SUBMISSION,
      payload: {
        activity,
      },
    });
  }, [activity]);

  return (
    <ActivityContext.Provider value={[state, dispatch]}>
      {children}
    </ActivityContext.Provider>
  );
}

export const initialStateActivity = ({
  activity,
  type,
}: {
  activity: ActivityPayload;
  type: ACTIVITY_TYPE;
}): ActivityContextType => {
  return {
    type,
    id: activity.id,
    title: activity.title,
    slug: activity.slug,
    exercise: activity.exercise,
    content_id: activity.content_id,
    mission_id: activity.mission_id,
    content_title: activity.content_title,
    latestSavedSubmission: activity.latest_submission,
    mentorReview: {
      hasMentor: activity.has_mentor,
      sessionId: activity.activity_session_id,
      isReviewed:
        !!activity.activity_session_instructor_review ||
        !!activity.activity_session_instructor_score,
      review: activity.activity_session_instructor_review,
      score: activity.activity_session_instructor_score,
      questionComments: activity.activity_session_instructor_question_comments,
    },
    activity_session_id: activity.activity_session_id,
    submission: {
      currentSubmissionStatus: 'INITIAL',
      difficultyRating: activity?.latest_submission?.difficulty_rating ?? null,
      feedback: activity?.latest_submission?.feedback ?? '',
      questionSlugToAnswer: initialQuestionsState(activity),
    },
    pageFocusTimestampIntervals: [],
    didInteract: false,
    hasUnsavedWork: false,
    activeQuestionIndex: getDefaultOpenQuestionIndex(activity),
    currentStep: getActivityInitialStep(activity),
  };
};

// For each question, the `value` field is what gets submitted as the solution.
// For playground related questions, the current value of the query string is
// stored in `query` and that value is put into `value` field when the query is ran.
// For the free text question, the `value` is updated on change.
const initialQuestionsState = (activity: ActivityPayload) => {
  const isSubmitted = !isEmpty(activity.latest_submission);

  return (activity?.exercise?.questions ?? []).reduce((hash, question) => {
    const answerState =
      activity?.latest_submission?.answer_states?.[question.slug] ?? null;

    if (
      [
        QUESTION_TYPE.FREE_TEXT,
        QUESTION_TYPE.FREE_TEXT_LOOKER_EMBED,
        QUESTION_TYPE.FREE_TEXT_POWERBI_EXTERNAL,
        QUESTION_TYPE.FREE_TEXT_SHEETS_EMBED,
        QUESTION_TYPE.SHEETS_EXTERNAL,
        QUESTION_TYPE.TABLEAU_EXTERNAL,
        QUESTION_TYPE.LOOKER_EXTERNAL,
        QUESTION_TYPE.FREE_TEXT_TABLEAU_EMBED,
        QUESTION_TYPE.FREE_TEXT_SQL_CODE,
        QUESTION_TYPE.FREE_TEXT_SELF_CHECK,
      ].includes(question.type)
    ) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType:
          question.type as ActivityContextFreeTextQuestionAnswer['questionType'],
        value: (answerState?.value as string) ?? '',
        isAnswered: isSubmitted ? !!answerState?.value : false,
        isCorrect: null,
      };
    }

    if (question.type === QUESTION_TYPE.STRICT_TEXT) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType: QUESTION_TYPE.STRICT_TEXT,
        value: (answerState?.value as string) ?? '',
        isAnswered: isSubmitted ? !!answerState?.value : false,
        isCorrect: answerState?.is_correct ?? null,
      };
    }

    if (question.type === QUESTION_TYPE.PYTHON_PLAYGROUND) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType: question.type,
        value: ((answerState?.value as string) || question.initial_py) ?? '',
        result: null,
        isAnswered: isSubmitted ? !!answerState?.is_submitted : false,
        isCorrect: answerState?.is_correct ?? null,
        isRan: null,
      };
    }

    if (
      [
        QUESTION_TYPE.PYTHON_MULTIPLE_CHOICE,
        QUESTION_TYPE.SQL_MULTIPLE_CHOICE,
      ].includes(question.type)
    ) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType:
          question.type as ActivityContextMultipleChoiceQuestionAnswer['questionType'],
        value: (answerState?.value as string) ?? '',
        isAnswered: isSubmitted ? !!answerState?.value : false,
        isCorrect: answerState?.is_correct ?? null,
        isRan: false,
        result: null,
        working_code: (answerState?.working_code || question.initial_py) ?? '',
      };
    }

    if (question.type === QUESTION_TYPE.SQL_PLAYGROUND) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType: question.type,
        value: (answerState?.value as string) ?? '', // this is what gets submitted
        result: { type: 'EMPTY', value: undefined },
        isAnswered: isSubmitted ? !!answerState?.is_submitted : false,
        isCorrect: answerState?.is_correct ?? null,
        isRan: null,
      };
    }

    if (question.type === QUESTION_TYPE.FREE_TEXT_SQL_PLAYGROUND) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType: question.type,
        value: (answerState?.value as string) ?? '', // this should be the free text answer
        working_code: answerState?.working_code, // we don't save these
        result: { type: 'EMPTY', value: undefined },
        isAnswered: isSubmitted ? !!answerState?.value : false,
        isCorrect: answerState?.is_correct ?? null,
        isRan: null,
      };
    }

    if (question.type === QUESTION_TYPE.FREE_TEXT_AI_VERIFIED) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType: QUESTION_TYPE.FREE_TEXT_AI_VERIFIED,
        value: (answerState?.value as string) ?? '',
        isAnswered: isSubmitted ? !!answerState?.value : false,
        isCorrect: answerState?.is_correct ?? null,
        review: answerState?.review ?? null,
      };
    }

    if (question.type === QUESTION_TYPE.LLM_PLAYGROUND) {
      hash[question.slug] = {
        questionSlug: question.slug,
        questionType: QUESTION_TYPE.LLM_PLAYGROUND,
        value: answerState?.value ?? question.llm_initial_prompt ?? '',
        isAnswered: isSubmitted ? !!answerState?.review : false,
        isCorrect: answerState?.is_correct ?? null,
        doesSatisfyAllRequirements:
          answerState?.does_satisfy_all_requirements ?? null,
        isRan: null,
        review: answerState?.review ?? null,
        result: answerState?.result ?? null,
      };
    }
    return hash;
  }, {} as Record<string, ActivityContextQuestionAnswer>);
};

export const ACTIVITY_ACTION = {
  RECORD_VISIBILITY_CHANGE: 'RECORD_VISIBILITY_CHANGE' as const,
  SET_SUBMISSION_STATUS: 'SET_SUBMISSION_STATUS' as const,
  SET_DIFFICULTY: 'SET_DIFFICULTY' as const,
  SET_FEEDBACK: 'SET_FEEDBACK' as const,
  CLEAR_RESULT_AND_REVIEW: 'CLEAR_RESULT_AND_REVIEW' as const,
  SET_ACTIVE_QUESTION_INDEX: 'SET_ACTIVE_QUESTION_INDEX' as const,
  CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION' as const,
  CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION:
    'CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION' as const,
  SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION:
    'SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION' as const,
  CHANGE_QUERY_SQL_PLAYGROUND_QUESTION:
    'CHANGE_QUERY_SQL_PLAYGROUND_QUESTION' as const,
  SET_RESULT_SQL_PLAYGROUND_QUESTION:
    'SET_RESULT_SQL_PLAYGROUND_QUESTION' as const,
  CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION:
    'CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION:
    'CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION:
    'CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_QUESTION: 'CHANGE_VALUE_FREE_TEXT_QUESTION' as const,
  CHANGE_VALUE_STRICT_TEXT_QUESTION:
    'CHANGE_VALUE_STRICT_TEXT_QUESTION' as const,
  VERIFY_ANSWER_STRICT_TEXT_QUESTION:
    'VERIFY_ANSWER_STRICT_TEXT_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_AI_VERIFIED_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_AI_VERIFIED_QUESTION' as const,

  CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED:
    'CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED' as const,
  CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION:
    'CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION' as const,
  CHANGE_VALUE_QUESTION_LLM_PLAYGROUND:
    'CHANGE_VALUE_QUESTION_LLM_PLAYGROUND' as const,
  CHANGE_VALUE_FREE_TEXT_SELF_CHECK_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_SELF_CHECK_QUESTION' as const,
  SET_RESULT_PYTHON_PLAYGROUND_QUESTION:
    'SET_RESULT_PYTHON_PLAYGROUND_QUESTION' as const,
  CHANGE_ANSWER_MULTIPLE_CHOICE_QUESTION:
    'CHANGE_ANSWER_MULTIPLE_CHOICE_QUESTION' as const,
  CHANGE_CODE_MULTIPLE_CHOICE_QUESTION:
    'CHANGE_CODE_MULTIPLE_CHOICE_QUESTION' as const,
  SET_PAGE_STEP: 'SET_PAGE_STEP' as const,
  REFRESH_SUBMISSION: 'REFRESH_SUBMISSION' as const,
  REFRESH_AI_REVIEW: 'REFRESH_AI_REVIEW' as const,
};
export type ActivityActionType =
  | RecordVisibilityActionType
  | SetSubmissionStatusActionType
  | SetDifficultyActionType
  | SetFeedbackActionType
  | SetActiveQuestionIndexActionType
  | ChangeValueFreeTextGoogleSheetsExternalQuestionActionType
  | ChangeValueLookerExternalQuestionActionType
  | ChangeValueGoogleSheetsExternalQuestionActionType
  | ChangeValueFreeTextPowerBiExternalQuestionActionType
  | ChangeValueFreeTextQuestionActionType
  | ChangeAnswerStrictTextQuestionActionType
  | VerifyAnswerStrictTextQuestionActionType
  | ChangeValueFreeTextTableauEmbedQuestionActionType
  | ChangeValueFreeTextGoogleSheetsExternalQuestionActionType
  | ChangeValueQuestionFreeTextLookerEmbedActionType
  | ChangeValueQuestionLLMPlaygroundType
  | ChangeValuePythonPlaygroundQuestionActionType
  | ChangeValueFreeTextSqlPlaygroundQuestionActionType
  | ChangeValueTableauExternalQuestionActionType
  | SetResultSqlPlaygroundQuestionActionType
  | SetResultPythonPlaygroundQuestionActionType
  | ChangeQuerySqlPlaygroundQuestionActionType
  | ChangeQueryFreeTextSqlPlaygroundQuestionActionType
  | SetResultPythonPlaygroundQuestionActionType
  | SetResultFreeTextSqlPlaygroundQuestionActionType
  | SetPageStep
  | RefreshSubmissionActionType
  | ChangeCodeMultipleChoiceQuestionActionType
  | ChangeAnswerMultipleChoiceQuestionActionType
  | ClearResultAndReviewActionType
  | ChangeAnswerFreeTextAIVerifiedQuestionActionType
  | ChangeValueExternalDocSelfCheckQuestionActionType;

function activityReducer(
  state: ActivityContextType,
  action: ActivityActionType
): ActivityContextType {
  addActionBreadcrumb({
    context: 'activity-submission',
    actionType: action.type,
  });

  switch (action.type) {
    case ACTIVITY_ACTION.CHANGE_QUERY_SQL_PLAYGROUND_QUESTION: {
      return sqlPlaygroundQueryChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_RESULT_SQL_PLAYGROUND_QUESTION: {
      return sqlPlaygroundResultChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.RECORD_VISIBILITY_CHANGE:
      return activityVisibilityReducer(state, action);
    case ACTIVITY_ACTION.SET_SUBMISSION_STATUS: {
      return activitySetSubmissionStatusReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_DIFFICULTY: {
      return activitySetDifficultyReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_FEEDBACK: {
      return activitySetFeedbackReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_ACTIVE_QUESTION_INDEX: {
      return activitySetActiveQuestionIndexReducer(state, action);
    }

    case ACTIVITY_ACTION.CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION: {
      return pythonPlaygroundChangeValueReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_RESULT_PYTHON_PLAYGROUND_QUESTION: {
      return pythonPlaygroundResultChangeReducer(state, action);
    }

    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION: {
      return sqlFreeTextPlaygroundQueryChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION: {
      return sqlFreeTextPlaygroundResultChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_CODE_MULTIPLE_CHOICE_QUESTION: {
      return multipleChoiceCodeChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_ANSWER_MULTIPLE_CHOICE_QUESTION: {
      return multipleChoiceAnswerChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SELF_CHECK_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_STRICT_TEXT_QUESTION: {
      return strictQuestionChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.VERIFY_ANSWER_STRICT_TEXT_QUESTION: {
      return strictQuestionVerifyReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_AI_VERIFIED_QUESTION: {
      return freeTextAIVerifiedQuestionChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_LLM_PLAYGROUND: {
      return updateLLMPromptReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_PAGE_STEP:
      return setPageStepReducer(state, action);
    case ACTIVITY_ACTION.REFRESH_SUBMISSION:
      return refreshSubmissionReducer(state, action);
    case ACTIVITY_ACTION.CLEAR_RESULT_AND_REVIEW: {
      return clearResultAndReviewReducer(state, action);
    }
    default:
      throw Error(`Invalid action type: "${action}`);
  }
}

type RecordVisibilityActionType = {
  type: typeof ACTIVITY_ACTION.RECORD_VISIBILITY_CHANGE;
  payload: {
    isIdle: boolean;
  };
};
function activityVisibilityReducer(
  state: ActivityContextType,
  action: RecordVisibilityActionType
): ActivityContextType {
  const pageFocusTimestampIntervals = state.pageFocusTimestampIntervals;
  const isIdle = action.payload.isIdle;

  if (!isIdle) {
    return {
      ...state,
      pageFocusTimestampIntervals: [
        ...pageFocusTimestampIntervals,
        [new Date(), undefined],
      ],
    };
  }
  const existingIntervals = pageFocusTimestampIntervals.slice(0, -1);
  const lastInterval = pageFocusTimestampIntervals[
    pageFocusTimestampIntervals.length - 1
  ] || [new Date(), undefined];
  return {
    ...state,
    pageFocusTimestampIntervals: [
      ...existingIntervals,
      [lastInterval[0], new Date()],
    ],
  };
}

type SetSubmissionStatusActionType = {
  type: typeof ACTIVITY_ACTION.SET_SUBMISSION_STATUS;
  payload: {
    currentSubmissionStatus: string;
  };
};
function activitySetSubmissionStatusReducer(
  state: ActivityContextType,
  action: SetSubmissionStatusActionType
) {
  return {
    ...state,
    submission: {
      ...state.submission,
      currentSubmissionStatus: action.payload.currentSubmissionStatus,
      isSubmitted: action.payload.currentSubmissionStatus === 'SUCCESS',
    },
  };
}

type SetFeedbackActionType = {
  type: typeof ACTIVITY_ACTION.SET_FEEDBACK;
  payload: {
    feedback: string;
  };
};
function activitySetFeedbackReducer(
  state: ActivityContextType,
  action: SetFeedbackActionType
) {
  const submission = {
    ...state.submission,
    feedback: action.payload.feedback,
  };
  return {
    ...state,
    submission,
    didInteract: true,
    hasUnsavedWork: isSubmissionDifferent(
      submission,
      state.latestSavedSubmission,
      state.exercise.questions
    ),
  };
}

type SetDifficultyActionType = {
  type: typeof ACTIVITY_ACTION.SET_DIFFICULTY;
  payload: {
    difficultyRating: number;
  };
};
function activitySetDifficultyReducer(
  state: ActivityContextType,
  action: SetDifficultyActionType
) {
  const submission = {
    ...state.submission,
    difficultyRating: action.payload.difficultyRating,
  };
  return {
    ...state,
    submission,
    didInteract: true,
    hasUnsavedWork: isSubmissionDifferent(
      submission,
      state.latestSavedSubmission,
      state.exercise.questions
    ),
  };
}

type SetActiveQuestionIndexActionType = {
  type: typeof ACTIVITY_ACTION.SET_ACTIVE_QUESTION_INDEX;
  payload: {
    index: number;
  };
};
function activitySetActiveQuestionIndexReducer(
  state: ActivityContextType,
  action: SetActiveQuestionIndexActionType
) {
  return {
    ...state,
    didInteract: true,
    activeQuestionIndex: action.payload.index,
  };
}

type SetPageStep = {
  type: typeof ACTIVITY_ACTION.SET_PAGE_STEP;
  payload: {
    step: ActivityContextType['currentStep'];
  };
};
function setPageStepReducer(
  state: ActivityContextType,
  action: SetPageStep
): ActivityContextType {
  return {
    ...state,
    currentStep: action.payload.step,
  };
}

type RefreshSubmissionActionType = {
  type: typeof ACTIVITY_ACTION.REFRESH_SUBMISSION;
  payload: {
    activity: ActivityPayload;
  };
};

function refreshSubmissionReducer(
  state: ActivityContextType,
  action: RefreshSubmissionActionType
): ActivityContextType {
  const activity = action.payload.activity;
  const newActivityState = initialStateActivity({
    activity,
    type: state.type,
  });
  return {
    ...newActivityState,
    // Preserve some state that shouldn't be reset
    pageFocusTimestampIntervals: state.pageFocusTimestampIntervals,
    didInteract: false,
    hasUnsavedWork: false,
    // Keep current step if it exists, otherwise use the new one
    currentStep: state.currentStep || newActivityState.currentStep,
  };
}
