import { useHistory } from 'react-router-dom';

import { useLearningPlanUpsell } from '../../hooks/use-hydra';
import { benefitsPath } from '../../routing';
import PageWithAsyncData from '../../components/PageWithAsyncData';
import { useEffect } from 'react';
import { StaticLearningPlan } from '../../components/LearningPlan/StaticLearningPlan';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';

export default function LearningPlanUpsell() {
  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_LEARNING_PLAN, {
    flowType: 'logged_in',
  });
  const { value, loading, error, retry } = useLearningPlanUpsell();
  const history = useHistory();
  useEffect(() => {
    if (!value?.learningPlan && !loading) {
      history.push(benefitsPath);
    }
  }, [value, loading, history]);
  return (
    <PageWithAsyncData
      isLoading={loading}
      error={error}
      retry={retry}
      className={''}
    >
      {value?.learningPlan && (
        <StaticLearningPlan
          learningPlan={value.learningPlan}
          planExplanation={value.planExplanation}
          onNext={() => {
            history.push(benefitsPath);
            window.scrollTo(0, 0);
          }}
        />
      )}
    </PageWithAsyncData>
  );
}
