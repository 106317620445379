import { ACTIVITY_ACTION, ActivityContextType } from '..';
import isPythonPlaygroundQuestionCorrect from '../helpers/is-python-playground-question-correct';
import updateQuestion from '../helpers/update-question';

export type SetResultPythonPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.SET_RESULT_PYTHON_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    result: string;
  };
};
export function pythonPlaygroundResultChangeReducer(
  state: ActivityContextType,
  action: SetResultPythonPlaygroundQuestionActionType
) {
  const { question, result } = action.payload;

  const answer = {
    ...state.submission.questionSlugToAnswer[question.slug],
    result,
  };

  const isCorrect = isPythonPlaygroundQuestionCorrect({
    code: answer.value as string,
    output: result,
    verificationOutputRegexes: question.output_regexes_py || [],
    verificationInputRegexes: question.input_regexes_py || [],
  });

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      ...answer,
      isAnswered: true,
      isCorrect,
      isRan: true,
    },
  });
}
