import { ACTIVITY_ACTION, ActivityContextType } from '..';
import { EVENTS, trackEvent } from '../../../analytics';
import updateQuestion from '../helpers/update-question';

export type ChangeValueFreeTextSqlPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueTableauExternalQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueLookerExternalQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueGoogleSheetsExternalQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueFreeTextPowerBiExternalQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueFreeTextQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueFreeTextTableauEmbedQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueFreeTextGoogleSheetsExternalQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export type ChangeValueQuestionFreeTextLookerEmbedActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};

export type ChangeValueExternalDocSelfCheckQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SELF_CHECK_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};

export default function questionValueChangeReducer(
  state: ActivityContextType,
  action:
    | ChangeValueFreeTextSqlPlaygroundQuestionActionType
    | ChangeValueTableauExternalQuestionActionType
    | ChangeValueLookerExternalQuestionActionType
    | ChangeValueGoogleSheetsExternalQuestionActionType
    | ChangeValueFreeTextPowerBiExternalQuestionActionType
    | ChangeValueFreeTextQuestionActionType
    | ChangeValueFreeTextTableauEmbedQuestionActionType
    | ChangeValueFreeTextGoogleSheetsExternalQuestionActionType
    | ChangeValueQuestionFreeTextLookerEmbedActionType
    | ChangeValueExternalDocSelfCheckQuestionActionType
) {
  const { question, value } = action.payload;

  trackEvent({
    event: EVENTS.ACTIVITY.QUESTION.TYPE_ANSWER,
    properties: {
      questionSlug: question.slug,
      questionType: question.type,
    },
  });

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      value,
      isAnswered: !!value,
    },
  });
}
