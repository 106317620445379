import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CTAButton,
  CenteredContainer,
  Header,
  HighlightSpan,
  Label,
  MainImage,
  ReadableContainer,
} from './common';
import {
  COMPLETE_BEGINNER_PLACEMENT_OPTION,
  TIME_EFFORT,
  TOPICS,
  TOPICS_DATA,
} from '../config';
import day from 'dayjs';
import * as Analytics from '../../../analytics';

const beginnerMessages = {
  [TOPICS.PYTHON]:
    '95% of active Enki learners wrote their first coding program within 3 months.',
  [TOPICS.SQL]:
    '87% of active Enki learners reported a “big increase in data confidence” after completing our program.',
  [TOPICS.SHEETS]:
    '87% of active Enki learners reported a “big increase in data confidence” after completing our program.',
  [TOPICS.DATA_LITERACY]:
    '87% of active Enki learners reported a “big increase in data confidence” after completing our program.',
  [TOPICS.POWER_BI]:
    '92% of active Enki learners became “confident with their BI tool” within 4 weeks.',
  [TOPICS.TABLEAU]:
    '92% of active Enki learners became “confident with their BI tool” within 4 weeks.',
  [TOPICS.LOOKER]:
    '92% of active Enki learners became “confident with their BI tool” within 4 weeks.',
};

const nonBeginnerMessages = {
  [TOPICS.PYTHON]:
    '90% of active Enki learners automated tasks using Python scripts.',
  [TOPICS.SQL]:
    '85% of experienced learners optimized complex queries to enhance database performance.',
  [TOPICS.SHEETS]:
    '80% of advanced users automated data processes with Sheets macros.',
  [TOPICS.DATA_LITERACY]:
    '82% of learners applied data insights to improve decision-making processes.',
  [TOPICS.POWER_BI]:
    '88% of advanced users created dynamic reports that drove strategic decisions.',
  [TOPICS.TABLEAU]:
    '90% of experienced users built interactive dashboards to visualise complex data sets.',
  [TOPICS.LOOKER]:
    '85% of non-beginners leveraged Looker to enhance data-driven strategies.',
};

const getLearningGoal = (topic: string, beginner: boolean) => {
  return `become ${beginner ? 'confident' : 'advanced'} in ${
    TOPICS_DATA.options[topic].label
  }`;
};

const getGoalRelatedMessage = (topic: string, beginner: boolean) => {
  return beginner ? beginnerMessages[topic] : nonBeginnerMessages[topic];
};

const getEndDate = (timeEffort: string) => {
  const today = day();
  let endDate;
  if (timeEffort === TIME_EFFORT.options.MINUTES_5_10.value) {
    endDate = today.add(8, 'weeks');
  } else if (timeEffort === TIME_EFFORT.options.MINUTES_20_30.value) {
    endDate = today.add(4, 'weeks');
  } else if (timeEffort === TIME_EFFORT.options.MORE.value) {
    endDate = today.add(2, 'weeks');
  } else {
    endDate = today.add(10, 'weeks');
  }

  let suffix = 'th';
  if ([1, 21, 31].includes(endDate?.date())) {
    suffix = 'st';
  }
  if ([2, 22].includes(endDate?.date())) {
    suffix = 'nd';
  }

  return endDate?.format(`MMMM D`) + suffix;
};

export default function Results() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_RESULTS,
    state.context
  );

  const { timeEffort, topic, knowledgeOfTopics } = state.context;
  if (!topic) {
    throw new Error('Topic is required to save onboarding answers');
  }

  if (!timeEffort) {
    throw new Error('Time effort is required to save onboarding answers');
  }
  if (!knowledgeOfTopics || knowledgeOfTopics.length === 0) {
    throw new Error(
      'Knowledge of topics is required to save onboarding answers'
    );
  }

  const primaryTopicKnowledge = knowledgeOfTopics.find(
    (c) => c?.topic === topic
  );
  const isBeginner =
    primaryTopicKnowledge?.knowledge === COMPLETE_BEGINNER_PLACEMENT_OPTION;

  const goalRelatedMessage = getGoalRelatedMessage(topic || '', isBeginner);
  
  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Based on your answers, you can{' '}
          {getLearningGoal(topic || '', isBeginner)} by{' '}
          <HighlightSpan>
            {getEndDate(timeEffort ?? TIME_EFFORT.options.UNSURE.value)}
          </HighlightSpan>
        </Header>
        <Label>{goalRelatedMessage}</Label>
        <MainImage
          src={'https://img.enkipro.com/826ee25de482e60aa5f6269f8964e147.png'}
        />

        <Label>
          Your goals are unique. We personalize your learning experience with
          content that adapts to your specific goals.
        </Label>

        <CTAButton
          label={'Continue'}
          onClick={() => send(EVENTS.CONTINUE_RESULTS)}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
