import React from 'react';
import useAuth from '../../hooks/use-auth';
import { joinProsumerContent, ProsumerContentModule } from '../../hydra';
import { toast } from 'react-toastify';
import { getProsumerDashboardUrl, getProsumerActivityUrl } from '../../routing';
import ContentCard, {
  CardIcon,
  CardLabelUppercase,
  CardHeader,
  CardPSmall,
} from '../ContentCard';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export default function ProsumerContentModuleCard({
  module,
}: {
  module: ProsumerContentModule;
}) {
  const { getTokenSilently } = useAuth();
  const [isJoining, setIsJoining] = React.useState(false);
  const history = useHistory();
  const onJoinModule = async (module: ProsumerContentModule) => {
    setIsJoining(true);
    try {
      const token = await getTokenSilently();
      await joinProsumerContent(token, {
        contentId: module.contentId,
      });
      history.push(getProsumerDashboardUrl(module.contentSlug));
    } catch (err) {
      toast.error('Something went wrong');
    }
    setIsJoining(false);
  };

  function getButtonsProps(module: ProsumerContentModule) {
    if (!module.isJoined) {
      return [
        {
          label: 'Enroll',
          isDisabled: isJoining,
          onClick: () => onJoinModule(module),
        },
      ];
    }

    return [
      ...(module.nextActivitySlug
        ? [
            {
              label: module.hasSubmission ? 'Continue' : 'Start',
              href: getProsumerActivityUrl(
                module.nextMissionSlug,
                module.nextActivitySlug
              ),
            },
          ]
        : []),
      {
        label: 'Dashboard',
        href: getProsumerDashboardUrl(module.contentSlug),
      },
    ];
  }

  return (
    <ContentCard
      buttonProps={getButtonsProps(module)}
      color={module.isJoined ? undefined : 'transparent'}
    >
      <Row>
        <CardIcon
          url={module.contentImageUrl}
          title={`${module.contentTopic} Track`}
        />
      </Row>
      <CardLabelUppercase>module</CardLabelUppercase>
      <CardHeader>{module.contentTitle}</CardHeader>
      <CardPSmall>{module.contentDescription}</CardPSmall>
    </ContentCard>
  );
}
