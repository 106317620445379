import React from 'react';
import { QuestionProps } from '.';
import {
  AIWorkflow,
  MESSAGE_CONTEXT_TYPES,
} from '../../../contexts/ai-workflow';
import { ACTIVITY_STEP } from '../Content';
import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextMultipleChoiceQuestionAnswer,
} from '../../../contexts/activity';
import { BottomSpacer, getQuestionText, SplitScreenQuestion } from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import { H1 } from '../../elements/Text';
import MentorReview from '../MentorComment';
import MultipleChoiceSelector from './MultipleChoiceSelector';
import SQLPlayground from '../../SQLPlayground';
import { SQLResult } from '../../../contexts/activity/helpers/is-sql-playground-question-correct';
import useSQLRunner from '../../SQLPlayground/use-sql-runner';
import IncorrectAnswerToast from '../../Toasts/TryAgain';
import { toast } from 'react-toastify';

export default function SQLMultipleChoiceQuestion({
  question,
  answer,
}: QuestionProps & {
  answer: ActivityContextMultipleChoiceQuestionAnswer;
}) {
  const { setMessageContext } = React.useContext(AIWorkflow);
  const [activity, dispatch] = React.useContext(ActivityContext);

  const [selectedAnswer, setSelectedAnswer] = React.useState<string | null>(
    answer.value
  );

  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const { runQuery, resetDb, schemas, isLoading, error } = useSQLRunner({
    id: question.id,
    setupSQL: question.setup_sql,
  });

  const onQueryChange = (code: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_CODE_MULTIPLE_CHOICE_QUESTION,
      payload: {
        question,
        code,
      },
    });
  };

  const onHint = React.useCallback(() => {
    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.QUESTION_HINT,
      context_content: {
        question_index: qIndex,
        attempted_answer: answer.value ?? '',
        working_code: answer.working_code ?? '',
        is_correct: answer.isCorrect,
      },
    });
  }, [answer, qIndex, setMessageContext]);

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  const onAnswerChange = React.useCallback(
    (option: string) => {
      dispatch({
        type: ACTIVITY_ACTION.CHANGE_ANSWER_MULTIPLE_CHOICE_QUESTION,
        payload: {
          question,
          answer: option,
        },
      });
    },
    [dispatch, question]
  );

  const onReset = () => {
    resetDb();
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_CODE_MULTIPLE_CHOICE_QUESTION,
      payload: {
        question,
        code: '',
      },
    });
  };

  React.useEffect(() => {
    if (answer.value && answer.value !== selectedAnswer) {
      setSelectedAnswer(answer.value);
      if (answer.isCorrect) {
        toast.success('Correct!');
      } else if (answer.isCorrect == false) {
        toast.warn(() => <IncorrectAnswerToast onHint={onHint} />);
      }
    }
  }, [answer, onHint, selectedAnswer]);

  const onRunQuery = (query: string) => {
    const result = runQuery(query) as SQLResult;
    dispatch({
      type: ACTIVITY_ACTION.SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        result,
      },
    });
  };

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        <ReadableMarkdown md={getQuestionText(question)} />
        <MultipleChoiceSelector
          question={question}
          onAnswerChange={onAnswerChange}
          selectedAnswer={answer.value}
        />
        <BottomSpacer />
      </>

      <SQLPlayground
        query={answer.working_code ?? ''}
        schemas={schemas}
        onQueryChange={onQueryChange}
        onRunQuery={onRunQuery}
        onReset={onReset}
        isLoading={isLoading}
        result={answer.result as SQLResult}
        error={error as unknown as Error}
      />
    </SplitScreenQuestion>
  );
}
