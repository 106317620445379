import styled from 'styled-components';
import { getTrackDurationLabel } from './helpers';
import ContentCard, {
  CardIcon,
  CardLabelUppercase,
  CardHeader,
  CardPSmall,
} from '../ContentCard';
import { Badge } from './TrackCard';
import { OnboardingTrack } from '../../hydra';
const CardRow = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  flex-direction: row;
  display: flex;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
export default function OnboardingTrackCard({
  track,
  setOnboardingTrack,
}: {
  track: OnboardingTrack;
  setOnboardingTrack: (track: OnboardingTrack) => void;
}) {
  const durationLabel = getTrackDurationLabel({
    startedAt: track.startedAt,
    endedAt: track.endedAt,
  });

  const steps = [
    ...track.onboarding.survey.map(({ name, isCompleted }) => ({
      name,
      isCompleted,
    })),
    {
      name: 'Connect to Slack',
      isCompleted: track.onboarding.slack.isCompleted,
    },
  ];
  const isOnboardingCompleted = steps.every(({ isCompleted }) => isCompleted);

  const completedSteps = steps.filter(({ isCompleted }) => isCompleted);
  return (
    <ContentCard
      buttonProps={[
        {
          label: isOnboardingCompleted ? 'Onboarding complete' : 'Onboard',
          isDisabled: isOnboardingCompleted,
          onClick: () => setOnboardingTrack(track),
        },
      ]}
    >
      <Row>
        <CardIcon url={track.imageUrl} title={`${track.topic} Track`} />
        <Badge>TEAMS</Badge>
      </Row>
      <CardLabelUppercase>module</CardLabelUppercase>
      <CardHeader>{track.contentTitle}</CardHeader>
      <CardRow>
        <CardPSmall>{durationLabel}</CardPSmall>
        <CardPSmall>
          {completedSteps.length} / {steps.length} steps
        </CardPSmall>
      </CardRow>
    </ContentCard>
  );
}
