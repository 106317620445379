import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type ChangeAnswerFreeTextAIVerifiedQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_AI_VERIFIED_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    answer: string;
  };
};

export function freeTextAIVerifiedQuestionChangeReducer(
  state: ActivityContextType,
  action: ChangeAnswerFreeTextAIVerifiedQuestionActionType
) {
  const { question, answer } = action.payload;
  const answerState = {
    ...state.submission.questionSlugToAnswer[question.slug],
    value: answer,
  };

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      ...answerState,
      isAnswered: answerState.isAnswered ?? false,
      isCorrect: answerState.isCorrect ?? null,
    },
  });
}
