import { Helmet } from 'react-helmet-async';
import PageWithAsyncData from '../components/PageWithAsyncData';
import { useProjectsDashboard } from '../hooks/use-hydra';
import {
  getAppliedProjectUrl,
  getHomeUrl,
  getProjectsPageTitle,
  projectsDashboardPath,
} from '../routing';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import styled from 'styled-components';
import { H2 } from '../components/elements/Text';
import { AppliedProjectStat } from '../hydra';
import React from 'react';
import { toast } from 'react-toastify';
import ContentCard, {
  CardHeader,
  CardIcon,
  CardLabelUppercase,
  CardPSmall,
} from '../components/ContentCard';
import MultiselectTags from '../components/MultiselectTags';
import { useHistory } from 'react-router-dom';
import { Input } from '../components/elements/Input';
import useProjectSearch from '../hooks/use-project-search';

const ScrollablePageWrapper = styled.div`
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const PageContentWrapper = styled(PageWrapper)`
  max-width: 1100px;
  margin: 0 auto 82px;
`;

export default function ProjectsDashboard() {
  const projectsQuery = useProjectsDashboard();

  return (
    <PageWithAsyncData
      isLoading={projectsQuery.loading}
      error={projectsQuery.error}
      retry={projectsQuery.retry}
      className={''}
    >
      <Helmet>
        <title>{getProjectsPageTitle()}</title>
      </Helmet>
      <Navbar
        breadCrumbs={[
          {
            label: 'Home',
            url: getHomeUrl(),
          },
          {
            label: 'Projects',
            url: projectsDashboardPath,
          },
        ]}
      />
      <Contents />
    </PageWithAsyncData>
  );
}

export function Contents() {
  const history = useHistory();
  const projectsDashboardState = useProjectsDashboard();

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const tags = React.useMemo(
    () => projectsDashboardState.value?.map((p) => p.tags).flat() ?? [],
    [projectsDashboardState.value]
  );

  const filteredProjects = useProjectSearch({
    searchQuery,
    selectedTags,
    projects: projectsDashboardState.value || [],
  });

  return (
    <ScrollablePageWrapper>
      <PageContentWrapper>
        <H2>Projects</H2>
        <Input
          placeholder="Search for projects"
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
        <MultiselectTags
          selectedTags={selectedTags}
          tags={tags}
          onChange={setSelectedTags}
          allowMultiple
        />
        <AppliedProjects
          appliedProjects={filteredProjects}
          onGoToProject={(slug) => history.push(getAppliedProjectUrl(slug))}
        />
      </PageContentWrapper>
    </ScrollablePageWrapper>
  );
}

const GridContainer = styled.div`
  margin-top: 32px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));

  column-gap: 32px;
  row-gap: 32px;
`;

export function AppliedProjects({
  appliedProjects,
  onGoToProject,
}: {
  appliedProjects: Array<AppliedProjectStat>;
  onGoToProject: (slug: string) => void;
}) {
  return (
    <GridContainer>
      {appliedProjects
        .filter((p) => p.is_joined)
        .map((ap) => {
          return (
            <ProjectCard
              key={ap.slug}
              project={ap}
              onClick={() => onGoToProject(ap.slug)}
              isLocked={ap.isLocked}
            />
          );
        })}
      {appliedProjects
        .filter((p) => !p.is_joined)
        .map((ap) => {
          return (
            <UnenrolledProjectCard
              key={ap.slug}
              project={ap}
              onGoToProject={() => onGoToProject(ap.slug)}
            />
          );
        })}
    </GridContainer>
  );
}

function UnenrolledProjectCard({
  project,
  onGoToProject,
}: {
  project: AppliedProjectStat;
  onGoToProject: () => void;
}) {
  const [isJoining, setIsJoining] = React.useState(false);
  const onEnroll = async () => {
    setIsJoining(true);
    try {
      onGoToProject();
    } catch (err) {
      toast.error('Something went wrong');
    }
    setIsJoining(false);
  };
  return (
    <ProjectCard
      key={project.slug}
      project={project}
      onClick={onEnroll}
      btnIsLoading={isJoining}
    />
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const CardRow = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  flex-direction: row;
  display: flex;
`;

function ProjectCard({
  project,
  onClick,
  btnIsLoading = false,
  isLocked = false,
}: {
  project: AppliedProjectStat;
  onClick: () => void;
  btnIsLoading?: boolean;
  isLocked?: boolean;
}) {
  const btnLabel =
    (project.completedQuestions ?? 0) !== 0
      ? project.completedQuestions == project.question_count
        ? 'Review'
        : 'Continue'
      : 'Start';

  const buttonProps = isLocked
    ? [
        {
          label: btnLabel,
          tooltip: 'You have not unlocked this project yet.',
          isDisabled: true,
          onClick: () => {},
        },
      ]
    : [
        {
          label: btnLabel,
          isDisabled: btnIsLoading,
          onClick,
        },
      ];
  return (
    <ContentCard buttonProps={buttonProps} isLocked={isLocked}>
      <Row>
        <CardIcon url={project.image_url} title={`Project: ${project.name}`} />
      </Row>
      <CardLabelUppercase>{project.topic} Project</CardLabelUppercase>
      <CardHeader>{project.name}</CardHeader>
      <CardPSmall>{project.description}</CardPSmall>
      <CardRow>
        <CardPSmall>
          {project.completedQuestions && project.completedQuestions > 0
            ? `${project.completedQuestions}/${project.question_count} Exercises`
            : `${project.question_count} Exercises`}
        </CardPSmall>
      </CardRow>
    </ContentCard>
  );
}
