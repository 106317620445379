import React from 'react';
import { Route } from 'react-router-dom';
import useAuth from '../../hooks/use-auth';
import FullScreenLoader from '../Loader/FullScreenLoader';
import useAuthenticateSilently from '../../hooks/use-authenthicate-silently';

const UnauthenthicatedRoute = ({
  path,
  children,
  ...rest
}: {
  path: string;
  children: React.ReactNode;
  exact?: boolean;
}) => {
  const { isInitialised, didAuthenticate } = useAuth();
  useAuthenticateSilently();

  if (!isInitialised || !didAuthenticate) {
    return <FullScreenLoader />;
  }

  return (
    <Route path={path} {...rest}>
      {children}
    </Route>
  );
};

export default UnauthenthicatedRoute;
