import { ActivityContextQuestionAnswer } from '..';
import { LatestSubmissionPayload, QuestionPayload } from '../../../hydra';

export default function isSubmissionDifferent(
  submission: {
    feedback: string;
    currentSubmissionStatus: string;
    difficultyRating: number | null;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  },
  latestSavedSubmission: LatestSubmissionPayload,
  questions: Array<QuestionPayload>
) {
  const answerStates = latestSavedSubmission?.answer_states;
  if (!answerStates) {
    return true;
  }
  return (
    submission.difficultyRating !== latestSavedSubmission?.difficulty_rating ||
    submission.feedback !== latestSavedSubmission?.feedback ||
    !!questions.find((question) => {
      const answerContext = submission.questionSlugToAnswer[question.slug];
      if (!answerStates[question.slug] || !answerContext) {
        return true;
      }
      const answerIsDifferent =
        answerStates[question.slug].value !== answerContext.value;
      const codeIsDifferent =
        answerStates[question.slug].working_code !==
        { working_code: undefined, ...answerContext }.working_code;

      return answerIsDifferent || codeIsDifferent;
    })
  );
}
