import styled from 'styled-components';
import { Tooltip as UnstyledTooltip } from 'react-tooltip';

export const SmallTooltip = styled(UnstyledTooltip)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  border: 1px solid ${({ theme }) => theme.colors.divider} !important;
  border-radius: 4px !important;

  background-color: ${({ theme }) => theme.colors.bgPage} !important;
  backdrop-filter: saturate(150%) blur(150px) !important;
  z-index: 3 !important;
`;
