import { useParams } from 'react-router-dom';
import ActivityPage, { ACTIVITY_PAGE_TYPE } from '../../components/Activity';
import {
  useProsumerActivity,
  useGetProsumerProfile,
} from '../../hooks/use-hydra';
import {
  ActivitySubmission,
  createProsumerAIReview,
  saveProsumerActivity,
  validateProsumerAnswerAttempt,
} from '../../hydra';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getProsumerActivityUrl, getProsumerDashboardUrl } from '../../routing';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getProsumerActivity } from '../../hydra';
import useAuth from '../../hooks/use-auth';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';
import { ActivityContextQuestionAnswer } from '../../contexts/activity';
import { useCallback } from 'react';

day.extend(utc);

export default function ProsumerActivity() {
  const { missionSlug, activitySlug } =
    useParams<{
      missionSlug: string;
      activitySlug: string;
    }>();
  const { getTokenSilently } = useAuth();
  const { value: profileValue } = useGetProsumerProfile();
  const history = useHistory();

  const activityState = useProsumerActivity(missionSlug, activitySlug);
  const submissionId = activityState.value?.latest_submission?.id ?? -1;

  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.ACTIVITY.VIEW, {
    missionSlug,
    activitySlug,
  });

  const startDiscussion = async () => {};

  const saveSubmission = async (submission: ActivitySubmission) => {
    const token = await getTokenSilently();

    await saveProsumerActivity(token, {
      contentSlug: activityState?.value?.content_slug ?? '',
      submission,
    });
  };

  const submitAnswer = useCallback(
    async ({
      questionSlug,
      value,
    }: {
      questionSlug: string;
      value: string;
    }) => {
      const token = await getTokenSilently();

      await validateProsumerAnswerAttempt(token, {
        value,
        submissionId,
        questionSlug,
      });
    },
    [getTokenSilently, submissionId]
  );
  const goToNextActivity = async (): Promise<void> => {
    const { next_activity_slug, next_mission_slug, content_slug } =
      activityState.value ?? {};

    toast.info('Moving on to the next activity...', {
      autoClose: 2000,
    });
    setTimeout(() => {
      if (next_activity_slug && next_mission_slug) {
        history.push(
          getProsumerActivityUrl(next_mission_slug, next_activity_slug)
        );
      } else {
        history.push(getProsumerDashboardUrl(content_slug ?? ''));
      }
    }, 2000);
  };

  const getActivity = async () => {
    const token = await getTokenSilently();
    return getProsumerActivity(token, {
      missionSlug,
      activitySlug,
    });
  };

  const createAIReview = async ({
    activitySlug,
    submissionId,
    questionSlugToAnswer,
  }: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }) => {
    const token = await getTokenSilently();
    return createProsumerAIReview(token, {
      activitySlug,
      submissionId,
      questionSlugToAnswer,
    });
  };

  return (
    <ActivityPage
      activityState={activityState}
      startDiscussion={startDiscussion}
      isLearnModeProsumer={day
        .utc(profileValue?.profile?.pro_until)
        .isAfter(day.utc())}
      dashboardUrl={getProsumerDashboardUrl(
        activityState?.value?.content_slug ?? ''
      )}
      goToNextActivity={goToNextActivity}
      saveSubmission={saveSubmission}
      getActivity={getActivity}
      type={ACTIVITY_PAGE_TYPE.PROSUMER}
      createAIReview={createAIReview}
      submitAnswer={submitAnswer}
    />
  );
}
