import React from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '../elements/Button';
import { SmallTooltip } from '../Tooltip';
import SQLCodeEditor from './SQLCodeEditor';
import Tabs from '../elements/Tabs';
import SQLOutput from './SQLOutput';
import SQLSchemas from './SQLSchemas';
import {
  SQLResult,
  SQLSchemasType,
} from '../../contexts/activity/helpers/is-sql-playground-question-correct';
// @ts-expect-error sql-formatter is not typed
import sqlFormatter from 'sql-formatter';
import { Header } from '../Activity/Question/common';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  background: ${(props) => props.theme.colors.bgDefault};
  border-left: 1px solid ${(props) => props.theme.colors.zinc800};
  flex-direction: column;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.divider};
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px 10px 20px;
  justify-content: space-between;
`;

const ControlsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const ControlsButton = styled(Button)`
  margin-top: 0px;
  padding: 12px;
  p {
    font-size: 12px !important;
  }
  line-height: 16px;
`;
const Icon = styled.i<{
  isDisabled?: boolean;
}>`
  color: ${(props) => props.theme.colors.textRegular};
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    `}
`;

const Controls = ({
  onReset,
  onFormat,
  canRun = false,
  onRun = () => {},
  isReadOnly,
}: {
  onReset: () => void;
  onFormat: () => void;
  canRun: boolean;
  onRun: () => void;
  isReadOnly: boolean;
}) => {
  const theme = useTheme();
  return (
    <ControlsRow>
      <ControlsGroup>
        {!isReadOnly && (
          <>
            <Icon
              onClick={onReset}
              className={'fa-solid fa-rotate-right playground-icon-reset'}
            />
            <SmallTooltip
              noArrow
              anchorSelect=".playground-icon-reset"
              place="top"
            >
              Start over
            </SmallTooltip>
            <Icon
              onClick={onFormat}
              className={`fa-solid fa-broom-wide playground-icon-format`}
              isDisabled={!canRun}
            />
            <SmallTooltip
              noArrow
              anchorSelect=".playground-icon-format"
              place="top"
            >
              Format query to make it more readable
            </SmallTooltip>
          </>
        )}
      </ControlsGroup>
      <ControlsGroup>
        <ControlsButton
          label="Run"
          onClick={onRun}
          type={canRun ? 'default' : 'secondary'}
          isDisabled={!canRun}
          color={
            !canRun ? theme.colors.textRegular : theme.colors.buttonPrimary
          }
          faIcon="fa-solid fa-circle-play"
          showIconBeforeLabel
          tooltip={!canRun ? 'Write a query in the editor first!' : ''}
        />
      </ControlsGroup>
    </ControlsRow>
  );
};

export default function SQLPlayground({
  query,
  schemas = [],
  result,
  error,
  isLoading,
  onQueryChange,
  onRunQuery,
  onReset,
  isReadOnly = false,
  headerText = 'Run queries in the editor to answer',
}: {
  query: string;
  onQueryChange: (query: string) => void;
  onRunQuery: (query: string) => void;
  onReset: () => void;
  schemas: SQLSchemasType;
  isLoading: boolean;
  result: SQLResult;
  error?: Error;
  isReadOnly?: boolean;
  headerText?: string;
}) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const runQuery = React.useCallback(() => {
    if (query.trim()) {
      onRunQuery(query.trim());
      setSelectedTabIndex(1);
    }
  }, [query, onRunQuery, setSelectedTabIndex]);

  const formatQuery = () => onQueryChange(sqlFormatter.format(query.trim()));

  return (
    <Wrapper>
      <Header text={headerText} />
      <Divider />
      <SQLCodeEditor
        query={query}
        onQueryChange={onQueryChange}
        isLoading={isLoading}
        schemas={schemas}
        error={error}
        isReadOnly={isReadOnly}
      />
      <Controls
        canRun={!isLoading && !!query}
        onRun={runQuery}
        onFormat={formatQuery}
        onReset={onReset}
        isReadOnly={isReadOnly}
      />
      <Divider />
      <Tabs
        options={['Schemas', 'Output']}
        selectedOptionIndex={selectedTabIndex}
        onClick={setSelectedTabIndex}
      />
      {selectedTabIndex === 0 ? (
        <SQLSchemas schemas={schemas} />
      ) : (
        <SQLOutput output={result} />
      )}
    </Wrapper>
  );
}
