import styled from 'styled-components';
import { Header } from '../Activity/Question/common';
import { MultiLineInput } from '../elements/Input';
import React from 'react';
import { LabelUppercase, P, PExtraSmall, PSmall } from '../elements/Text';
import Button from '../elements/Button';
import { Tooltip } from 'react-tooltip';
import Markdown from '../Markdown';
import Tabs from '../elements/Tabs';
import Loader from '../Loader';
import { useUpdateEffect } from 'react-use';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  background: ${(props) => props.theme.colors.bgDefault};
  border-left: 1px solid ${(props) => props.theme.colors.zinc800};
  flex-direction: column;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.divider};
`;

const OutputWrapper = styled.div`
  padding: 0 14px 14px 14px;
  height: CALC(50% - 105px);
`;

const MarkdownWrapper = styled.div`
  height: CALC(100% - 35px);
  overflow-y: auto;
  > div {
   height: 100%;
   > div {
    height: 100%;
  }
`;

const Placeholder = styled.span`
  color: ${({ theme }) => theme.colors.textPlaceholder};
  font-size: 14px;
  margin: 10px;
`;

const ValidationErrorLabel = styled(PSmall)`
  color: ${({ theme }) => theme.colors.textDanger};
`;

const OutputMarkdownComponents = {
  H1: ({ ...props }) => <P {...props} />,
  H2: ({ ...props }) => <P {...props} />,
  H3: ({ ...props }) => <P {...props} />,
  p: ({ ...props }) => <PSmall {...props} />,
  li: ({ ...props }) => <li {...props} style={{ fontSize: '15px' }} />,
  a: ({ ...props }) => <a {...props} style={{ fontSize: '15px' }} />,
};

export default function LLMPlayground({
  userPrompt,
  onPromptChange,
  result,
  review,
  onSubmitPrompt,
  isLoading,
  onReset,
  validationError,
}: {
  userPrompt: string;
  onPromptChange: (prompt: string) => void;
  result: string | null;
  review: string | null;
  onSubmitPrompt: () => void;
  onReset: () => void;
  isLoading: boolean;
  validationError: string | null;
}) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  useUpdateEffect(() => {
    if (review) {
      setSelectedTabIndex(1);
    }
  }, [review]);

  return (
    <Wrapper>
      <Header text={'Your Prompt:'} />
      <Divider />
      <PromptEditor userPrompt={userPrompt} onPromptChange={onPromptChange} />
      <Controls
        hasPrompt={!!userPrompt}
        onSubmitPrompt={() => {
          onSubmitPrompt();
          setSelectedTabIndex(0);
        }}
        onReset={onReset}
        isLoading={isLoading}
      />
      <Divider />
      <OutputWrapper>
        <Tabs
          options={['Output', 'Review']}
          selectedOptionIndex={selectedTabIndex}
          onClick={setSelectedTabIndex}
          loadingStates={[
            isLoading && !result,
            !!result && isLoading && !review,
          ]}
          disabledStates={[false, !result && isLoading]}
        />
        <MarkdownWrapper>
          {validationError && (
            <ValidationErrorLabel>{validationError}</ValidationErrorLabel>
          )}
          {!validationError && (
            <>
              {selectedTabIndex === 0 ? (
                <>
                  {isLoading && !result ? (
                    <HorizontalBarLoader label="Creating AI response..." />
                  ) : result ? (
                    <Markdown
                      md={result}
                      components={OutputMarkdownComponents}
                    />
                  ) : (
                    <Placeholder>
                      Your submitted prompt output will be here
                    </Placeholder>
                  )}
                </>
              ) : (
                <>
                  {isLoading && !review ? (
                    <HorizontalBarLoader label="Evaluating LLM output..." />
                  ) : review ? (
                    <Markdown
                      md={review}
                      components={OutputMarkdownComponents}
                    />
                  ) : (
                    <Placeholder>
                      Your AI Mentor will give you feedback here
                    </Placeholder>
                  )}
                </>
              )}
            </>
          )}
        </MarkdownWrapper>
      </OutputWrapper>
    </Wrapper>
  );
}

const PromptInput = styled(MultiLineInput)`
  background: ${(props) => props.theme.colors.bgDefault};
  border-radius: 8px;
  padding-bottom: 0;
  margin-top: 0px;
  div {
    background: transparent;
    border: none;

    textarea {
      font-size: 14px;
      &::placeholder {
        color: ${({ theme }) => theme.colors.textPlaceholder};
      }
    }
  }
`;

const PromptEditorWrapper = styled.div`
  padding: 15px;
  height: calc(50% - 10px);
  overflow-y: auto;
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 20px 10px 20px;
  justify-content: space-between;
`;

const ControlsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const ControlsButton = styled(Button)`
  margin-top: 0px;
  padding: 12px;
  p {
    font-size: 12px !important;
  }
  svg {
    width: 16px;
  }
  line-height: 16px;
`;

function PromptEditor({
  userPrompt,
  onPromptChange,
}: {
  userPrompt: string;
  onPromptChange: (prompt: string) => void;
}) {
  return (
    <PromptEditorWrapper>
      <PromptInput
        value={userPrompt}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onPromptChange(e.target.value)
        }
        placeholder={`Write your prompt here.`}
        minRows={9}
      />
    </PromptEditorWrapper>
  );
}

const Icon = styled.i<{
  isDisabled?: boolean;
}>`
  color: ${(props) => props.theme.colors.textRegular};
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    `
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
      `}
`;

const ModelLabel = styled(PExtraSmall)`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.textPlaceholder};
`;

function Controls({
  hasPrompt,
  onSubmitPrompt,
  onReset,
  isLoading,
}: {
  hasPrompt: boolean;
  onSubmitPrompt: () => void;
  onReset: () => void;
  isLoading: boolean;
}) {
  return (
    <ControlsRow>
      <ControlsGroup>
        <>
          <Icon
            className={`fa-solid fa-rotate-left playground-icon-reset`}
            onClick={onReset}
          />
          <Tooltip noArrow anchorSelect=".playground-icon-reset" place="top">
            Start over
          </Tooltip>
        </>
        <ModelLabel>Model: GPT-4o-mini</ModelLabel>
      </ControlsGroup>
      <ControlsGroup>
        <ControlsButton
          label="Submit"
          type="primary"
          tooltip={
            hasPrompt
              ? undefined
              : 'You must write a prompt before you can submit :)'
          }
          onClick={onSubmitPrompt}
          isDisabled={isLoading || !hasPrompt}
        />
      </ControlsGroup>
    </ControlsRow>
  );
}
const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
`;
function HorizontalBarLoader({ label }: { label: string }) {
  return (
    <LoaderWrapper>
      <Loader />
      <LabelUppercase>{label}</LabelUppercase>
    </LoaderWrapper>
  );
}
