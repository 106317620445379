import { createMachine } from 'xstate';
import { ACTIONS } from './actions';
import { GUARDS } from './guards';
import { SERVICES } from './services';
import { SaveOnboardingPayload } from '../../hydra';

export enum STATES {
  INITIAL = 'INITIAL',
  ASK_TOPIC = 'ASK_TOPIC',
  ASK_GOAL = 'ASK_GOAL',
  POST_ASK_GOAL = 'POST_ASK_GOAL',
  ASK_PROFILE = 'ASK_PROFILE',
  ASK_ROLE = 'ASK_ROLE',
  POST_ASK_ROLE = 'POST_ASK_ROLE',
  ASK_APPLICATIONS = 'ASK_APPLICATIONS',
  ASK_SECONDARY_TOPICS = 'ASK_SECONDARY_TOPICS',
  ASK_TOPIC_KNOWLEDGE = 'ASK_TOPIC_KNOWLEDGE',
  ASK_THEORY = 'ASK_THEORY',
  ASK_TIME_EFFORT = 'ASK_TIME_EFFORT',
  GET_RESULTS = 'GET_RESULTS',
  CHECK_EMAIL_AVAILABILITY = 'CHECK_EMAIL_AVAILABILITY',
  SAVE_RESULTS = 'SAVE_RESULTS',
  RESULTS = 'RESULTS',
  PERSONALIZED_PLAN = 'PERSONALIZED_PLAN',
  BENEFITS = 'BENEFITS',
  PAYMENT = 'PAYMENT',
  PAYMENT_CALLBACK = 'PAYMENT_CALLBACK',
  AUTH = 'AUTH',
  AUTH_CONFIRM_EMAIL = 'AUTH_CONFIRM_EMAIL',
  AUTH_CALLBACK = 'AUTH_CALLBACK',
  END = 'END',
  PARTNER_WELCOME = 'PARTNER_WELCOME',
  ERROR = 'ERROR',
  APP_USER = 'APP_USER',
  CHECK_APP_USER = 'CHECK_APP_USER',
}

export const STATES_TO_ROUTES = {
  [STATES.INITIAL]: '',
  [STATES.ASK_TOPIC]: 'topic',

  [STATES.ASK_GOAL]: 'goal',
  [STATES.POST_ASK_GOAL]: 'post-goal',

  [STATES.ASK_PROFILE]: 'profile',
  [STATES.ASK_ROLE]: 'role',
  [STATES.POST_ASK_ROLE]: 'post-role',

  [STATES.ASK_APPLICATIONS]: 'applications',
  [STATES.ASK_SECONDARY_TOPICS]: 'secondary-topics',

  [STATES.ASK_TOPIC_KNOWLEDGE]: 'topic-knowledge',
  [STATES.ASK_THEORY]: 'theory',
  [STATES.ASK_TIME_EFFORT]: 'time-effort',
  [STATES.GET_RESULTS]: 'get-results',
  [STATES.CHECK_EMAIL_AVAILABILITY]: 'check-email-availability',
  [STATES.SAVE_RESULTS]: 'save-results',
  [STATES.RESULTS]: 'results',
  [STATES.PERSONALIZED_PLAN]: 'personalized-plan',
  [STATES.BENEFITS]: 'benefits',
  [STATES.PAYMENT]: 'payment',
  [STATES.PAYMENT_CALLBACK]: 'payment-callback',
  [STATES.AUTH]: 'auth',
  [STATES.AUTH_CONFIRM_EMAIL]: 'confirm-email',
  [STATES.AUTH_CALLBACK]: 'auth-callback',
  [STATES.ERROR]: 'error',
  [STATES.PARTNER_WELCOME]: 'partner-welcome',
  [STATES.END]: 'end',
  [STATES.APP_USER]: 'app-user',
  [STATES.CHECK_APP_USER]: 'check-app-user',
};

export const ROUTES_TO_STATES = Object.fromEntries(
  Object.entries(STATES_TO_ROUTES).map(([state, route]) => [route, state])
);

export enum EVENTS {
  START_ONBOARDING = 'START_ONBOARDING',
  SKIP_TOPIC_SELECTION = 'SKIP_TOPIC_SELECTION',
  RESUME_PAYMENT_CALLBACK = 'RESUME_PAYMENT_CALLBACK',
  RESUME_AUTH_CALLBACK = 'RESUME_AUTH_CALLBACK',

  SELECT_TOPIC = 'SELECT_TOPIC',
  CONTINUE_ASK_TOPIC = 'CONTINUE_ASK_TOPIC',

  SELECT_PROFILE = 'SELECT_PROFILE',
  CONTINUE_ASK_PROFILE = 'CONTINUE_ASK_PROFILE',
  BACK_ASK_PROFILE = 'BACK_ASK_PROFILE',

  SELECT_ROLE = 'SELECT_ROLE',
  CONTINUE_ASK_ROLE = 'CONTINUE_ASK_ROLE',
  BACK_ASK_ROLE = 'BACK_ASK_ROLE',

  CONTINUE_POST_ASK_ROLE = 'CONTINUE_POST_ASK_ROLE',
  BACK_POST_ASK_ROLE = 'BACK_POST_ASK_ROLE',

  SELECT_APPLICATIONS = 'SELECT_APPLICATIONS',
  CONTINUE_ASK_APPLICATIONS = 'CONTINUE_ASK_APPLICATIONS',
  BACK_ASK_APPLICATIONS = 'BACK_ASK_APPLICATIONS',

  SELECT_SECONDARY_TOPICS = 'SELECT_SECONDARY_TOPICS',
  CONTINUE_ASK_SECONDARY_TOPICS = 'CONTINUE_ASK_SECONDARY_TOPICS',
  BACK_ASK_SECONDARY_TOPICS = 'BACK_ASK_SECONDARY_TOPICS',

  SELECT_THEORY_PREFERENCE = 'SELECT_THEORY_PREFERENCE',
  CONTINUE_ASK_THEORY = 'CONTINUE_ASK_THEORY',
  BACK_ASK_THEORY = 'BACK_ASK_THEORY',

  SELECT_GOAL = 'SELECT_GOAL',
  CONTINUE_ASK_GOAL = 'CONTINUE_ASK_GOAL',
  BACK_ASK_GOAL = 'BACK_ASK_GOAL',

  CONTINUE_POST_ASK_GOAL = 'CONTINUE_POST_ASK_GOAL',
  BACK_POST_ASK_GOAL = 'BACK_POST_ASK_GOAL',

  SELECT_TOPIC_KNOWLEDGE = 'SELECT_TOPIC_KNOWLEDGE',
  CONTINUE_ASK_TOPIC_KNOWLEDGE = 'CONTINUE_ASK_TOPIC_KNOWLEDGE',
  BACK_ASK_TOPIC_KNOWLEDGE = 'BACK_ASK_TOPIC_KNOWLEDGE',

  SELECT_TIME_EFFORT = 'SELECT_TIME_EFFORT',
  CONTINUE_ASK_TIME_EFFORT = 'CONTINUE_ASK_TIME_EFFORT',
  BACK_ASK_TIME_EFFORT = 'BACK_ASK_TIME_EFFORT',

  CONTINUE_GET_RESULTS = 'CONTINUE_GET_RESULTS',
  BACK_GET_RESULTS = 'BACK_GET_RESULTS',

  CONTINUE_PERSONALIZED_PLAN = 'CONTINUE_PERSONALIZED_PLAN',
  BACK_PERSONALIZED_PLAN = 'BACK_PERSONALIZED_PLAN',

  CONTINUE_APP_USER = 'CONTINUE_APP_USER',
  BACK_APP_USER = 'BACK_APP_USER',

  ADD_EMAIL = 'ADD_EMAIL',
  SET_BILLING_PREFERENCE = 'SET_BILLING_PREFERENCE',
  CONTINUE_RESULTS = 'CONTINUE_RESULTS',
  BACK_RESULTS = 'BACK_RESULTS',

  CONTINUE_BENEFITS = 'CONTINUE_BEFEFITS',
  BACK_BENEFITS = 'BACK_BENEFITS',
  BACK_PAYMENT = 'BACK_PAYMENT',
  CONTINUE_PAYMENT_CALLBACK = 'CONTINUE_PAYMENT_CALLBACK',
  RESUME_PAYMENT = 'RESUME_PAYMENT',
  UPDATE_SETUP_INTENT = 'UPDATE_SETUP_INTENT',

  SAVE_RESULTS = 'SAVE_RESULTS',
  CHECK_EMAIL_AVAILABILITY = 'CHECK_EMAIL_AVAILABILITY',

  CONTINUE_PARTNER_WELCOME = 'CONTINUE_PARTNER_WELCOME',
  HANDLE_PAYMENT_CALLBACK = 'HANDLE_PAYMENT_CALLBACK',

  GO_TO_CONFIRM_EMAIL = 'GO_TO_CONFIRM_EMAIL',
  BACK_AUTH_CONFIRM_EMAIL = 'BACK_AUTH_CONFIRM_EMAIL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

export type EventType =
  | { type: EVENTS.SELECT_TOPIC; topic: string }
  | { type: EVENTS.SELECT_GOAL; goal: string }
  | { type: EVENTS.CONTINUE_ASK_TOPIC }
  | { type: EVENTS.SELECT_PROFILE; profile: string }
  | { type: EVENTS.CONTINUE_ASK_PROFILE }
  | { type: EVENTS.BACK_ASK_PROFILE }
  | { type: EVENTS.SELECT_ROLE; role: string }
  | { type: EVENTS.CONTINUE_ASK_ROLE }
  | { type: EVENTS.BACK_ASK_ROLE }
  | { type: EVENTS.CONTINUE_POST_ASK_ROLE }
  | { type: EVENTS.BACK_POST_ASK_ROLE }
  | { type: EVENTS.SELECT_APPLICATIONS; applications: string[] }
  | { type: EVENTS.CONTINUE_ASK_APPLICATIONS }
  | { type: EVENTS.BACK_ASK_APPLICATIONS }
  | { type: EVENTS.SELECT_SECONDARY_TOPICS; secondaryTopics: string[] }
  | { type: EVENTS.CONTINUE_ASK_SECONDARY_TOPICS }
  | { type: EVENTS.BACK_ASK_SECONDARY_TOPICS }
  | {
    type: EVENTS.SELECT_THEORY_PREFERENCE;
    theoryPreference: string;
  }
  | { type: EVENTS.CONTINUE_ASK_THEORY }
  | { type: EVENTS.BACK_ASK_THEORY }
  | { type: EVENTS.START_ONBOARDING }
  | { type: EVENTS.SKIP_TOPIC_SELECTION }
  | { type: EVENTS.CONTINUE_ASK_GOAL }
  | { type: EVENTS.BACK_ASK_GOAL }
  | { type: EVENTS.CONTINUE_POST_ASK_GOAL }
  | { type: EVENTS.BACK_POST_ASK_GOAL }
  | { type: EVENTS.CONTINUE_GET_RESULTS }
  | { type: EVENTS.BACK_GET_RESULTS }
  | { type: EVENTS.ADD_EMAIL; email: string }
  | { type: EVENTS.SET_BILLING_PREFERENCE; isMonthlySubscription: boolean }
  | { type: EVENTS.CONTINUE_RESULTS }
  | { type: EVENTS.CONTINUE_PERSONALIZED_PLAN }
  | { type: EVENTS.BACK_PERSONALIZED_PLAN }
  | { type: EVENTS.CONTINUE_APP_USER }
  | { type: EVENTS.BACK_APP_USER }
  | { type: EVENTS.BACK_RESULTS }
  | { type: EVENTS.CONTINUE_BENEFITS }
  | { type: EVENTS.BACK_BENEFITS }
  | {
    type: EVENTS.SELECT_TOPIC_KNOWLEDGE;
    topicKnowledge: string;
    topic: string;
  }
  | { type: EVENTS.CONTINUE_ASK_TOPIC_KNOWLEDGE }
  | { type: EVENTS.BACK_ASK_TOPIC_KNOWLEDGE }
  | { type: EVENTS.SELECT_TIME_EFFORT; timeEffort: string }
  | { type: EVENTS.CONTINUE_ASK_TIME_EFFORT }
  | { type: EVENTS.BACK_ASK_TIME_EFFORT }
  | { type: EVENTS.RESUME_PAYMENT_CALLBACK }
  | { type: EVENTS.CONTINUE_PAYMENT_CALLBACK }
  | { type: EVENTS.RESUME_AUTH_CALLBACK }
  | { type: EVENTS.RESUME_PAYMENT }
  | { type: EVENTS.BACK_PAYMENT }
  | {
    type: EVENTS.SAVE_RESULTS;
    data: {
      hasPartnerCode: boolean;
      partnerCode: string;
      partnerWelcomeMessage: string;
      shouldSkipPayment: boolean;
      isAppUser: boolean;
      learningPlan: SaveOnboardingPayload['learningPlan'];
      planExplanation: SaveOnboardingPayload['planExplanation'];
    };
  }
  | {
    type: EVENTS.CHECK_EMAIL_AVAILABILITY;
    data: {
      email: string;
    };
  }
  | {
    type: EVENTS.CONTINUE_PARTNER_WELCOME;
  }
  | {
    type: EVENTS.HANDLE_PAYMENT_CALLBACK;
    data: {
      email: string;
    };
  }
  | {
    type: EVENTS.UPDATE_SETUP_INTENT;
    data: {
      paymentIntentClientSecret: string;
    };
  }
  | { type: EVENTS.GO_TO_CONFIRM_EMAIL }
  | { type: EVENTS.BACK_AUTH_CONFIRM_EMAIL }
  | { type: EVENTS.PAYMENT_ERROR };

export type OnboardQuestion = {
  id: string;
  question: string;
  options: { value: string; label: string }[];
  answers: string[];
  customData?: Record<string, string>;
};

export type ContextType = {
  profile: string | null;

  topic: string | null;
  projectSlug: string | null;

  goal: string | null;
  role: string | null;
  applications: string[] | null;
  secondaryTopics: string[];
  theoryPreference: null | 'theory' | 'practice';
  timeEffort: string | null;
  knowledgeOfTopics: Array<{
    topic: string;
    knowledge: string;
  }>;
  email: string | null;
  isMonthlySubscription: boolean;
  paymentIntentClientSecret: string | null;
  discountPercentage: number | null;
  partnerCode: string | null;
  partnerWelcomeMessage: string | null;
  shouldSkipPayment: boolean | null;
  isAppUser: boolean | null;
  learningPlan: SaveOnboardingPayload['learningPlan'];
  planExplanation: SaveOnboardingPayload['planExplanation'];
  // discountCode: string | null; we might not need this as it's set as a cookie through
};

export const INITIAL_CONTEXT: ContextType = {
  profile: null,
  topic: null,
  projectSlug: null,
  goal: null,
  role: null,
  applications: null,
  secondaryTopics: [],
  knowledgeOfTopics: [],
  theoryPreference: null,
  timeEffort: null,
  email: null,
  isMonthlySubscription: false,
  paymentIntentClientSecret: null,
  discountPercentage: null,
  partnerCode: null,
  partnerWelcomeMessage: null,
  shouldSkipPayment: false,
  isAppUser: false,
  learningPlan: null,
  planExplanation: null,
};

//stately.ai/registry/editor/181269cd-5755-48ac-9bfa-0a0d17fbe360?machineId=0febf937-6118-4336-8382-e318a5a375f5
export const stateMachine = createMachine({
  predictableActionArguments: true,
  schema: {
    context: {} as ContextType,
    events: {} as EventType,
  },
  context: INITIAL_CONTEXT,
  id: 'solo-onboarding',
  initial: STATES.INITIAL,
  states: {
    [STATES.INITIAL]: {
      on: {
        [EVENTS.START_ONBOARDING]: {
          target: STATES.ASK_TOPIC,
        },
        [EVENTS.RESUME_PAYMENT_CALLBACK]: {
          target: STATES.PAYMENT_CALLBACK,
        },
        [EVENTS.RESUME_AUTH_CALLBACK]: {
          target: STATES.AUTH_CALLBACK,
        },
        [EVENTS.SKIP_TOPIC_SELECTION]: {
          target: STATES.ASK_GOAL,
        },
      },
      entry: {
        type: ACTIONS.SET_PREFILLED_CONTENT,
      },
      exit: {
        type: ACTIONS.SET_PROMO_CODES,
      },
    },
    [STATES.ASK_TOPIC]: {
      on: {
        [EVENTS.SELECT_TOPIC]: {
          actions: { type: ACTIONS.SELECT_TOPIC },
        },
        [EVENTS.CONTINUE_ASK_TOPIC]: {
          target: STATES.ASK_GOAL,
          cond: GUARDS.DID_SELECT_TOPIC,
        },
      },
    },
    [STATES.ASK_GOAL]: {
      on: {
        [EVENTS.SELECT_GOAL]: {
          actions: { type: ACTIONS.SELECT_GOAL },
        },
        [EVENTS.CONTINUE_ASK_GOAL]: {
          target: STATES.POST_ASK_GOAL,
          cond: GUARDS.DID_SELECT_GOAL,
        },
        [EVENTS.BACK_ASK_GOAL]: {
          target: STATES.ASK_TOPIC,
        },
      },
    },
    [STATES.POST_ASK_GOAL]: {
      on: {
        [EVENTS.CONTINUE_POST_ASK_GOAL]: [
          {
            target: STATES.ASK_PROFILE,
            cond: GUARDS.IS_CAREER_STARTER,
          },
          {
            target: STATES.ASK_ROLE,
          },
        ],
        [EVENTS.BACK_POST_ASK_GOAL]: {
          target: STATES.ASK_GOAL,
        },
      },
    },
    [STATES.ASK_PROFILE]: {
      on: {
        [EVENTS.SELECT_PROFILE]: {
          actions: { type: ACTIONS.SELECT_PROFILE },
        },
        [EVENTS.CONTINUE_ASK_PROFILE]: {
          target: STATES.ASK_ROLE,
        },
        [EVENTS.BACK_ASK_PROFILE]: {
          target: STATES.POST_ASK_GOAL,
        },
      },
    },
    [STATES.ASK_ROLE]: {
      on: {
        [EVENTS.SELECT_ROLE]: {
          actions: { type: ACTIONS.SELECT_ROLE },
        },
        [EVENTS.CONTINUE_ASK_ROLE]: {
          target: STATES.POST_ASK_ROLE,
        },
        [EVENTS.BACK_ASK_ROLE]: [
          {
            target: STATES.ASK_PROFILE,
            cond: GUARDS.IS_CAREER_STARTER,
          },
          {
            target: STATES.POST_ASK_GOAL,
          },
        ],
      },
    },
    [STATES.POST_ASK_ROLE]: {
      on: {
        [EVENTS.CONTINUE_POST_ASK_ROLE]: {
          target: STATES.ASK_APPLICATIONS,
        },
        [EVENTS.BACK_POST_ASK_ROLE]: {
          target: STATES.ASK_ROLE,
        },
      },
    },
    [STATES.ASK_APPLICATIONS]: {
      on: {
        [EVENTS.SELECT_APPLICATIONS]: {
          actions: { type: ACTIONS.SELECT_APPLICATIONS },
        },
        [EVENTS.CONTINUE_ASK_APPLICATIONS]: {
          target: STATES.ASK_SECONDARY_TOPICS,
        },
        [EVENTS.BACK_ASK_APPLICATIONS]: {
          target: STATES.POST_ASK_ROLE,
        },
      },
    },
    [STATES.ASK_SECONDARY_TOPICS]: {
      on: {
        [EVENTS.SELECT_SECONDARY_TOPICS]: {
          actions: { type: ACTIONS.SELECT_SECONDARY_TOPICS },
        },
        [EVENTS.CONTINUE_ASK_SECONDARY_TOPICS]: {
          target: STATES.ASK_TOPIC_KNOWLEDGE,
        },
        [EVENTS.BACK_ASK_SECONDARY_TOPICS]: {
          target: STATES.ASK_APPLICATIONS,
        },
      },
    },
    [STATES.ASK_TOPIC_KNOWLEDGE]: {
      on: {
        [EVENTS.SELECT_TOPIC_KNOWLEDGE]: {
          actions: {
            type: ACTIONS.SELECT_TOPIC_KNOWLEDGE,
          },
        },
        [EVENTS.CONTINUE_ASK_TOPIC_KNOWLEDGE]: [
          {
            target: STATES.ASK_THEORY,
            cond: GUARDS.DID_SELECT_ALL_KNOWLEDGES,
          },
          {
            target: STATES.ASK_TOPIC_KNOWLEDGE,
          },
        ],
        [EVENTS.BACK_ASK_TOPIC_KNOWLEDGE]: [
          {
            target: STATES.ASK_SECONDARY_TOPICS,
            cond: GUARDS.DID_NOT_SELECT_ANY_KNOWLEDGE,
          },
          {
            actions: { type: ACTIONS.CLEAR_TOPIC_KNOWLEDGE },
            target: STATES.ASK_TOPIC_KNOWLEDGE,
          },
        ],
      },
    },
    [STATES.ASK_THEORY]: {
      on: {
        [EVENTS.SELECT_THEORY_PREFERENCE]: {
          actions: {
            type: ACTIONS.SELECT_THEORY_PREFERENCE,
          },
        },
        [EVENTS.CONTINUE_ASK_THEORY]: {
          target: STATES.ASK_TIME_EFFORT,
        },
        [EVENTS.BACK_ASK_THEORY]: {
          target: STATES.ASK_TOPIC_KNOWLEDGE,
          actions: { type: ACTIONS.CLEAR_TOPIC_KNOWLEDGE },
        },
      },
    },
    [STATES.ASK_TIME_EFFORT]: {
      on: {
        [EVENTS.SELECT_TIME_EFFORT]: {
          actions: {
            type: ACTIONS.SELECT_TIME_EFFORT,
          },
        },
        [EVENTS.CONTINUE_ASK_TIME_EFFORT]: {
          target: STATES.RESULTS,
          cond: GUARDS.DID_SELECT_TIME_EFFORT,
        },
        [EVENTS.BACK_ASK_TIME_EFFORT]: [
          {
            target: STATES.ASK_THEORY,
            cond: GUARDS.IS_INTERMEDIATE_LEARNER,
          },
          {
            target: STATES.ASK_TOPIC_KNOWLEDGE,
            actions: { type: ACTIONS.CLEAR_TOPIC_KNOWLEDGE },
          },
        ],
      },
    },
    [STATES.RESULTS]: {
      on: {
        [EVENTS.CONTINUE_RESULTS]: {
          target: STATES.GET_RESULTS,
        },
        [EVENTS.BACK_RESULTS]: {
          target: STATES.ASK_TIME_EFFORT,
        },
      },
    },
    [STATES.GET_RESULTS]: {
      on: {
        [EVENTS.ADD_EMAIL]: {
          actions: { type: ACTIONS.ADD_EMAIL },
        },
        [EVENTS.CONTINUE_GET_RESULTS]: {
          target: STATES.CHECK_EMAIL_AVAILABILITY,
          cond: GUARDS.DID_ADD_EMAIL,
        },
        [EVENTS.BACK_GET_RESULTS]: {
          target: STATES.RESULTS,
        },
      },
    },
    [STATES.CHECK_EMAIL_AVAILABILITY]: {
      invoke: {
        src: SERVICES.CHECK_EMAIL_AVAILABILITY,
        onDone: {
          target: STATES.SAVE_RESULTS,
        },
        onError: [
          {
            actions: { type: ACTIONS.SHOW_EMAIL_VALID_ERROR_TOAST },
            cond: GUARDS.IS_EMAIL_VALID_ERROR,
            target: STATES.GET_RESULTS,
          },
          {
            target: STATES.ERROR,
          },
        ],
      },
    },
    [STATES.SAVE_RESULTS]: {
      invoke: {
        src: SERVICES.SAVE_ONBOARDING_ANSWERS,
        onDone: {
          target: STATES.CHECK_APP_USER,
          actions: [
            { type: ACTIONS.UPDATE_PARTNER_SIGNUP_CODE },
            { type: ACTIONS.UPDATE_SHOULD_SKIP_PAYMENT },
            { type: ACTIONS.UPDATE_IS_APP_USER },
            { type: ACTIONS.SET_LEARNING_PLAN },
          ],
        },
        onError: [
          {
            actions: { type: ACTIONS.SHOW_EMAIL_VALID_ERROR_TOAST },
            cond: GUARDS.IS_EMAIL_VALID_ERROR,
            target: STATES.GET_RESULTS,
          },
          {
            target: STATES.ERROR,
          },
        ],
      },
    },
    [STATES.CHECK_APP_USER]: {
      always: [
        { target: STATES.APP_USER, cond: GUARDS.IS_APP_USER },
        {
          target: STATES.PERSONALIZED_PLAN,
          cond: GUARDS.HAS_LEARNING_PLAN,
        },
        { target: STATES.BENEFITS },
      ],
    },
    [STATES.APP_USER]: {
      on: {
        [EVENTS.CONTINUE_APP_USER]: [
          {
            target: STATES.PERSONALIZED_PLAN,
            cond: GUARDS.HAS_LEARNING_PLAN,
          },
          { target: STATES.BENEFITS },
        ],
        [EVENTS.BACK_APP_USER]: {
          target: STATES.GET_RESULTS,
        },
      },
    },
    [STATES.PERSONALIZED_PLAN]: {
      on: {
        [EVENTS.CONTINUE_PERSONALIZED_PLAN]: {
          target: STATES.BENEFITS,
        },
        [EVENTS.BACK_PERSONALIZED_PLAN]: {
          target: STATES.GET_RESULTS,
        },
      },
    },
    [STATES.BENEFITS]: {
      on: {
        [EVENTS.CONTINUE_BENEFITS]: [
          {
            target: STATES.AUTH,
            cond: GUARDS.SHOULD_SKIP_PAYMENT,
          },
          {
            target: STATES.PAYMENT,
          },
        ],
        [EVENTS.BACK_BENEFITS]: [
          {
            target: STATES.PERSONALIZED_PLAN,
            cond: GUARDS.HAS_LEARNING_PLAN,
          },
          { target: STATES.GET_RESULTS },
        ],
      },
    },
    [STATES.PAYMENT]: {
      on: {
        [EVENTS.BACK_PAYMENT]: {
          target: STATES.BENEFITS,
        },
        [EVENTS.SET_BILLING_PREFERENCE]: {
          // Will reload the state and setup intent with new billing preference
          actions: { type: ACTIONS.SET_BILLING_PREFERENCE },
          target: STATES.PAYMENT,
        },
        [EVENTS.PAYMENT_ERROR]: {
          target: STATES.ERROR,
        },
      },
      invoke: {
        onDone: {
          actions: { type: ACTIONS.UPDATE_SETUP_INTENT },
        },
        onError: {
          target: STATES.ERROR,
        },
        src: SERVICES.INIT_PAYMENT,
      },
      description:
        'making a purchase through stripe will redirect away from the site\n\nwe resume through INITIAL -> PAYMENT_CALLBACK',
    },
    [STATES.PAYMENT_CALLBACK]: {
      invoke: {
        src: SERVICES.HANDLE_PAYMENT_CALLBACK,
        onDone: {
          target: STATES.AUTH,
          actions: { type: ACTIONS.UPDATE_PAYMENT_EMAIL }, // you need to auth after payment, and we'll need the email
        },
        onError: {
          target: STATES.ERROR,
        },
      },
    },
    [STATES.ERROR]: {
      type: 'final',
    },
    [STATES.AUTH]: {
      on: {
        [EVENTS.GO_TO_CONFIRM_EMAIL]: {
          target: STATES.AUTH_CONFIRM_EMAIL,
        },
      },
      description:
        "we're proceeding through an auth0 redirect to /join/auth-callback which resumes the flow",
    },
    [STATES.AUTH_CONFIRM_EMAIL]: {
      description:
        "we're proceeding through an auth0 redirect to /join/auth-callback which resumes the flow",
      on: {
        [EVENTS.BACK_AUTH_CONFIRM_EMAIL]: {
          target: STATES.AUTH,
        },
      },
    },
    [STATES.AUTH_CALLBACK]: {
      invoke: {
        src: SERVICES.HANDLE_AUTH_CALLBACK,
        onDone: [
          {
            target: STATES.PARTNER_WELCOME,
            actions: { type: ACTIONS.UPDATE_PARTNER_SIGNUP_CODE },
            cond: GUARDS.HAS_PARTNER_SIGNUP_CODE,
          },
          {
            target: STATES.END,
          },
        ],
        onError: [
          {
            actions: { type: ACTIONS.LOGOUT_WITH_ERROR_TOAST },
            cond: GUARDS.IS_WRONG_EMAIL_ERROR,
          },
          {
            target: STATES.ERROR,
          },
        ],
      },
    },
    [STATES.END]: {
      type: 'final',
    },
    [STATES.PARTNER_WELCOME]: {
      on: {
        [EVENTS.CONTINUE_PARTNER_WELCOME]: {
          target: STATES.END,
        },
      },
    },
  },
});
