import React from 'react';
import styled from 'styled-components';
import { PExtraSmall, PSmall } from '../elements/Text';
import {
  useActivityLearnScroll,
  getActivityLessonHeaders,
  ACTIVITY_LEARN_SECTIONS,
} from '../../hooks/use-activity-lesson-scroll-state';
import { ActivityContext, ActivityContextLLMPlaygroundQuestionAnswer, ActivityContextType } from '../../contexts/activity';
import { ACTIVITY_STEP } from './Content';
import CyclingIcons from '../CyclingIcons';

const IS_CAREERIST = process.env.REACT_APP_IS_CAREERIST === 'true';

export const ACTIVITY_NAVIGATION_WIDTH = 250;

const ActivityNavigationWrapper = styled.div`
  min-width: ${ACTIVITY_NAVIGATION_WIDTH}px;
  max-width: ${ACTIVITY_NAVIGATION_WIDTH}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #2f3237;
  padding-bottom: 20px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Text = styled(PSmall)`
  margin: 0;
  padding: 0;
  padding: 12px 0;
`;

const Label = styled(PExtraSmall)`
  margin: 0;
  padding: 4px 10px 6px;
  font-weight: bold;
`;

const Divider = styled.div`
  min-height: 2px;
  background: ${(props) => props.theme.colors.navigation};
`;

const ClickableRow = styled.div<{
  isActive: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  opacity: ${(props) => (props.isDisabled ? 0.3 : 0.8)};
  ${(props) =>
    props.isDisabled
      ? `cursor: not-allowed;`
      : `&:hover {
    opacity: 1;
    cursor: pointer;
  }`}

  transition: all 300ms ease-in-out;

  border-radius: 8px;

  ${(props) =>
    props.isActive &&
    `
    opacity: 1;
    cursor: pointer;
    background-color: ${props.theme.colors.bgActive};
  `}

  ${(props) =>
    props?.isLoading &&
    `
    &:hover {
      cursor: wait;
    }
  `}
`;

const Section = styled.div`
  padding: 20px 12px;
`;

const UncheckedIcon = styled.i.attrs({
  className: 'fa-regular fa-circle',
}) <{ isActive: boolean }>`
  color: ${(props) => props.theme.colors.textRegular};
  font-size: 16px;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.navigation : props.theme.colors.bgPage};
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.isActive ? 'transparent' : props.theme.colors.bgPage)};
  transition: all 300ms ease-in-out;
  z-index: 1;
`;

const ReviewIcon = styled.i.attrs({
  className: 'fa-solid fa-comment-captions',
}) <{ xOffset: number }>`
  color: ${(props) => props.theme.colors.textRegular};
  font-size: 16px;
  ${(props) => props.xOffset && `transform: translateX(${props.xOffset}px)`};
  z-index: 0;
`;

const AIIcon = styled.i.attrs({
  className: 'fa-solid fa-sparkles',
})`
  color: ${(props) => props.theme.colors.textRegular};
  font-size: 16px;
  z-index: 0;
`;

const CheckedIcon = styled.i.attrs<{ isActive: boolean; isCorrect: boolean; doesSatisfyAllRequirements: boolean | null }>(
  ({ isCorrect }) => ({
    className: isCorrect
      ? 'fa-solid fa-check-circle'
      : 'fa-solid fa-times-circle',
  })
) <{ isActive: boolean; isCorrect: boolean; doesSatisfyAllRequirements: boolean | null }>`
  color: ${(props) =>
    props.isCorrect && props.doesSatisfyAllRequirements
      ? props.theme.colors.primary
      : props.theme.colors.secondary};
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid
    ${(props) =>
    props.isActive
      ? props.theme.colors.navigation
      : props.theme.colors.bgPage};
  transition: all 300ms ease-in-out;
  z-index: 1;
`;

const IconsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: -10px;
`;

const QuestionIndicators = ({
  activity,
  answer,
  questionIndex,
  isActive,
  isCorrect,
  doesSatisfyAllRequirements,
}: {
  activity: ActivityContextType;
  answer: ActivityContextType['submission']['questionSlugToAnswer'][string];
  questionIndex: number;
  isActive: boolean;
  isCorrect: boolean | null;
  doesSatisfyAllRequirements: boolean | null;
}) => {
  const hasMentorComment =
    !!activity?.mentorReview?.questionComments?.[questionIndex];
  // TODO: we should also save correctness state so we shoudln't need to run all sql queries to figure out if the answer is correct
  return (
    <IconsRow>
      {hasMentorComment && <ReviewIcon xOffset={6} />}
      {answer.isAnswered ? (
        <CheckedIcon isActive={isActive} isCorrect={isCorrect !== false} doesSatisfyAllRequirements={doesSatisfyAllRequirements} />
      ) : (
        <UncheckedIcon isActive={isActive} />
      )}
    </IconsRow>
  );
};

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 12px 10px 12px 24px;
  align-items: center;
`;

const ToggleButton = styled.i.attrs({
  className: 'fa-solid fa-chevrons-left',
})`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 16px;
  padding: 14px;
  transition: all 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

const ActivityNavigation = ({
  onClose,
  submitAndMoveToQuestion,
  submitAndMoveToReview,
  submitAndMoveToLesson,
  isSubmitting,
}: {
  onClose: () => void;
  submitAndMoveToQuestion: ({
    questionIndex,
    questionSlug,
  }: {
    questionIndex: number;
    questionSlug: string;
  }) => Promise<void>;
  submitAndMoveToReview: () => Promise<void>;
  submitAndMoveToLesson: () => Promise<void>;
  isSubmitting: boolean;
}) => {
  const [activity] = React.useContext(ActivityContext);
  const lessonHeaders = getActivityLessonHeaders(activity.exercise.lesson);
  const questions = activity.exercise.questions;

  const { activeSection, scrollTo } = useActivityLearnScroll(lessonHeaders);
  const [activeStep, setActiveStep] = React.useState(activity.currentStep);

  const hasOverallMentorFeedback =
    !!activity.mentorReview.review || !!activity.mentorReview.score;
  const hasAnsweredAllQuestions = questions.every(
    (question) =>
      activity.submission.questionSlugToAnswer[question.slug].isAnswered
  );
  const hasAIReview = activity.latestSavedSubmission?.ai_review;

  const currentQuestionAnswer =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.submission.questionSlugToAnswer[
      (
        activity.currentStep as {
          type: ACTIVITY_STEP.QUESTION;
          questionSlug: string;
        }
      ).questionSlug
      ]
      : null;

  React.useEffect(() => {
    setActiveStep(activity.currentStep);
  }, [activity.currentStep]);

  const canMoveFromQuestion = () => {
    if (activeStep?.type !== ACTIVITY_STEP.QUESTION) {
      return true;
    }

    if (currentQuestionAnswer?.isAnswered) {
      return true;
    }

    return window.confirm(
      "You haven't tried this exercise yet. Are you sure you want to move on?"
    );
  };

  const canMoveToReview = () => {
    if (activeStep?.type === ACTIVITY_STEP.FEEDBACK) {
      return true;
    }

    if (hasAIReview) {
      return true;
    }

    if (hasAnsweredAllQuestions) {
      return true;
    }

    return window.confirm(
      'We recommend attempting all exercises before moving to the review. Do you want to continue?'
    );
  };

  const goToLessonSection = async (header?: string) => {
    if (canMoveFromQuestion()) {
      setActiveStep({
        type: ACTIVITY_STEP.LESSON,
      });
      await submitAndMoveToLesson();
      return setTimeout(
        () => {
          return scrollTo(header);
        },
        activeStep?.type === ACTIVITY_STEP.LESSON ? 0 : 500
      );
    }
  };

  const goToQuestion = async (questionIndex: number) => {
    if (canMoveFromQuestion()) {
      const questionSlug = questions[questionIndex].slug;
      setActiveStep({
        type: ACTIVITY_STEP.QUESTION,
        questionIndex,
        questionSlug,
      });
      await submitAndMoveToQuestion({ questionIndex, questionSlug });
    }
  };

  const goToReview = async () => {
    if (canMoveToReview()) {
      setActiveStep({
        type: ACTIVITY_STEP.FEEDBACK,
      });
      await submitAndMoveToReview();
    }
  };

  const shouldLockReview = IS_CAREERIST && !hasAnsweredAllQuestions;

  return (
    <ActivityNavigationWrapper>
      <HeaderRow>
        <Text style={{ fontWeight: 'bold' }}>{activity.content_title}</Text>
        <ToggleButton onClick={onClose} />
      </HeaderRow>
      <Divider />
      <Section>
        <Label>Intro</Label>
        <ClickableRow
          onClick={() => goToLessonSection()}
          isActive={
            activeStep?.type === ACTIVITY_STEP.LESSON &&
            activeSection === ACTIVITY_LEARN_SECTIONS.TITLE.getSection()
          }
          isLoading={isSubmitting}
        >
          <Text>{activity.title}</Text>
        </ClickableRow>
        {lessonHeaders.map((header) => {
          return (
            <ClickableRow
              key={`activity-navigation-header-${header}`}
              onClick={() => goToLessonSection(header)}
              isLoading={isSubmitting}
              isActive={
                activeStep?.type === ACTIVITY_STEP.LESSON &&
                activeSection ===
                ACTIVITY_LEARN_SECTIONS.HEADER.getSection(header)
              }
            >
              <Text>{header}</Text>
            </ClickableRow>
          );
        })}
      </Section>
      <Divider />
      <Section>
        <Label>Exercises</Label>
        {questions.map((question, index) => {
          const isLocked =
            IS_CAREERIST &&
            (index === 0
              ? false
              : !activity.submission.questionSlugToAnswer[
                questions[index - 1].slug
              ]?.isAnswered);

          return (
            <ClickableRow
              key={`activity-navigation-question-${index}`}
              onClick={isLocked ? () => { } : () => goToQuestion(index)}
              isActive={
                activeStep?.type === ACTIVITY_STEP.QUESTION &&
                activeStep?.questionIndex === index
              }
              isLoading={isSubmitting}
              isDisabled={isLocked}
            >
              <Text>Exercise {index + 1}</Text>
              <QuestionIndicators
                activity={activity}
                questionIndex={index}
                answer={activity.submission.questionSlugToAnswer[question.slug]}
                isActive={
                  activeStep?.type === ACTIVITY_STEP.QUESTION &&
                  activeStep?.questionIndex === index
                }
                isCorrect={
                  activity.submission.questionSlugToAnswer[question.slug]
                    .isCorrect
                }
                doesSatisfyAllRequirements={
                  (activity.submission.questionSlugToAnswer[question.slug] as ActivityContextLLMPlaygroundQuestionAnswer)
                    .doesSatisfyAllRequirements !== false
                }
              />
            </ClickableRow>
          );
        })}
      </Section>
      <Divider />
      <Section>
        <ClickableRow
          onClick={shouldLockReview ? () => { } : goToReview}
          isActive={activeStep?.type === ACTIVITY_STEP.FEEDBACK}
          isLoading={isSubmitting}
          isDisabled={shouldLockReview}
        >
          <Text>Review and Continue</Text>
          <IconsRow>
            {hasOverallMentorFeedback ? (
              <CyclingIcons icons={[<ReviewIcon xOffset={0} />, <AIIcon />]} />
            ) : (
              <AIIcon />
            )}
          </IconsRow>
        </ClickableRow>
      </Section>
    </ActivityNavigationWrapper>
  );
};

export default ActivityNavigation;
