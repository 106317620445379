import React from 'react';
import { PaymentPage } from '../../components/SoloOnboarding/Screens/Payment';
import useAuth from '../../hooks/use-auth';
import { createProsumerLearnModePaymentIntent } from '../../hydra';
import Navbar from '../../components/Navbar';
import { useGetProsumerProfile } from '../../hooks/use-hydra';
import FullScreenAlert from '../../components/Alert/FullScreenAlert';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PageWithAsyncData from '../../components/PageWithAsyncData';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';

const PageWrapper = styled(PageWithAsyncData)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

export default function Payment() {
  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_PAYMENT, {
    flowType: 'logged_in',
  });
  // should show benefits, then the stripe payments element page
  const { user } = useAuth();
  const history = useHistory();

  const {
    value: profileValue,
    loading: isLoadingProfile,
    error,
    retry,
  } = useGetProsumerProfile();
  const { shouldGetTrial, hasAccess } = profileValue?.learnMode || {};
  const [isMonthlySubscription, setIsMonthlySubscription] =
    React.useState(false);

  React.useEffect(() => {
    if (!profileValue || hasAccess) {
      history.push('/');
    }
  }, [profileValue, hasAccess, history]);

  const updatePaymentIntent = React.useCallback(async () => {
    const { paymentIntentClientSecret, discountPercentage } =
      await createProsumerLearnModePaymentIntent({
        userEmail: user?.email ?? '',
        isMonthlySubscription: isMonthlySubscription,
      });
    setPaymentIntentClientSecret(paymentIntentClientSecret);
    setDiscountPercentage(discountPercentage ?? null);
  }, [isMonthlySubscription, user?.email]);

  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    React.useState<string | undefined | null>();

  const [discountPercentage, setDiscountPercentage] =
    React.useState<number | null>(null);
  const toggleBillingPreference = () => {
    setPaymentIntentClientSecret(null);
    setIsMonthlySubscription(!isMonthlySubscription);
  };
  const [paymentError, setPaymentError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    updatePaymentIntent();
  }, [updatePaymentIntent]);

  const onError = (error: Error) => {
    setPaymentError(error);
  };
  return (
    <>
      {paymentError ? (
        <FullScreenAlert text={paymentError.message} />
      ) : (
        <PageWrapper
          isLoading={isLoadingProfile}
          className={''}
          error={error}
          retry={retry}
        >
          <Navbar />
          <PaymentPage
            email={user?.email ?? ''}
            returnUrl={`${window.location.origin}/payment-callback`}
            paymentIntentClientSecret={paymentIntentClientSecret}
            isMonthlySubscription={isMonthlySubscription}
            toggleBillingPreference={toggleBillingPreference}
            shouldShowTrial={shouldGetTrial ?? true}
            discountPercentage={discountPercentage}
            onLoadError={() => {
              onError(new Error('Failed to load payment page'));
              return null;
            }}
          />
        </PageWrapper>
      )}
    </>
  );
}
