import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  Header,
  ButtonsWrapper,
  CenteredContainer,
  PillButton,
  ProgressBar,
  ReadableContainer,
  HighlightWord,
} from './common';
import { TOPICS_DATA } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

export default function AskTopicKnowledge() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);
  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_TOPIC_KNOWLEDGE,
    state.context
  );
  const primaryTopic = state?.context?.topic;
  const secondaryTopics = state?.context?.secondaryTopics;

  if (!primaryTopic) {
    throw new Error('Primary topic is not defined');
  }

  const topic = [primaryTopic, ...secondaryTopics].find(
    (t) => !state.context.knowledgeOfTopics.find((c) => c?.topic === t)
  );

  const placementTest = topic ? TOPICS_DATA.options[topic]?.placementTest : null;

  if (!placementTest) {
    throw new Error('Placement test is not defined');
  }

  if (!topic) {
    throw new Error('Topic is not defined');
  }

  const topicData = TOPICS_DATA.options[topic];

  // Use key to ensure that fade animation gets applied (Ie force rerender when topic changes)
  return (
    <ReadableContainer key={topic + 'placement test'}>
      <CenteredContainer>
        <ProgressBar currentStep={5} />
        <LabelUppercase>Step 5</LabelUppercase>
        <Header>
          <HighlightWord text={topicData.knowledgeQuestion} word={topicData.label} />
        </Header>
        <ButtonsWrapper>
          {placementTest.options.map((option) => (
            <PillButton
              key={option.value}
              isSelected={
                state?.context?.knowledgeOfTopics.find(
                  (c) => c?.topic === topic
                )?.knowledge === option.value
              }
              onClick={() => {
                send({
                  type: EVENTS.SELECT_TOPIC_KNOWLEDGE,
                  topicKnowledge: option.value,
                  topic,
                });
                send({
                  type: EVENTS.CONTINUE_ASK_TOPIC_KNOWLEDGE,
                });
              }}
              label={option.label}
            />
          ))}
        </ButtonsWrapper>
      </CenteredContainer>
    </ReadableContainer>
  );
}
