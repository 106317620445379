import {
  ADVANCED_PLACEMENT_OPTION,
  COMPLETE_BEGINNER_PLACEMENT_OPTION,
  GOAL_CATEGORIES,
  GOALS,
} from './config';
import { ContextType, EventType } from './state-machine';

export enum GUARDS {
  DID_SELECT_TOPIC = 'DID_SELECT_TOPIC',
  DID_SELECT_GOAL = 'DID_SELECT_GOAL',
  DID_SELECT_ALL_KNOWLEDGES = 'DID_SELECT_ALL_KNOWLEDGES',
  DID_NOT_SELECT_ANY_KNOWLEDGE = 'DID_NOT_SELECT_ANY_KNOWLEDGE',
  DID_SELECT_TIME_EFFORT = 'DID_SELECT_TIME_EFFORT',
  DID_ADD_EMAIL = 'DID_ADD_EMAIL',
  HAS_PARTNER_SIGNUP_CODE = 'HAS_PARTNER_SIGNUP_CODE',
  IS_EMAIL_VALID_ERROR = 'IS_EMAIL_VALID_ERROR',
  IS_WRONG_EMAIL_ERROR = 'IS_WRONG_EMAIL_ERROR',
  SHOULD_SKIP_PAYMENT = 'SHOULD_SKIP_PAYMENT',
  IS_APP_USER = 'IS_APP_USER',
  IS_CAREER_STARTER = 'IS_CAREER_STARTER',
  IS_INTERMEDIATE_LEARNER = 'IS_INTERMEDIATE_LEARNER',
  HAS_LEARNING_PLAN = 'HAS_LEARNING_PLAN',
}

export default {
  [GUARDS.DID_SELECT_TOPIC]: (context: ContextType) => {
    return !!context.topic;
  },
  [GUARDS.IS_CAREER_STARTER]: (context: ContextType) => {
    return (
      Object.entries(GOALS.options).find(([, g]) => g.value === context.goal)?.[1]
        .category === GOAL_CATEGORIES.START
    );
  },
  [GUARDS.DID_SELECT_GOAL]: (context: ContextType) => {
    return !!context.goal;
  },
  [GUARDS.DID_SELECT_ALL_KNOWLEDGES]: (context: ContextType) => {
    // Should be a module knowledge selection for each secondary topic + the primary topic
    return (
      (context.knowledgeOfTopics ?? []).filter((c) => c !== null).length ===
      context.secondaryTopics.length + 1
    );
  },
  [GUARDS.DID_NOT_SELECT_ANY_KNOWLEDGE]: (context: ContextType) => {
    return context.knowledgeOfTopics.length === 1;
  },
  [GUARDS.IS_INTERMEDIATE_LEARNER]: (context: ContextType) => {
    const primaryTopicKnowledge = context.knowledgeOfTopics.find(
      (c) => c?.topic === context.topic
    );
    return (
      primaryTopicKnowledge?.knowledge !== COMPLETE_BEGINNER_PLACEMENT_OPTION &&
      primaryTopicKnowledge?.knowledge !== ADVANCED_PLACEMENT_OPTION
    );
  },
  [GUARDS.DID_SELECT_TIME_EFFORT]: (context: ContextType) => {
    return !!context.timeEffort;
  },
  [GUARDS.DID_ADD_EMAIL]: (context: ContextType) => {
    return !!context.email;
  },
  [GUARDS.SHOULD_SKIP_PAYMENT]: (context: ContextType) => {
    return !!context.shouldSkipPayment;
  },
  [GUARDS.IS_APP_USER]: (context: ContextType) => {
    return !!context.isAppUser;
  },
  [GUARDS.HAS_PARTNER_SIGNUP_CODE]: (
    context: ContextType,
    event: EventType
  ) => {
    // @ts-expect-error it's hard to type these events...
    return !!context.partnerCode || !!event?.data?.partnerCode;
  },
  [GUARDS.IS_EMAIL_VALID_ERROR]: (_: ContextType, event: EventType) => {
    // @ts-expect-error didn't type the onError response event;
    const errorMessage = event?.data?.response?.data?.message;
    return (
      errorMessage ===
      'Sorry, this email is not available. Try logging in instead.'
    );
  },
  [GUARDS.IS_WRONG_EMAIL_ERROR]: (_: ContextType, event: EventType) => {
    // @ts-expect-error didn't type the onError response event;
    const errorMessage = event?.data?.response?.data?.message;
    return (
      errorMessage === 'Sorry, this looks like the wrong account.' ||
      errorMessage === 'Sorry, we could not find your account'
    );
  },
  [GUARDS.HAS_LEARNING_PLAN]: (context: ContextType) => {
    return !!context.learningPlan;
  },
};
