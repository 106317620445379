import styled from 'styled-components';
import { MultiLineInput } from '../elements/Input';
import { PSmall } from '../elements/Text';
import { AI_TITLE } from './FloatingChat';

const AiChatFooter = styled.div`
  align-self: flex-end;
  width: 100%;
  padding: 16px;
`;

const StyledInput = styled(MultiLineInput)`
  margin: 0;
  padding: 0;
`;

const ASmall = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  font-feature-settings: 'tnum' on, 'lnum' on;

  :hover {
    cursor: pointer;
  }
`;

const UsageIndicator = ({ dailyMessagesCount, onShowUpsell }) => {
  const numQuestionsLeft = 3 - dailyMessagesCount;
  const questionsLeftCopy =
    numQuestionsLeft <= 0
      ? 'No free questions left today.'
      : `${numQuestionsLeft} free question${
          numQuestionsLeft > 1 ? 's' : ''
        } left today.`;

  return (
    <PSmall style={{ marginTop: '10px', paddingBottom: 0, textAlign: 'end' }}>
      {questionsLeftCopy}{' '}
      <ASmall onClick={onShowUpsell}>Unlock Learn Mode</ASmall>
    </PSmall>
  );
};

const InputFooter = ({
  onInputChange,
  onSubmit,
  input,
  isDisabled,
  shouldLock,
  dailyMessagesCount,
  onShowUpsell,
}) => {
  return (
    <AiChatFooter>
      <StyledInput
        maxRows={4}
        value={input}
        placeholder={`Ask ${AI_TITLE} something...`}
        onChange={(e) => onInputChange(e.target.value)}
        onSubmit={() => onSubmit(input)}
        isDisabled={isDisabled}
        shouldSubmitOnEnter
        shouldFocusAfterDisable
        autoFocus
      />
      {shouldLock && (
        <UsageIndicator
          dailyMessagesCount={dailyMessagesCount}
          onShowUpsell={onShowUpsell}
        />
      )}
    </AiChatFooter>
  );
};

export default InputFooter;
