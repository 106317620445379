import styled from 'styled-components';
import { PExtraSmall, PSmall } from './Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.bgActive};
  padding: 3px;
  justify-content: space-evenly;
  gap: 3px;
  margin-top: 10px;
`;

const Tab = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 8px 0px 4px 0px;
  border-radius: 5px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.bgDefault : 'transparent'};
  cursor: ${({ isActive, isDisabled }) => {
    if (isActive) {
      return 'default';
    } else if (isDisabled) {
      return 'not-allowed';
    } else {
      return 'pointer';
    }
  }};
  transition: all 0.3s ease-in-out;
`;

const TabHeader = styled(PExtraSmall)<{ isActive: boolean }>`
  padding: 0;
  margin: 0;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.textActive : theme.colors.textRegular};
  transition: all 0.3s ease-in-out;
`;

const LoaderLine = styled.div<{ shouldHide: boolean }>`
  background-color: ${({ shouldHide }) =>
    shouldHide ? 'transparent' : 'transparent'};
  height: 3px;
  width: 50px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background-color: ${({ shouldHide }) =>
      shouldHide ? 'transparent' : '#aaa'};
    width: 40%;
    height: 100%;
    left: 0;
    top: 0;
    animation: loading 1.5s linear infinite;
  }

  @keyframes loading {
    0% {
      left: -50%;
    }
    100% {
      left: 150%;
    }
  }
`;
export default function Tabs({
  options,
  selectedOptionIndex,
  onClick,
  loadingStates,
  disabledStates,
}: {
  options: string[];
  selectedOptionIndex: number;
  onClick: (index: number) => void;
  loadingStates?: boolean[];
  disabledStates?: boolean[];
}) {
  return (
    <Wrapper>
      {options.map((option, index) => (
        <Tab
          isActive={index === selectedOptionIndex}
          onClick={
            disabledStates
              ? () => {
                  if (!disabledStates[index]) {
                    onClick(index);
                  }
                }
              : () => onClick(index)
          }
          key={option}
          isDisabled={disabledStates ? disabledStates[index] : false}
        >
          <TabHeader isActive={index === selectedOptionIndex}>
            {option}
          </TabHeader>
          {loadingStates && <LoaderLine shouldHide={!loadingStates[index]} />}
        </Tab>
      ))}
    </Wrapper>
  );
}

const LightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const LightTab = styled.div<{ isActive: boolean }>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4px 12px;
  background: transparent;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  transition: all 0.3s ease-in-out;
`;

const LightTabHeader = styled(PSmall)<{ isActive: boolean }>`
  margin-top: 0;
  padding-bottom: 4px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.textActive : theme.colors.textRegular};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  transition: all 0.3s ease-in-out;

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-bottom: 2px solid ${theme.colors.primary};
  `}
`;

export function LightTabs({
  options,
  selectedOptionIndex,
  onClick,
}: {
  options: string[];
  selectedOptionIndex: number;
  onClick: (index: number) => void;
}) {
  return (
    <LightWrapper>
      {options.map((option, index) => (
        <LightTab
          key={`light-tab-${options}-${index}`}
          isActive={index === selectedOptionIndex}
          onClick={() => onClick(index)}
        >
          <LightTabHeader isActive={index === selectedOptionIndex}>
            {option}
          </LightTabHeader>
        </LightTab>
      ))}
    </LightWrapper>
  );
}
