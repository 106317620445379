import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  PillButton,
  ProgressBar,
  ButtonsWrapper,
  CenteredContainer,
  Header,
  ReadableContainer,
  ButtonContainer,
} from './common';
import { PROFILES } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

export default function AskProfile() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_PROFILE,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={2} />
        <LabelUppercase>Step 2</LabelUppercase>
        <Header>{PROFILES.question}</Header>
        <ButtonContainer>
          <ButtonsWrapper>
            {Object.entries(PROFILES.options).map(([key, { value, label }]) => (
              <PillButton
                key={key}
                label={label}
                isSelected={state.context.profile === value}
                onClick={() => {
                  send({
                    type: EVENTS.SELECT_PROFILE,
                    profile: value,
                  });
                  send({
                    type: EVENTS.CONTINUE_ASK_PROFILE,
                  });
                }}
              />
            ))}
          </ButtonsWrapper>
        </ButtonContainer>
      </CenteredContainer>
    </ReadableContainer>
  );
}
