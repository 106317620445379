import { H1 } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextStrictTextQuestionAnswer,
} from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  getQuestionText,
} from './common';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';
import Button from '../../elements/Button';
import { toast } from 'react-toastify';
export default function StrictTextQuestion({
  question,
  answer,
}: QuestionProps & { answer: ActivityContextStrictTextQuestionAnswer }) {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (checked) {
      if (answer.isCorrect) {
        toast.success('Correct!');
      } else if (answer.isCorrect === false) {
        toast.warn('Not quite!');
      }
      setChecked(false); // Reset after showing the toast
    }
  }, [answer.isCorrect, checked]);

  const onStrictTextQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_STRICT_TEXT_QUESTION,
      payload: {
        question,
        answer: value,
      },
    });
  };

  const onStrictTextQuestionTextVerify = () => {
    dispatch({
      type: ACTIVITY_ACTION.VERIFY_ANSWER_STRICT_TEXT_QUESTION,
      payload: {
        question,
        answer: answer.value,
      },
    });

    setChecked(true);
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) => onStrictTextQuestionTextChange(e.target.value)}
        />
        <ButtonsRow>
          <Button
            type="primary"
            label="Check Answer"
            faIcon="fas fa-check"
            onClick={() => onStrictTextQuestionTextVerify()}
          />
        </ButtonsRow>
      </ReadableContainer>
      <BottomSpacer />
    </FullScreenQuestion>
  );
}
