import React from 'react';
import { useHistory } from 'react-router-dom';
import useURLQuery from '../../hooks/use-url-query';
import {
  useGetProsumerProfile,
  useProjectsDashboard,
} from '../../hooks/use-hydra';
import {
  getAppliedProjectUrl,
  getProsumerActivityUrl,
  getProsumerDashboardUrl,
} from '../../routing';

export const CONTINUE_PROGRESS_QUERYPARAM = 'continueProgress';
export const CONTENT_SLUG_QUERYPARAM = 'contentSlug';
export const APPLIED_PROJECT_SLUG_QUERYPARAM = 'projectSlug';

export default function ContinueContnetModuleProgress() {
  const history = useHistory();
  const urlQuery = useURLQuery();

  const { value: profileValue, error: profileError } = useGetProsumerProfile();
  const { value: projectValue, error: projectError } = useProjectsDashboard();
  const contentSlug = urlQuery.get(CONTENT_SLUG_QUERYPARAM);
  const projectSlug = urlQuery.get(APPLIED_PROJECT_SLUG_QUERYPARAM);

  const content = (profileValue?.contentModules ?? []).find(
    (module) => module.contentSlug == contentSlug
  );
  const project = (projectValue ?? []).find((p) => {
    return p.slug == projectSlug;
  });

  const shouldContinueModule =
    !!content &&
    urlQuery.get(CONTINUE_PROGRESS_QUERYPARAM) === 'true' &&
    !!profileValue &&
    content.isJoined;

  const shouldContinueProject =
    !shouldContinueModule &&
    !!project &&
    urlQuery.get(CONTINUE_PROGRESS_QUERYPARAM) === 'true' &&
    project.is_joined;

  // If queries are done loading but there is no matching content or project, remove the query params
  const shouldRemoveQueryParams =
    (!!profileValue || !!profileError) &&
    !shouldContinueModule &&
    (!!projectValue || !!projectError) &&
    !shouldContinueProject;

  const removeQueryParams = React.useCallback(() => {
    const prevQuery = urlQuery.toString();
    urlQuery.delete(CONTINUE_PROGRESS_QUERYPARAM);
    urlQuery.delete(CONTENT_SLUG_QUERYPARAM);
    urlQuery.delete(APPLIED_PROJECT_SLUG_QUERYPARAM);
    if (urlQuery.toString() !== prevQuery) {
      history.replace({
        search: urlQuery.toString(),
      });
    }
  }, [urlQuery, history]);

  React.useEffect(() => {
    if (shouldContinueModule) {
      const nextActivitySlug = content?.nextActivitySlug;
      const nextMissionSlug = content?.nextMissionSlug;

      if (nextActivitySlug && nextMissionSlug) {
        history.push(getProsumerActivityUrl(nextMissionSlug, nextActivitySlug));
      } else {
        history.push(getProsumerDashboardUrl(content?.contentSlug ?? ''));
      }
    } else if (shouldContinueProject) {
      history.push(getAppliedProjectUrl(project.slug));
    } else if (shouldRemoveQueryParams) {
      removeQueryParams();
    }
  }, [
    history,
    content,
    project,
    shouldContinueModule,
    shouldContinueProject,
    shouldRemoveQueryParams,
    removeQueryParams,
  ]);

  return null;
}
