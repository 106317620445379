import React from 'react';
import { PaymentPage } from '../../components/SoloOnboarding/Screens/Payment';
import { createProsumerLearnModePaymentIntent } from '../../hydra';
import FullScreenAlert from '../../components/Alert/FullScreenAlert';
import { ProsumerPublicUpsell } from '../../contexts/prosumer-public-upsell';
import PageWrapper from '../../components/PageWrapper';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';
import styled from 'styled-components';

const Page = styled(PageWrapper)`
  padding: 0 !important;
`;

export default function ProsumerPublicUpsellPayment() {
  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_PAYMENT, {
    flowType: 'public_token',
  });

  const { user, shouldGetTrial } = React.useContext(ProsumerPublicUpsell);

  const [isMonthlySubscription, setIsMonthlySubscription] =
    React.useState(false);

  const updatePaymentIntent = React.useCallback(async () => {
    const { paymentIntentClientSecret, discountPercentage } =
      await createProsumerLearnModePaymentIntent({
        userEmail: user?.email ?? '',
        isMonthlySubscription: isMonthlySubscription,
      });
    setPaymentIntentClientSecret(paymentIntentClientSecret);
    setDiscountPercentage(discountPercentage ?? null);
  }, [isMonthlySubscription, user?.email]);

  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    React.useState<string | undefined | null>();

  const [discountPercentage, setDiscountPercentage] =
    React.useState<number | null>(null);
  const toggleBillingPreference = () => {
    setPaymentIntentClientSecret(null);
    setIsMonthlySubscription(!isMonthlySubscription);
  };
  const [paymentError, setPaymentError] = React.useState<Error | null>(null);
  React.useEffect(() => {
    updatePaymentIntent();
  }, [updatePaymentIntent]);

  const onError = (error: Error) => {
    setPaymentError(error);
  };

  return (
    <>
      {paymentError ? (
        <FullScreenAlert text={paymentError.message} />
      ) : (
        <Page>
          <PaymentPage
            email={user?.email ?? ''}
            returnUrl={`${window.location.origin}/buy/payment-callback`}
            paymentIntentClientSecret={paymentIntentClientSecret}
            isMonthlySubscription={isMonthlySubscription}
            toggleBillingPreference={toggleBillingPreference}
            shouldShowTrial={shouldGetTrial}
            discountPercentage={discountPercentage}
            onLoadError={() => {
              onError(new Error('Failed to load payment page'));
              return null;
            }}
          />
        </Page>
      )}
    </>
  );
}
