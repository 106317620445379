import PageWrapper from '../../components/PageWrapper';
import BenefitsContent from '../../components/Benefits';
import { useHistory } from 'react-router-dom';
import { publicUpsellPaymentPath } from '../../routing';
import styled from 'styled-components';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';

const Page = styled(PageWrapper)`
  padding: 0;
  margin: 0;
`;

export default function ProsumerPublicUpsellBenefits() {
  const history = useHistory();
  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_BENEFITS, {
    flowType: 'public_token',
  });

  return (
    <Page>
      <BenefitsContent
        onNext={() => {
          history.push(publicUpsellPaymentPath);
          window.scrollTo(0, 0);
        }}
      />
    </Page>
  );
}
