import React from 'react';
import styled from 'styled-components';
import {
  ACTIVITY_ACTION,
  ACTIVITY_TYPE,
  AIReview,
  ActivityContext,
  ActivityContextQuestionAnswer,
  ActivityContextType,
} from '../../contexts/activity';
import { H1, H3, LabelUppercase, P } from '../elements/Text';
import {
  ContentSpacer,
  ReadableContainer,
  ScrollableFullScreen,
} from './Containers';
import MentorReview from './MentorComment';
import { ActivityPayload } from '../../hydra';
import Markdown from '../Markdown';
import TakeawaysContainer from './TakeawaysContainer';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ACTIVITY_PAGE_TYPE, ActivityPageProps } from '.';
import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
// @ts-expect-error - .riv files are not supported by typescript
import loaderAnim from '../../assets/rive-animations/minimal-loader.riv';
import Feedback from './Feedback';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <ScrollableFullScreen>
    <ContentSpacer>
      <ReadableContainer style={{ height: '100%' }}>
        {children}
      </ReadableContainer>
    </ContentSpacer>
  </ScrollableFullScreen>
);

export default function Review({
  getActivity,
  createAIReview,
  activityType,
}: {
  getActivity: () => Promise<ActivityPayload>;
  createAIReview: (data: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }) => Promise<void>;
  activityType: ActivityPageProps['type'];
}) {
  const [activity, dispatch] = React.useContext(ActivityContext);

  const aiReview = activity.latestSavedSubmission?.ai_review;
  const { difficultyRating, feedback } = activity.submission;
  const hasAnsweredQuestions = activity.exercise.questions.some(
    (question) =>
      activity.submission.questionSlugToAnswer[question.slug].isAnswered
  );

  const hasMentorReview =
    activity.mentorReview.review || activity.mentorReview.score;

  const onFeedbackChange = (feedback: string) => {
    dispatch({ type: ACTIVITY_ACTION.SET_FEEDBACK, payload: { feedback } });
  };
  const onDifficultyChange = (difficultyRating: number) => {
    dispatch({
      type: ACTIVITY_ACTION.SET_DIFFICULTY,
      payload: { difficultyRating },
    });
  };

  const reviewMutation = useMutation({
    mutationFn: async () => {
      try {
        await createAIReview({
          activitySlug: activity.slug,
          submissionId: activity.latestSavedSubmission?.id || -1,
          questionSlugToAnswer: activity.submission.questionSlugToAnswer,
        });
        const newActivityPayload = await getActivity();
        dispatch({
          type: ACTIVITY_ACTION.REFRESH_SUBMISSION,
          payload: {
            activity: newActivityPayload,
          },
        });
      } catch (err) {
        console.error(err);
        toast.error('Sorry, something went wrong.');
      }
    },
  });

  React.useEffect(() => {
    if (reviewMutation.isIdle && !aiReview && hasAnsweredQuestions) {
      reviewMutation.mutate();
    }
  }, [reviewMutation, aiReview, createAIReview, hasAnsweredQuestions]);

  if (reviewMutation.isIdle && !hasAnsweredQuestions) {
    return (
      <Wrapper>
        <H1>Your Review is waiting!</H1>
        <P>
          It looks like you haven't answered any exercises yet. 🤔 Come back
          after you've attempted some, and you'll get a personalized review.
        </P>
        <Feedback
          type={activity.type}
          feedback={feedback}
          onFeedbackChange={onFeedbackChange}
          difficultyRating={difficultyRating}
          onDifficultyChange={onDifficultyChange}
        />
      </Wrapper>
    );
  }

  if (reviewMutation.isLoading || (reviewMutation.isIdle && !aiReview)) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <H1>Your Review is Ready!</H1>
      {activityType === ACTIVITY_PAGE_TYPE.PREVIEW && (
        <P>PREVIEW MODE - AI & Mentor reviews are not supported.</P>
      )}

      {aiReview && <AIReviewSection aiReview={aiReview} activity={activity} />}
      {hasMentorReview && (
        <MentorReview
          comment={activity.mentorReview.review}
          rating={activity.mentorReview.score}
        />
      )}
      <Feedback
        type={activity.type}
        feedback={feedback}
        onFeedbackChange={onFeedbackChange}
        difficultyRating={difficultyRating}
        onDifficultyChange={onDifficultyChange}
      />
    </Wrapper>
  );
}

function AIReviewSection({
  aiReview,
  activity,
}: {
  aiReview: AIReview;
  activity: ActivityContextType;
}) {
  const { type } = activity;
  return (
    <>
      <P>
        Great job with your work on the
        <b> {activity.title} </b>{' '}
        {type === ACTIVITY_TYPE.PROJECT ? 'project' : 'activity'}! 🎉
      </P>
      <TakeawaysContainer
        content={aiReview.lesson_takeaways
          .map((t, ind) => `${ind + 1}. ${t}`)
          .join('\n')}
      />

      {aiReview.progress_suggestions && (
        <>
          <H3>❗️Suggestions</H3>
          <Markdown md={aiReview.progress_suggestions} />
        </>
      )}
      {aiReview.further_insights && (
        <>
          <H3>💪 Go further</H3>
          <Markdown md={aiReview.further_insights} />
        </>
      )}
    </>
  );
}

function Loader() {
  const [showTitle, setShowTitle] = React.useState(false);

  const { RiveComponent } = useRive({
    src: loaderAnim,
    autoplay: true,
    onPlay: () => {
      setShowTitle(true);
    },
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.Center,
    }),
  });
  return (
    <Wrapper>
      <CenteredContainer>
        <div style={{ height: 300, width: 300 }}>
          <RiveComponent />
        </div>
        {showTitle && (
          <LabelUppercase style={{ margin: 0, marginTop: -100 }}>
            Generating your review
          </LabelUppercase>
        )}
      </CenteredContainer>
    </Wrapper>
  );
}
