import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import useAuth from '../../hooks/use-auth';
import FullScreenLoader from '../Loader/FullScreenLoader';
import useAuthenticateSilently from '../../hooks/use-authenthicate-silently';
import { ProsumerPublicUpsell } from '../../contexts/prosumer-public-upsell';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

const UnauthenthicatedRoute = ({
  path,
  children,
  needsUpsellData = true,
  ...rest
}: {
  path: string;
  children: React.ReactNode;
  exact?: boolean;
  needsUpsellData?: boolean;
}) => {
  const { didAuthenticate, isInitialised, isAuthenticated } = useAuth();
  const { loadProsumerPublicData, isLoadingPlan, emailToken, hasSubscription } =
    React.useContext(ProsumerPublicUpsell);
  const history = useHistory();

  useAuthenticateSilently();

  const loadProsumerProUpsellData = useMutation({
    mutationFn: async () => {
      await loadProsumerPublicData();
    },
    onError: () => {
      toast.error('Sorry, something went wrong with your link');
      history.push('/');
    },
  });

  React.useEffect(() => {}, [hasSubscription, history]);

  React.useEffect(() => {
    if (isAuthenticated) {
      toast.warning('You are already logged in');
      history.push('/');
      return;
    }

    if (!needsUpsellData) {
      return;
    }

    if (!emailToken) {
      toast.error('Sorry, something went wrong with your link');
      history.push('/');
      return;
    }

    if (loadProsumerProUpsellData.isIdle) {
      loadProsumerProUpsellData.mutate();
    }

    if (hasSubscription) {
      toast.error(
        'Seems you already have an active subscription, please log in'
      );
      history.push('/');
    }
  }, [
    loadProsumerProUpsellData,
    emailToken,
    history,
    isAuthenticated,
    needsUpsellData,
    hasSubscription,
  ]);

  if (!isInitialised || !didAuthenticate || isLoadingPlan) {
    return <FullScreenLoader />;
  }

  return (
    <Route path={path} {...rest}>
      {children}
    </Route>
  );
};

export default UnauthenthicatedRoute;
