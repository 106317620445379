import styled from 'styled-components';
import { AppliedProjectTag } from '../hydra';

const Tag = styled.p<{ isSelected: boolean }>`
  margin: 0;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : theme.colors.bgActive};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.black : theme.colors.text};
  font-size: 14px;
  cursor: pointer;
  padding: 4px 24px;
  border-radius: 50px;
  text-transform: lowercase;
  &:hover {
    background-color: ${({ isSelected, theme }) =>
      `color-mix(in srgb, ${
        isSelected ? theme.colors.primary : theme.colors.bgActive
      } 90%, black 10%);`};
  }

  user-select: none;
`;

const TagRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 16px;
`;
export default function MultiselectTags({
  selectedTags,
  tags,
  onChange,
  allowMultiple = false,
}: {
  selectedTags: string[];
  tags: AppliedProjectTag[];
  onChange: (selected: string[]) => void;
  allowMultiple?: boolean;
}) {
  const handleTagClick = (tag: string) => {
    if (selectedTags.includes(tag)) {
      onChange(selectedTags.filter((o) => o !== tag));
    } else {
      onChange(allowMultiple ? [...selectedTags, tag] : [tag]);
    }
  };

  return (
    <>
      <TagRow>
        {Array.from(
          new Set(
            tags
              .filter((t) => t.type === 'TOPIC')
              .map((t) => t.value)
              .sort()
          )
        ).map((tag, i) => (
          <Tag
            key={i}
            onClick={() => handleTagClick(tag)}
            isSelected={selectedTags.includes(tag)}
          >
            # {tag}
          </Tag>
        ))}
      </TagRow>
      <TagRow>
        {Array.from(
          new Set(
            tags
              .filter((t) => t.type === 'ROLE')
              .map((t) => t.value)
              .sort()
          )
        ).map((tag, i) => (
          <Tag
            key={i}
            onClick={() => handleTagClick(tag)}
            isSelected={selectedTags.includes(tag)}
          >
            # {tag}
          </Tag>
        ))}
        {Array.from(
          new Set(
            tags
              .filter((t) => t.type === 'APPLICATION')
              .map((t) => t.value)
              .sort()
          )
        ).map((tag, i) => (
          <Tag
            key={i}
            onClick={() => handleTagClick(tag)}
            isSelected={selectedTags.includes(tag)}
          >
            # {tag}
          </Tag>
        ))}
      </TagRow>
    </>
  );
}
