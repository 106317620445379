import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import PageWrapper from '../PageWrapper';
import PageWithAsyncData from '../PageWithAsyncData';
import Navbar from '../Navbar';
import Button from '../elements/Button';
import { useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import DashboardGrid from './DashboardGrid';
import { Helmet } from 'react-helmet-async';
import { getDashboardPageTitle, getHomeUrl } from '../../routing';

export default function DashboardPage({
  statsState,
  getActivityUrl,
  user,
  projectInfo = {
    shouldSubmitProject: false,
    shouldUploadFiles: false,
    shouldAnswerRetroSurvey: false,
  },
  isPreview = false,
  isProsumer = false,
}) {
  return (
    <PageWithAsyncData
      error={statsState.error}
      isLoading={statsState.loading}
      retry={statsState.retry}
    >
      {statsState.value && (
        <>
          <Navbar
            isPreview={isPreview}
            breadCrumbs={[
              {
                label: 'Home',
                url: getHomeUrl(),
              },
              {
                label: statsState.value?.missions?.[0]?.content?.title,
                url: null,
              },
            ]}
          />
          <DashboardLayout
            stats={statsState.value}
            getActivityUrl={getActivityUrl}
            user={user}
            projectInfo={projectInfo}
            isProsumer={isProsumer}
            isPreview={isPreview}
          />
        </>
      )}
    </PageWithAsyncData>
  );
}

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
  align-items: center;
`;

const FloatingButtonWrapper = styled.div`
  position: fixed;
  top: 100px;
  right: 40px;
  z-index: 2;
`;

const FloatingButton = ({ onClick }) => {
  const theme = useTheme();
  return createPortal(
    <FloatingButtonWrapper>
      <Button
        color={theme.colors.secondary}
        type="primary"
        backgroundColor={theme.colors.bgPage}
        onClick={onClick}
        label="Back to all Activities"
      />
    </FloatingButtonWrapper>,
    document.getElementById('root')
  );
};

const Page = styled(PageWrapper)`
  ${({ isHighlightingOutstanding }) =>
    isHighlightingOutstanding &&
    css`
      .dim-content {
        opacity: 0.25 !important;
      }
    `}
`;

function DashboardLayout({
  stats,
  getActivityUrl,
  isProsumer,
  isPreview,
  projectInfo,
}) {
  const { teamSlug } = useParams();

  const hasOutstandingActivities = stats.missions.some((mission) =>
    mission.activities.some(
      (activity) => activity.isSent && !activity.isSubmitted && activity.isCore
    )
  );

  React.useEffect(() => {
    if (isHighlightingOutstanding) {
      const highlightedActivities = document.getElementsByClassName(
        'highlighted-content'
      );
      // the navbar breaks .scrollIntoView so adding some offset to hack around it
      const y =
        highlightedActivities[0].getBoundingClientRect().top +
        window.scrollY -
        150;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  });

  const onShowOutstandingActivities = () => {
    if (hasOutstandingActivities) {
      setIsHighlightingOutstanding(true);
    }
  };

  const onShowAllActivities = () => {
    setIsHighlightingOutstanding(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isTeams = !isPreview && !isProsumer;
  const [isHighlightingOutstanding, setIsHighlightingOutstanding] =
    React.useState(false);

  const contentModuleTitle =
    stats?.missions?.[0]?.content?.title ?? 'Your Dashboard';
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{getDashboardPageTitle(contentModuleTitle)}</title>
      </Helmet>
      <Page isHighlightingOutstanding={isHighlightingOutstanding}>
        {isHighlightingOutstanding && (
          <FloatingButton onClick={onShowAllActivities} />
        )}
        <div className="dim-content" style={{ marginBottom: '52px' }}>
          <h1>{contentModuleTitle}</h1>
          <>
            {isTeams && projectInfo.shouldSubmitProject && (
              <p>
                Submit your project before the end of week 4.{' '}
                <a href="/project-examples" target="_blank">
                  See project examples.
                </a>
              </p>
            )}
            <ButtonsRow>
              <Button
                type="secondary"
                isDisabled={!hasOutstandingActivities}
                label="Show Outstanding Activities ↓"
                color={theme.colors.buttonSecondary}
                onClick={onShowOutstandingActivities}
              />
              {isTeams && projectInfo.shouldSubmitProject && (
                <Button
                  label="Submit Project"
                  type="secondary"
                  href={`/${teamSlug}/submit-project`}
                />
              )}
            </ButtonsRow>
          </>
        </div>

        <DashboardGrid
          missions={stats.missions}
          getActivityUrl={getActivityUrl}
          isHighlightingOutstanding={isHighlightingOutstanding}
        />
      </Page>
    </>
  );
}
