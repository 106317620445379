import styled, { css } from 'styled-components';
import { P } from '../elements/Text';
import { CardLabelUppercase, CardPSmall } from '../ContentCard';
import { SmallTooltip } from '../Tooltip';

const Card = styled.div<{
  status: 'completed' | 'in-progress' | 'not-started';
  isFirst: boolean;
  isLast: boolean;
  preview: boolean;
  isLocked: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'completed': {
        return theme.colors.primaryBg;
      }
      case 'in-progress': {
        return theme.colors.secondaryBg;
      }
      case 'not-started': {
        return theme.colors.bgDefaultSecondary;
      }
    }
  }};

  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.bgActive};

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: 0px;
    `}

  ${({ isLast, isFirst }) =>
    !isLast &&
    !isFirst &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
  ${({ preview, isLocked }) => {
    if (preview) {
      return css`
        cursor: default;
      `;
    }
    if (isLocked) {
      return css`
        opacity: 0.6;
        cursor: default;
      `;
    }
    return css`
      cursor: pointer;
      &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
      }
    `;
  }}
`;

export const CardIcon = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url});
  height: 32px;
  width: 32px;
  background-size: cover;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const NonmobileRow = styled(Row)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const CardTitle = styled(P)`
  margin-top: 0;
  flex-grow: 0;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  padding-bottom: 0;
`;

const CardDescription = styled(P)`
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0;
  margin-top: 0;
`;

const TopicLabel = styled(CardLabelUppercase)`
  margin-top: 0px;
  padding-bottom: 0px;
`;

const HorizontalDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgActive};
`;

const LearnMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  &:hover {
    & > i,
    & > p {
      transition: all 0.3s ease-in-out;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ButtonIcon = styled.i`
  color: ${({ theme }) => theme.colors.textRegular};
  font-size: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 16px;
  text-align: left;
`;
const TooltipContent = styled(CardDescription)`
  font-style: italic;
  padding: 5;
  max-width: 300px;
`;

export enum LEARNING_PLAN_UNIT_TYPE {
  CONTENT_MODULE = 'module',
  PROJECT = 'project',
}

export type LearningPlanUnit = {
  type: LEARNING_PLAN_UNIT_TYPE;
  slug: string;
  progressDescription: string;
  moduleTitle: string;
  unitTitle: string;
  nextItemTitle: string;
  reasoning: string;
  description: string;
  subtitle: string;
  imageUrl: string;
  onClick: () => void;
  isCompleted: boolean;
  isInProgress: boolean;
  dashboardUrl: string | null;
  isLocked: boolean;
};

export default function LearningPlanUnitCard({
  learningUnit: {
    unitTitle,
    subtitle,
    imageUrl,
    progressDescription,
    reasoning,
    onClick,
    isCompleted,
    isInProgress,
    isLocked,
  },
  index,
  numItems,
  isPreviewingPlan = false,
}: {
  learningUnit: LearningPlanUnit;
  index: number;
  numItems: number;
  isPreviewingPlan?: boolean;
}) {
  const handleClick = (e: React.MouseEvent) => {
    if (isLocked || isPreviewingPlan) {
      e.preventDefault();
      return;
    }
    onClick();
  };

  return (
    <>
      <Card
        status={
          isCompleted
            ? 'completed'
            : isInProgress
            ? 'in-progress'
            : 'not-started'
        }
        isFirst={index === 1}
        isLast={index === numItems}
        onClick={handleClick}
        preview={isPreviewingPlan}
        isLocked={isLocked}
        className={isLocked ? `locked-card-${index}` : ''}
      >
        <Row>
          <Section>
            <CardIcon url={imageUrl} title={unitTitle} />
          </Section>
          <HorizontalDivider />

          <Section>
            <TopicLabel>{subtitle}</TopicLabel>
            <CardTitle>{unitTitle}</CardTitle>
          </Section>
        </Row>

        <NonmobileRow style={{ height: '100%' }}>
          <Section>
            <CardPSmall style={{ whiteSpace: 'nowrap' }}>
              {progressDescription}
            </CardPSmall>
          </Section>
          <HorizontalDivider />
          <Section>
            <Row>
              <LearnMoreWrapper>
                <ButtonIcon
                  className={`fa-regular fa-circle-info info-button-${index} `}
                />
              </LearnMoreWrapper>
              {!isPreviewingPlan && (
                <LearnMoreWrapper>
                  <ButtonIcon
                    className={`fa-solid fa-forward open-button-${index}`}
                  />
                </LearnMoreWrapper>
              )}
            </Row>
          </Section>
        </NonmobileRow>
      </Card>

      {!isPreviewingPlan && (
        <SmallTooltip anchorSelect={`.open-button-${index}`}>
          {isLocked
            ? 'Make progress to unlock this item'
            : isCompleted
            ? 'Open Dashboard'
            : 'Continue to the next item'}
        </SmallTooltip>
      )}
      <SmallTooltip anchorSelect={`.info-button-${index}`}>
        <TooltipContent>💡 {reasoning}</TooltipContent>
      </SmallTooltip>
    </>
  );
}
