import { Auth0DecodedHash } from 'auth0-js';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Analytics from '../analytics';
import * as CrashReporter from '../crash-reporter';
import { UserPayload, verifyAuthUserCareerist } from '../hydra';
import { AuthContextType, initialState } from './auth';

export const AuthContext = React.createContext<AuthContextType>(initialState);

const ENKI_AUTH_CALLBACK_URL = `${window.location.origin}/auth/`;
export const CAREERIST_AUTH_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://app.careerist.com/auth/login'
    : 'https://app.dev.careerist.cloud/auth/login';

const goToCareeristLogin = () => {
  window.location.href = `${CAREERIST_AUTH_URL}?r=${encodeURIComponent(
    ENKI_AUTH_CALLBACK_URL
  )}`;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<null | UserPayload>(null);

  const [didAuthenticate, setDidAuthenticate] = React.useState(false);
  const [isInitialised] = React.useState(true);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const history = useHistory();

  const logout = React.useCallback((): Promise<void> => {
    CrashReporter.addBreadcrumb({
      message: 'Careerist Auth - logout',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    Analytics.removeUser();
    return new Promise((resolve) => {
      deleteTokenFromLocalStorage();
      goToCareeristLogin();
      resolve();
    });
  }, []);

  const queryUser = React.useCallback(async (accessToken: string) => {
    CrashReporter.addBreadcrumb({
      message: 'Careerist Auth - query user',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    const user = await verifyAuthUserCareerist(accessToken);

    CrashReporter.setUser(user);
    Analytics.setUser({
      id: user.id,
      email: user?.email ?? '',
      name: user?.name ?? '',
      isSignUp: user.isSignUp,
    });
    Analytics.trackEvent({
      event: Analytics.EVENTS.COMMON.AUTHENTHICATE,
      properties: {
        isSignUp: user.isSignUp,
      },
    });
    setUser(user);
  }, []);

  const getTokenFromQueryParam = React.useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
  }, []);

  const clearTokenFromQueryParam = React.useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.delete('token');
    history.replace(`${window.location.pathname}?${params.toString()}`);
  }, [history]);

  const handleAuthCallback = React.useCallback(async () => {
    setIsAuthenticating(false);
    setDidAuthenticate(false);
    CrashReporter.addBreadcrumb({
      message: 'Careerist Auth - handle auth callback',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    try {
      const token = getTokenFromQueryParam();
      if (!token) {
        throw new Error('No token found');
      }

      await queryUser(token);
      setTokenInLocalStorage(token);
      clearTokenFromQueryParam();
      setDidAuthenticate(true);
      setIsAuthenticating(false);
      return null;
    } catch (error) {
      deleteTokenFromLocalStorage();
      clearTokenFromQueryParam();
      setDidAuthenticate(true);
      setIsAuthenticating(false);
      throw error;
    }
  }, [clearTokenFromQueryParam, getTokenFromQueryParam, queryUser]);

  const getReturnToFromResult = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_result: Auth0DecodedHash | null) => {
      throw new Error('Not implemented');
    },
    []
  );

  const reAuthSilently = React.useCallback(async () => {
    setDidAuthenticate(false);
    setIsAuthenticating(true);
    CrashReporter.addBreadcrumb({
      message: 'Careerist Auth - re-auth silently',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    const accessToken: string | null = getTokenFromLocalStorage();
    if (!accessToken) {
      goToCareeristLogin();
      return;
    }

    try {
      await queryUser(accessToken);
      setDidAuthenticate(true);
      setIsAuthenticating(false);
    } catch (error) {
      deleteTokenFromLocalStorage();
      setIsAuthenticating(false);
      setDidAuthenticate(true);
      throw error;
    }
  }, [queryUser]);

  const getTokenSilently = React.useCallback(async (): Promise<string> => {
    CrashReporter.addBreadcrumb({
      message: 'Careerist Auth - get token silently',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });

    const cachedToken = getTokenFromLocalStorage();
    if (cachedToken) {
      return cachedToken;
    }

    throw new Error('No token found');
  }, []);

  const authWithGoogle = React.useCallback(() => {
    throw new Error('Not implemented');
  }, []);

  const passwordlessLogin = React.useCallback(() => {
    throw new Error('Not implemented');
  }, []);

  const passwordlessStart = React.useCallback(() => {
    throw new Error('Not implemented');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: didAuthenticate && !!user,
        isInitialised,
        didAuthenticate,
        isAuthenticating,
        user,
        logout,
        getTokenSilently,
        handleAuthCallback,
        getReturnToFromResult,
        reAuthSilently,
        authWithGoogle,
        passwordlessLogin,
        passwordlessStart,
      }}
    >
      {isInitialised && children}
    </AuthContext.Provider>
  );
};

const TOKEN_KEY = 'careerist_auth_access_token';

const setTokenInLocalStorage = (token: string) => {
  CrashReporter.addBreadcrumb({
    message: 'Auth - set token in local storage',
    metadata: {
      token,
    },
    type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
  });

  localStorage.setItem(TOKEN_KEY, token);
};

const deleteTokenFromLocalStorage = () => {
  CrashReporter.addBreadcrumb({
    message: 'Auth - delete token from local storage',
    metadata: {},
    type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
  });
  localStorage.removeItem(TOKEN_KEY);
};

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  CrashReporter.addBreadcrumb({
    message: 'Auth - get token from local storage',
    metadata: {
      token,
    },
    type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
  });

  if (!token) {
    return null;
  }

  return token;
};
