import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import * as Analytics from '../../../analytics';
import { TOPICS, TOPICS_DATA } from '../config';
import BenefitsContent from '../../Benefits';

export default function Benefits() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_UPSELL_BENEFITS,
    state.context
  );

  return (
    <BenefitsContent
      onNext={() => send(EVENTS.CONTINUE_BENEFITS)}
      quote={getPersonalizedQuote({ topic: state.context.topic })}
    />
  );
}

function getPersonalizedQuote({ topic }: { topic: string | null }) {
  if (topic === TOPICS.PYTHON) {
    return 'Enki’s program is by far the most effective way to learn how to code.';
  }

  if (topic) {
    return `Enki’s program is by far the most effective way to learn ${TOPICS_DATA.options[topic].label}.`;
  }

  return 'Enki’s program is by far the most effective way to learn.';
}
