import { H1, H4, PSmall } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextFreeTextQuestionAnswer,
} from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  getQuestionText,
} from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';

import Button from '../../elements/Button';
import GoogleSheetsEmbed, {
  getGoogleCopyUrl,
  getGoogleDocPreviewUrl,
} from '../../Embeds/GoogleSheetsEmbed';
import styled, { useTheme } from 'styled-components';
import { Input } from '../../elements/Input';
import MentorReview from '../MentorComment';
import Modal from '../../Modal/Modal';
import { CloseIcon } from '../../Icons';
import Markdown from '../../Markdown';

const GOOGLE_DOC_EMBED_REGEX = /https:\/\/docs.google.com\/d.*/;

export default function ExternalDocSelfCheck({
  question,
  answer,
}: QuestionProps & {
  answer: ActivityContextFreeTextQuestionAnswer;
}) {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onFreeTextSelfCheckQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SELF_CHECK_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const [isPreviewModalOpen, setIsPreviewModalOpen] = React.useState(false);

  const googleDocLink = question?.google_sheets_link ?? '';
  const tableauLink = question?.tableau_link ?? '';
  const modelAnswer = question?.model_answer ?? '';

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];
  return (
    <FullScreenQuestion>
      {isPreviewModalOpen && (
        <AnswerPreviewModal
          answer={modelAnswer}
          onCloseModal={() => setIsPreviewModalOpen(false)}
        />
      )}
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <ButtonsRow>
          {googleDocLink && (
            <Button
              type="secondary"
              label="Open Document"
              faIcon="fas fa-chart-pie"
              onClick={() =>
                window.open(getGoogleCopyUrl(googleDocLink), '_blank')
              }
            />
          )}
          {tableauLink && (
            <Button
              type="secondary"
              label="Open Tableau"
              faIcon="fas fa-chart-pie"
              onClick={() => window.open(tableauLink, '_blank')}
            />
          )}
          <Button
            type="secondary"
            label="View Answer"
            isDisabled={!answer?.value}
            faIcon="fas fa-search"
            onClick={() => setIsPreviewModalOpen(true)}
          />
        </ButtonsRow>
        <Input
          placeholder={'Enter your answer here'}
          value={answer.value}
          onChange={(e) =>
            onFreeTextSelfCheckQuestionTextChange(e.target.value)
          }
        />
        <BottomSpacer />
      </ReadableContainer>
    </FullScreenQuestion>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;

  > * {
    margin: 0;
  }
`;

function AnswerPreviewModal({
  answer,
  onCloseModal,
}: {
  answer: string;
  onCloseModal: () => void;
}) {
  const theme = useTheme();
  const isGoogleDoc = answer.match(GOOGLE_DOC_EMBED_REGEX);

  return (
    <Modal
      style={{ width: '100%', maxWidth: theme.dimensions.readableColumnWidth }}
    >
      <Row>
        <H4>Answer Preview</H4>
        <CloseIcon
          style={{ padding: 0, alignSelf: 'flex-start' }}
          onClick={onCloseModal}
        />
      </Row>
      <PSmall>Make sure your answer reflects the correct answer.</PSmall>
      {isGoogleDoc && (
        <GoogleSheetsEmbed
          isClickable={true}
          isCopyable={false}
          url={getGoogleDocPreviewUrl(answer)}
          id="google-doc-embed-answer-preview"
        />
      )}
      {!isGoogleDoc && <Markdown md={answer} />}
      <div style={{ height: '32px' }} />
    </Modal>
  );
}
