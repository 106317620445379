import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type VerifyAnswerStrictTextQuestionActionType = {
  type: typeof ACTIVITY_ACTION.VERIFY_ANSWER_STRICT_TEXT_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    answer: string;
  };
};

export type ChangeAnswerStrictTextQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_STRICT_TEXT_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    answer: string;
  };
};

export function strictQuestionChangeReducer(
  state: ActivityContextType,
  action: ChangeAnswerStrictTextQuestionActionType
) {
  const { question, answer } = action.payload;
  const answerState = {
    ...state.submission.questionSlugToAnswer[question.slug],
    value: answer,
  };

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      ...answerState,
      isAnswered: false,
      isCorrect: null,
    },
  });
}

export function strictQuestionVerifyReducer(
  state: ActivityContextType,
  action: VerifyAnswerStrictTextQuestionActionType
) {
  const { question, answer } = action.payload;
  const isCorrect = (question?.strict_text_answers ?? []).includes(answer);

  const answerState = {
    ...state.submission.questionSlugToAnswer[question.slug],
    value: answer,
  };

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      ...answerState,
      isAnswered: true,
      isCorrect,
    },
  });
}
