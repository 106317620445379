import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import * as Analytics from '../../../analytics';
import { StaticLearningPlan } from '../../LearningPlan/StaticLearningPlan';

export default function PersonalizedPlan() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const { learningPlan, planExplanation } = state.context;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_PERSONALIZED_PLAN,
    state.context
  );

  if (!learningPlan) {
    throw new Error('Learning plan is not defined');
  }

  return (
    <StaticLearningPlan
      learningPlan={learningPlan}
      planExplanation={planExplanation}
      onNext={() => send(EVENTS.CONTINUE_PERSONALIZED_PLAN)}
    />
  );
}
