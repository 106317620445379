import React from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { PSmall } from './Text';

const InputBox = styled.input`
  height: auto;
  width: 100%;
  background: transparent !important;

  line-height: 28px;
  outline: 0;
  border: 0 !important;
  padding: 0 !important;
  margin: 0;

  caret-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.inputTextActive};

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputTextPlaceholder} !important;
  }
`;

const InputWithButton = styled.div`
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  transition: all 0.3s ease-in-out;

  border-radius: 8px;
  width: 100%;
  padding: 12px 20px;
  border-width: 1px;
  border-style: solid;

  background-color: ${({ theme }) => theme.colors.inputBackground};
  border-color: ${({ theme }) => theme.colors.inputBackground};

  &:hover {
    border-color: ${({ theme }) => theme.colors.inputBorderActive};
  }
  ${({ isFocused }) =>
    isFocused &&
    `border-color: ${({ theme }) => theme.colors.inputBorderActive};`}
`;

const ActionIcon = styled.i`
  opacity: 0.6;
  transition: all 0.3s ease-in-out;

  ${({ isMultiLine }) =>
    isMultiLine && `align-self: flex-end;   padding-bottom: 4px;`}

  ${({ isValid, theme }) =>
    isValid &&
    `
    opacity: 1;
    cursor: pointer;
    color: ${theme.colors.primary};
  `}
`;

const LabelIcon = styled.i`
  opacity: 0.6;
`;

export const Input = ({
  placeholder,
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_) => {},
  isDisabled = false,
  errorMessage = '',
  onSubmit = null,
  canSubmit = true,
  type = 'text',
  className = '',
  ...rest
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const isValid = canSubmit && value.length;

  return (
    <Container className={className} isDisabled={isDisabled}>
      <InputWithButton isFocused={isFocused}>
        {type === 'link' && <LabelIcon className="fas fa-link" />}
        <InputBox
          {...(type === 'password' && { type: 'password' })}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isDisabled={isDisabled}
          {...rest}
        />
        {onSubmit && (
          <ActionIcon
            className="fas fa-paper-plane"
            onClick={isValid ? onSubmit : () => {}}
            isValid={isValid}
          />
        )}
      </InputWithButton>
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Container>
  );
};

const MultiLineInputBox = styled(TextareaAutosize)`
  resize: none;
  height: auto;
  background: transparent;
  line-height: 28px;
  margin: 0;
  outline: 0;
  border: 0;
  padding: 0;
  box-shadow: none;
  width: 100%;

  caret-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.inputTextActive};

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputTextPlaceholder};
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding-bottom: 12px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
`;

const ErrorLabel = styled(PSmall)`
  color: ${({ theme }) => theme.colors.textDanger};
  margin: 12px 20px;
  font-weight: 700;
`;

export const MultiLineInput = ({
  placeholder = '',
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_) => {},
  onSubmit = null,
  isDisabled = false,
  canSubmit = true,
  errorMessage = '',
  autoFocus = false,
  readOnly = false,
  className = '',
  shouldSubmitOnEnter = false,
  shouldFocusAfterDisable = false,
  maxRows = 20,
  ...rest
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const isValid = canSubmit && value.length;
  const inputRef = React.useRef(null);

  const onKeyDown = (e) => {
    if (
      shouldSubmitOnEnter &&
      e.code === 'Enter' &&
      e?.nativeEvent?.shiftKey === false
    ) {
      e.preventDefault();

      if (isValid) {
        onSubmit();
      }
    }
  };

  const moveCursor = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(value.length, value.length);
    }
  }, [value]);

  React.useEffect(() => {
    if (shouldFocusAfterDisable && !isDisabled) {
      focusInput();
      moveCursor();
    }
  }, [isDisabled, shouldFocusAfterDisable, value, moveCursor]);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Container
      className={className}
      isDisabled={isDisabled}
      onClick={focusInput}
    >
      <InputWithButton
        isFocused={isFocused}
        isDisabled={isDisabled}
        style={{
          height: 'auto',
        }}
      >
        <MultiLineInputBox
          ref={inputRef}
          readOnly={isDisabled || readOnly}
          autoFocus={autoFocus}
          maxRows={maxRows}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={onKeyDown}
          {...rest}
        />
        {onSubmit && (
          <ActionIcon
            isMultiLine
            className="fas fa-paper-plane"
            onClick={isValid ? onSubmit : () => {}}
            isValid={isValid}
          />
        )}
      </InputWithButton>
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Container>
  );
};
