import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import styled from 'styled-components';
import {
  CenteredContainer,
  Header,
  ReadableContainer,
  ButtonsWrapper,
  CTAButton,
  ProgressBar,
  PillButton,
  HighlightWord,
  Label,
} from './common';
import { TOPICS_DATA } from '../config';
import { LabelUppercase } from '../../elements/Text';

import * as Analytics from '../../../analytics';

const ItalicLabel = styled(Label)`
  font-style: italic;
  font-size: 18px;
`;

const constructSecondaryTopicsArray = (
  secondaryTopics: string[],
  newTopic: string
) => {
  if (secondaryTopics.includes(newTopic)) {
    return secondaryTopics.filter((t) => t !== newTopic);
  } else {
    if (secondaryTopics.length >= 2) {
      return [...secondaryTopics.slice(1), newTopic];
    } else {
      return [...secondaryTopics, newTopic];
    }
  }
};

export default function AskSecondaryTopics() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);
  const primaryTopic = state.context.topic;
  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_SECONDARY_TOPICS,
    state.context
  );

  if (!primaryTopic) {
    return null;
  }

  const topicData = TOPICS_DATA.options[primaryTopic];

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={4} />
        <LabelUppercase>Step 4</LabelUppercase>
        <Header>
          <HighlightWord text={topicData.secondaryTopicQuestion} word={topicData.label} />
        </Header>
        <ItalicLabel>If so, pick 1-2 skills</ItalicLabel>

        <ButtonsWrapper>
          {Object.entries(TOPICS_DATA.options)
            .filter((t) => t[1].topic !== primaryTopic)
            .map(([key, value]) => (
              <PillButton
                key={key}
                label={value.label}
                iconUrl={value.iconUrl}
                isSelected={state.context.secondaryTopics.includes(value.topic)}
                onClick={() => {
                  send({
                    type: EVENTS.SELECT_SECONDARY_TOPICS,
                    secondaryTopics: constructSecondaryTopicsArray(
                      state.context.secondaryTopics,
                      value.topic
                    ),
                  });
                }}
              ></PillButton>
            ))}
        </ButtonsWrapper>
        <CTAButton
          label={
            state.context.secondaryTopics.length ? 'Continue' : 'No, Continue'
          }
          type="primary"
          onClick={() => {
            send({
              type: EVENTS.CONTINUE_ASK_SECONDARY_TOPICS,
            });
          }}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
