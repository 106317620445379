import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './contexts/auth';
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute';
import LearnModeOnlyRoute from './components/Routes/LearnModeOnlyRoute';
import AuthLoadingRoute from './components/Routes/AuthLoadingRoute';
import ProsumerPublicUpsellRoute from './components/Routes/ProsumerPublicUpsellRoute';

import NotFound from './pages/NotFound';
import {
  appUserUpsellPath,
  authCallbackPath,
  authConfirmEmailPath,
  authPath,
  benefitsPath,
  embeddablePythonPlaygroundPath,
  embeddableSQLPlaygroundPath,
  paymentCallbackPath,
  paymentPath,
  planUpsellPath,
  previewActivityPath,
  previewDashboardPath,
  previewProjectPath,
  projectExamplesPath,
  projectsDashboardPath,
  retroSurveyPath,
  soloActivityPath,
  soloDashboardPath,
  soloOnboardingPath,
  appliedProjectPath,
  teamActivityPath,
  teamDashboardPath,
  teamsAuthPath,
  teamSubmitProjectPath,
  unsubscribePath,
  publicUpsellRoute,
  publicUpsellPlanPath,
  publicUpsellBenefitsPath,
  publicUpsellPaymentPath,
  publicUpsellPaymentCallbackPath,
} from './routing';
import theme from './theme';
import PreviewActivity from './pages/Activity/PreviewActivity';
import ProsumerActivity from './pages/Activity/ProsumerActivity';
import TeamActivity from './pages/Activity/TeamsActivity';
import PreviewDashboard from './pages/Dashboard/PreviewDashboard';
import ProsumerDashboard from './pages/Dashboard/ProsumerDashboard';
import TeamDashboard from './pages/Dashboard/TeamsDashboard';
import Home from './pages/Home';
import Playground from './pages/Playground';
import ProjectExamples from './pages/ProjectExamples';
import RetroSurvey from './pages/RetroSurvey';
import SubmitProject from './pages/SubmitProject';
import ProsumerQueryParamsHandlers from './components/ProsumerQueryParamsHandlers';
import { AIWorkflowProvider } from './contexts/ai-workflow';
import * as CrashReporter from './crash-reporter';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import FullScreenAlert from './components/Alert/FullScreenAlert';
import { NAVBAR_HEIGHT } from './components/Navbar';
import { SoloOnboardingProvider } from './components/SoloOnboarding/context';
import AuthCallback from './pages/AuthCallback/AuthCallback';
import ConfirmEmailPage from './pages/ConfirmEmail';
import LoginPage from './pages/Login';
import Payment from './pages/ProsumerUpsell/Payment';
import PaymentCallback from './pages/ProsumerUpsell/PaymentCallback';
import SoloOnboarding from './pages/SoloOnboarding';
import Benefits from './pages/ProsumerUpsell/Benefits';
import Unsubscribe from './pages/Unsubscribe';
import { useEffect } from 'react';
import { trackPageView } from './analytics';
import AppUserUpsell from './pages/AppUserUpsell';
import EmbeddablePythonPlayground from './pages/PythonPlayground';
import EmbeddableSQLPlayground from './pages/SQLPlayground';
import AppliedProjectActivity from './pages/Project/AppliedProject';
import AppliedProjectPreviewActivity from './pages/Project/PreviewAppliedProject';
import ProjectsDashboard from './pages/ProjectsDashboard';
import LearningPlanUpsell from './pages/ProsumerUpsell/LearningPlan';
import { ProsumerPublicUpsellProvider } from './contexts/prosumer-public-upsell';
import ProsumerPublicUpsellLearningPlan from './pages/ProsumerPublicUpsell/LearningPlan';
import ProsumerPublicUpsellBenefits from './pages/ProsumerPublicUpsell/Benefits';
import ProsumerPublicUpsellPayment from './pages/ProsumerPublicUpsell/Payment';
import ProsumerPublicUpsellPaymentCallback from './pages/ProsumerPublicUpsell/PaymentCallback';

const queryClient = new QueryClient();

export default function Application() {
  return (
    <CrashReporter.ErrorBoundary
      FallbackComponent={() => (
        <FullScreenAlert text="Unfortunately, something went wrong" />
      )}
    >
      <GlobalProviders>
        <Router>
          <ApplicationSwitch />
        </Router>
      </GlobalProviders>
    </CrashReporter.ErrorBoundary>
  );
}

const ApplicationSwitch = () => {
  const history = useHistory();

  useEffect(() => {
    if (
      !window.location.pathname.includes('/public/sql-playground') &&
      !window.location.pathname.includes('/public/python-playground')
    ) {
      trackPageView();
    }
    history.listen(() => trackPageView());
  }, [history]);
  return (
    <Switch>
      <Route path="/public">
        <PublicRoutes />
      </Route>
      <Route path="/">
        <AuthProvider>
          <AuthenticatedApp />
        </AuthProvider>
      </Route>
    </Switch>
  );
};

function GlobalProviders({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ToastContainer
        theme="dark"
        transition={Zoom}
        position="bottom-right"
        className="enki-toast-container"
        toastClassName="enki-toast"
        progressClassName="enki-toast-progress"
        style={{
          bottom: NAVBAR_HEIGHT,
        }}
      />
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </>
  );
}

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/public/playground">
        <Playground />
      </Route>
      <Route path={embeddablePythonPlaygroundPath}>
        <EmbeddablePythonPlayground />
      </Route>
      <Route path={embeddableSQLPlaygroundPath}>
        <EmbeddableSQLPlayground />
      </Route>
      {/* not found fallback */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

function AuthenticatedApp() {
  return (
    <Switch>
      <AuthLoadingRoute exact path={authPath}>
        <LoginPage />
      </AuthLoadingRoute>
      <AuthLoadingRoute exact path={teamsAuthPath}>
        <LoginPage isTeamsOnboard />
      </AuthLoadingRoute>
      <AuthLoadingRoute exact path={appUserUpsellPath}>
        <AppUserUpsell />
      </AuthLoadingRoute>
      <AuthLoadingRoute exact path={authConfirmEmailPath}>
        <ConfirmEmailPage />
      </AuthLoadingRoute>
      <Route exact path={authCallbackPath}>
        <AuthCallback />
      </Route>
      <Route exact path="/prosumer/onboarding">
        <Redirect to={{ pathname: '/join', search: window.location.search }} />
      </Route>
      <Route exact path={soloOnboardingPath}>
        <SoloOnboardingProvider>
          <SoloOnboarding />
        </SoloOnboardingProvider>
      </Route>
      <Route exact path={unsubscribePath}>
        <Unsubscribe />
      </Route>
      <Route path={publicUpsellRoute}>
        <ProsumerPublicUpsellProvider>
          <Switch>
            <Route exact path={publicUpsellRoute}>
              <Redirect to={publicUpsellPlanPath} />
            </Route>
            <ProsumerPublicUpsellRoute exact path={publicUpsellPlanPath}>
              <ProsumerPublicUpsellLearningPlan />
            </ProsumerPublicUpsellRoute>
            <ProsumerPublicUpsellRoute exact path={publicUpsellBenefitsPath}>
              <ProsumerPublicUpsellBenefits />
            </ProsumerPublicUpsellRoute>
            <ProsumerPublicUpsellRoute exact path={publicUpsellPaymentPath}>
              <ProsumerPublicUpsellPayment />
            </ProsumerPublicUpsellRoute>
            <ProsumerPublicUpsellRoute
              exact
              path={publicUpsellPaymentCallbackPath}
              needsUpsellData={false}
            >
              <ProsumerPublicUpsellPaymentCallback />
            </ProsumerPublicUpsellRoute>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </ProsumerPublicUpsellProvider>
      </Route>
      <Route path="/">
        <AuthenticatedRoutesWithAI />
      </Route>
    </Switch>
  );
}

function AuthenticatedRoutesWithAI() {
  return (
    <AIWorkflowProvider>
      <ProsumerQueryParamsHandlers />
      <Switch>
        <LearnModeOnlyRoute exact path={soloDashboardPath}>
          <ProsumerDashboard />
        </LearnModeOnlyRoute>
        <LearnModeOnlyRoute exact path={soloActivityPath}>
          <ProsumerActivity />
        </LearnModeOnlyRoute>
        <LearnModeOnlyRoute exact path={appliedProjectPath}>
          <AppliedProjectActivity />
        </LearnModeOnlyRoute>
        <AuthenticatedRoute exact path={paymentPath}>
          <Payment />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={benefitsPath}>
          <Benefits />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={planUpsellPath}>
          <LearningPlanUpsell />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={paymentCallbackPath}>
          <PaymentCallback />
        </AuthenticatedRoute>
        {/* preview content routes */}
        <AuthenticatedRoute exact path={previewActivityPath}>
          <PreviewActivity />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={previewDashboardPath}>
          <PreviewDashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={previewProjectPath}>
          <AppliedProjectPreviewActivity />
        </AuthenticatedRoute>
        {/* teams routes */}
        <AuthenticatedRoute exact path={teamDashboardPath}>
          <TeamDashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={teamActivityPath}>
          <TeamActivity />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={projectExamplesPath}>
          <ProjectExamples />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={teamSubmitProjectPath}>
          <SubmitProject />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={retroSurveyPath}>
          <RetroSurvey />
        </AuthenticatedRoute>
        {/* <AuthenticatedRoute exact path="/company-planner">
          <CompanyPlanner />
        </AuthenticatedRoute> */}
        <LearnModeOnlyRoute exact path="/">
          <Home />
        </LearnModeOnlyRoute>
        <LearnModeOnlyRoute path={projectsDashboardPath}>
          <ProjectsDashboard />
        </LearnModeOnlyRoute>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </AIWorkflowProvider>
  );
}
