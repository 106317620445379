import { useHistory, useParams } from 'react-router-dom';
import useAuth from '../../hooks/use-auth';
import { useAppliedProjectPreview } from '../../hooks/use-hydra';
import { getAppliedProjectPreview } from '../../hydra';
import { ACTIVITY_PAGE_TYPE, ActivityLayout } from '../../components/Activity';
import { projectsDashboardPath } from '../../routing';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageWithAsyncData from '../../components/PageWithAsyncData';
import { Helmet } from 'react-helmet-async';
import { ACTIVITY_TYPE, ActivityProvider } from '../../contexts/activity';
import Navbar, { NAVBAR_HEIGHT } from '../../components/Navbar';
import React from 'react';
import { AIWorkflow } from '../../contexts/ai-workflow';
import AIChat from '../../components/AIChat';
import { getProjectPageTitle } from '../../routing';

const StyledNavbar = styled(Navbar)`
  position: static;
`;

const StyledAIChat = styled(AIChat)`
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px - 32px);
  bottom: calc(${NAVBAR_HEIGHT}px + 16px);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
`;

export default function PreviewProject() {
  const { getTokenSilently } = useAuth();
  const { projectSlug } =
    useParams<{
      projectSlug: string;
    }>();

  const projectState = useAppliedProjectPreview(projectSlug);

  const getActivity = async () => {
    const token = await getTokenSilently();
    return getAppliedProjectPreview(token, {
      projectSlug,
    });
  };
  const history = useHistory();

  const goBackToDashboard = async (): Promise<void> => {
    toast.info('Returning to dashboard...', {
      autoClose: 2000,
    });
    setTimeout(() => {
      history.push(projectsDashboardPath);
    }, 2000);
  };

  const breadCrumbs = [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Projects',
      url: projectsDashboardPath,
    },
    {
      label: projectState.value?.title || 'Project',
      url: null,
    },
  ];

  const { hasAIAccess } = React.useContext(AIWorkflow);

  const project = projectState.value;

  const createAIReview = async () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  };
  const noopSubmit = async () => {
    toast.warn('PREVIEW MODE - skipped saving the submission');
  };

  const submitAnswer = async () => {
    return new Promise<void>((resolve) => {
      toast.warn('PREVIEW MODE - skipped submitting the answer');
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  };

  return (
    <PageWithAsyncData
      isLoading={projectState.loading}
      error={projectState.error}
      retry={projectState.retry}
      className={''}
    >
      {projectState.value && (
        <>
          <Helmet>
            <title>{getProjectPageTitle(projectState.value.title)}</title>
          </Helmet>
          <ActivityProvider
            activity={projectState?.value}
            type={ACTIVITY_TYPE.PROJECT}
          >
            <StyledNavbar breadCrumbs={breadCrumbs} />
            {hasAIAccess && project && (
              <div>
                <StyledAIChat
                  contentId={null}
                  missionId={null}
                  activityId={null}
                  projectId={project.exercise.id}
                  discussionContext={'project'}
                  shouldShowChatIcon={false}
                />
              </div>
            )}
            <ActivityLayout
              type={ACTIVITY_PAGE_TYPE.PREVIEW}
              goToNextActivity={goBackToDashboard}
              saveSubmission={noopSubmit}
              getActivity={getActivity}
              startDiscussion={async () => {}}
              createAIReview={createAIReview}
              dashboardUrl={projectsDashboardPath}
              activityState={projectState}
              submitAnswer={submitAnswer}
            />
          </ActivityProvider>
        </>
      )}
    </PageWithAsyncData>
  );
}
