import styled from 'styled-components';
import Badge from '../elements/Badge';
import { prop } from 'styled-tools';
import { ChevronDownIcon } from '../Icons';
import { AI_TITLE } from './FloatingChat';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  border-bottom: 1px solid ${prop('theme.colors.divider')};
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Icon = styled(ChevronDownIcon)`
  padding: 4px;
`;

const Header = ({ onClose }) => {
  return (
    <HeaderWrapper>
      <Badge label={AI_TITLE} style={{ margin: 0 }} />
      <ControlsWrapper>
        <Icon onClick={onClose} />
      </ControlsWrapper>
    </HeaderWrapper>
  );
};

export default Header;
