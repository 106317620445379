import { toast } from 'react-toastify';
import { assign } from 'xstate';
import { TOPICS } from './config';
import { ContextType, EventType } from './state-machine';
import * as Analytics from '../../analytics';
import EmailInvalidToast from '../Toasts/EmailInvalid';
import { SaveOnboardingPayload } from '../../hydra';

export enum ACTIONS {
  SELECT_PROFILE = 'SELECT_PROFILE',
  SELECT_TOPIC = 'SELECT_TOPIC',

  SELECT_GOAL = 'SELECT_GOAL',
  SELECT_ROLE = 'SELECT_ROLE',
  SELECT_APPLICATIONS = 'SELECT_APPLICATIONS',
  SELECT_SECONDARY_TOPICS = 'SELECT_SECONDARY_TOPICS',
  SELECT_TOPIC_KNOWLEDGE = 'SELECT_TOPIC_KNOWLEDGE',
  CLEAR_TOPIC_KNOWLEDGE = 'CLEAR_TOPIC_KNOWLEDGE',
  SELECT_THEORY_PREFERENCE = 'SELECT_THEORY_PREFERENCE',
  SELECT_TIME_EFFORT = 'SELECT_TIME_EFFORT',
  ADD_EMAIL = 'ADD_EMAIL',
  SET_PROMO_CODES = 'SET_PROMO_CODES',
  SET_PREFILLED_CONTENT = 'SET_PREFILLED_CONTENT',
  SET_BILLING_PREFERENCE = 'SET_BILLING_PREFERENCE',
  UPDATE_PARTNER_SIGNUP_CODE = 'UPDATE_PARTNER_SIGNUP_CODE',
  UPDATE_SHOULD_SKIP_PAYMENT = 'UPDATE_SHOULD_SKIP_PAYMENT',
  UPDATE_IS_APP_USER = 'UPDATE_IS_APP_USER',
  UPDATE_PAYMENT_EMAIL = 'UPDATE_PAYMENT_EMAIL',
  UPDATE_SETUP_INTENT = 'UPDATE_SETUP_INTENT',
  LOGOUT_WITH_ERROR_TOAST = 'LOGOUT_WITH_ERROR_TOAST',
  SHOW_EMAIL_VALID_ERROR_TOAST = 'SHOW_EMAIL_VALID_ERROR_TOAST',
  SET_LEARNING_PLAN = 'SET_LEARNING_PLAN',
}

export const parameterisedActions = {
  [ACTIONS.LOGOUT_WITH_ERROR_TOAST]:
    ({ logout }: { logout: (arg0: { redirectUri?: string }) => void }) =>
    (_: ContextType, event: EventType) => {
      // @ts-expect-error didn't type the onError response event;
      const errorMessage = event?.data?.response?.data?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      }

      setTimeout(() => {
        logout({});
      }, 1000);
    },
};

export default {
  [ACTIONS.SELECT_TOPIC]: assign<ContextType, EventType>((_, event) => ({
    topic: (event as { topic: string })?.topic,
  })),
  [ACTIONS.SELECT_GOAL]: assign<ContextType, EventType>({
    goal: (_, event) => (event as { goal: string })?.goal,
  }),
  [ACTIONS.SELECT_PROFILE]: assign<ContextType, EventType>({
    profile: (_, event) => (event as { profile: string })?.profile,
  }),
  [ACTIONS.SELECT_ROLE]: assign<ContextType, EventType>({
    role: (_, event) => (event as { role: string })?.role,
  }),
  [ACTIONS.SELECT_APPLICATIONS]: assign<ContextType, EventType>({
    applications: (_, event) =>
      (event as { applications: string[] })?.applications,
  }),
  [ACTIONS.SELECT_SECONDARY_TOPICS]: assign<ContextType, EventType>({
    secondaryTopics: (_, event) =>
      (event as { secondaryTopics: string[] })?.secondaryTopics,
  }),
  [ACTIONS.SELECT_TOPIC_KNOWLEDGE]: assign(
    (context: ContextType, event: EventType) => {
      const { topicKnowledge, topic } = event as {
        topicKnowledge: string;
        topic: string;
      };
      const existingTopicKnowledge = context.knowledgeOfTopics.find(
        (item) => item?.topic === topic
      );
      if (existingTopicKnowledge) {
        return {
          knowledgeOfTopics: context.knowledgeOfTopics.map((item) =>
            item?.topic === topic ? { topic, knowledge: topicKnowledge } : item
          ),
        };
      }
      return {
        knowledgeOfTopics: [
          ...context.knowledgeOfTopics,
          {
            topic,
            knowledge: topicKnowledge,
          },
        ],
      };
    }
  ),
  [ACTIONS.CLEAR_TOPIC_KNOWLEDGE]: assign<ContextType, EventType>(() => {
    return {
      knowledgeOfTopics: [],
    };
  }),
  [ACTIONS.SELECT_THEORY_PREFERENCE]: assign<ContextType, EventType>({
    theoryPreference: (_, event) =>
      (event as { theoryPreference: 'theory' | 'practice' })?.theoryPreference,
  }),
  [ACTIONS.SELECT_TIME_EFFORT]: assign<ContextType, EventType>({
    timeEffort: (_, event) => (event as { timeEffort: string })?.timeEffort,
  }),
  [ACTIONS.ADD_EMAIL]: assign<ContextType, EventType>({
    email: (_, event) => (event as { email: string })?.email,
  }),
  [ACTIONS.SET_BILLING_PREFERENCE]: assign(
    (_: ContextType, event: EventType) => {
      return {
        isMonthlySubscription: (event as { isMonthlySubscription: boolean })
          ?.isMonthlySubscription,
        paymentIntentClientSecret: null,
      };
    }
  ),
  [ACTIONS.SET_PROMO_CODES]: assign(() => {
    const currentUrl = new URL(window.location.href);
    const learnMorePartnerCode = currentUrl.searchParams.get('learnModeCode');

    // we set this on init rather than on validating the code to ensure we track those who disengage before validation
    Analytics.setUserProperties({
      hasPartnerCode: !!learnMorePartnerCode,
    });

    return {
      partnerCode: learnMorePartnerCode,
    };
  }),
  [ACTIONS.SET_PREFILLED_CONTENT]: assign(() => {
    const currentUrl = new URL(window.location.href);
    const topic = currentUrl.searchParams.get('topic')?.toUpperCase();
    if (!topic || !Object.keys(TOPICS).includes(topic)) {
      return {};
    }

    const projectSlug = currentUrl.searchParams
      .get('projectslug')
      ?.toLowerCase();

    return {
      topic: topic,
      projectSlug: projectSlug,
      knowledgeOfTopics: [null],
    };
  }),

  [ACTIONS.UPDATE_PARTNER_SIGNUP_CODE]: assign(
    (_: ContextType, event: EventType) => {
      const saveOrFinishResults = (
        event as {
          data: {
            partnerCode: string;
            hasPartnerCode: boolean;
            partnerWelcomeMessage: string;
          };
        }
      )?.data;

      if (saveOrFinishResults?.hasPartnerCode) {
        return {
          partnerCode: saveOrFinishResults.partnerCode,
          partnerWelcomeMessage: saveOrFinishResults.partnerWelcomeMessage,
        };
      }
      return {
        partnerCode: null,
        partnerWelcomeMessage: null,
      };
    }
  ),
  [ACTIONS.UPDATE_SHOULD_SKIP_PAYMENT]: assign(
    (_: ContextType, event: EventType) => {
      const saveOrFinishResults = (
        event as {
          data: {
            shouldSkipPayment: boolean;
          };
        }
      )?.data;

      return {
        shouldSkipPayment: saveOrFinishResults?.shouldSkipPayment,
      };
    }
  ),
  [ACTIONS.UPDATE_IS_APP_USER]: assign((_: ContextType, event: EventType) => {
    const saveOrFinishResults = (
      event as {
        data: {
          isAppUser: boolean;
        };
      }
    )?.data;

    return { isAppUser: saveOrFinishResults?.isAppUser };
  }),
  [ACTIONS.UPDATE_PAYMENT_EMAIL]: assign<ContextType, EventType>(
    (_: ContextType, event: EventType) => {
      const handlePaymentCallbackResponse = (
        event as { data: { email: string } }
      )?.data;
      return {
        email: handlePaymentCallbackResponse?.email,
      };
    }
  ),
  [ACTIONS.UPDATE_SETUP_INTENT]: assign<ContextType, EventType>(
    (_: ContextType, event: EventType) => {
      const paymentIntentCallbackResponse = (
        event as {
          data: {
            paymentIntentClientSecret: string;
            discountPercentage?: number;
          };
        }
      )?.data;
      return {
        paymentIntentClientSecret:
          paymentIntentCallbackResponse?.paymentIntentClientSecret,
        discountPercentage: paymentIntentCallbackResponse?.discountPercentage,
      };
    }
  ),
  [ACTIONS.SHOW_EMAIL_VALID_ERROR_TOAST]: () => {
    toast.warn(() => (
      <EmailInvalidToast goToLogin={() => (window.location.href = '/auth')} />
    ));
  },
  [ACTIONS.SET_LEARNING_PLAN]: assign<ContextType, EventType>((_, event) => {
    if ('data' in event) {
      const saveOrFinishResults = event.data as SaveOnboardingPayload;
      return {
        learningPlan: saveOrFinishResults?.learningPlan,
        planExplanation: saveOrFinishResults?.planExplanation,
      };
    }
    return {};
  }),
};
