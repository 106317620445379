import React from 'react';
import styled from 'styled-components';
import { LabelUppercase } from '../elements/Text';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-one_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import Button from '../elements/Button';
import { SmallTooltip } from '../Tooltip';
import XTerm from './XTerm';
import Loader from '../Loader';
import usePythonRunner from './use-python-runner';
import { StyledAceEditor } from '../SQLPlayground/SQLCodeEditor';
import { Header } from '../Activity/Question/common';

import ReactAce from 'react-ace/lib/ace';
import Tabs from '../elements/Tabs';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  background: ${(props) => props.theme.colors.bgDefault};
  border-left: 1px solid ${(props) => props.theme.colors.zinc800};
  flex-direction: column;
`;

const EditorWrapper = styled.div<{
  shouldCentre?: boolean;
}>`
  height: CALC(50% - 59px);
  width: 100%;
  padding-top: 16px;
  padding-bottom: 2px;

  ${({ shouldCentre }) =>
    shouldCentre &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
  `}
`;

const OutputWrapper = styled.div`
  height: CALC(50% - 59px);
  padding: 10px;
`;

const SectionHeader = styled(LabelUppercase)`
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.divider};
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 20px 10px 20px;
  justify-content: space-between;
`;

const ControlsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const ControlsButton = styled(Button)`
  margin-top: 0px;
  padding: 12px;
  p {
    font-size: 12px !important;
  }
  svg {
    width: 16px;
  }
  line-height: 16px;
`;

const Icon = styled.i<{
  isDisabled?: boolean;
}>`
  color: ${(props) => props.theme.colors.textRegular};
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    `}
`;

const Controls = ({
  isAwaitingInput,
  onReset,
  isRunning,
  onRun = () => {},
  onStop = () => {},
  canReset,
}: {
  isAwaitingInput: boolean;
  onReset: () => void;
  onStop: () => void;
  isRunning: boolean;
  onRun: () => void;
  canReset: boolean;
}) => {
  return (
    <ControlsRow>
      <ControlsGroup>
        {canReset && (
          <>
            <Icon
              onClick={onReset}
              isDisabled={isRunning}
              className={`fa-solid fa-rotate-left playground-icon-reset`}
            />
            <SmallTooltip
              noArrow
              anchorSelect=".playground-icon-reset"
              place="top"
            >
              Start over
            </SmallTooltip>
          </>
        )}

        <Icon
          onClick={onStop}
          isDisabled={!isRunning}
          className={`fa-solid fa-stop playground-icon-stop`}
        />
        <SmallTooltip noArrow anchorSelect=".playground-icon-stop" place="top">
          Stop the code
        </SmallTooltip>
      </ControlsGroup>
      <ControlsGroup>
        <ControlsButton
          label={
            isAwaitingInput
              ? 'Waiting for terminal input'
              : isRunning
              ? 'Running'
              : 'Run'
          }
          style={{
            minWidth: 72,
          }}
          onClick={onRun}
          type={'secondary'}
          isDisabled={isRunning}
          isLoading={isRunning}
          faIcon={isRunning ? '' : 'fa-solid fa-circle-play'}
          tooltip={
            isAwaitingInput
              ? 'Write in the terminal, then press Enter'
              : isRunning
              ? 'Wait for the code to finish'
              : ''
          }
          showIconBeforeLabel
        />
      </ControlsGroup>
    </ControlsRow>
  );
};

export default function PythonPlayground({
  setupPy = '',
  code,
  onCodeChange,
  onResult,
  onResetCode,
  isReadOnly = false,
  headerText = 'Use the editor and run your code to answer',
  shouldPreloadPyodide = true,
}: {
  setupPy?: string;
  code: string;
  onCodeChange: (code: string) => void;
  onResult: (result: string, code: string) => void;
  onResetCode: () => void;
  isReadOnly?: boolean;
  headerText?: string;
  shouldPreloadPyodide?: boolean;
}) {
  const terminalRef = React.useRef(null);

  const writeToTerminal = React.useCallback((data: string) => {
    if (terminalRef.current) {
      // @ts-expect-error - terminal is not typed
      terminalRef.current.write(data);
    }
  }, []);

  const onResultWrapper = React.useCallback(
    (result: string) => {
      onResult(result, code);
    },
    [code, onResult]
  );

  const {
    isRunning,
    isAwaitingInput,
    runCode,
    sendInput,
    interruptExecution,
    base64EncodedPlot,
  } = usePythonRunner({
    onOutput: writeToTerminal,
    onResult: onResultWrapper,
    shouldPreloadPyodide,
  });

  React.useEffect(() => {
    if (base64EncodedPlot) {
      setSelectedTabIndex(1);
    }
  }, [base64EncodedPlot]);

  const onRunCode = React.useCallback(() => {
    if (terminalRef.current) {
      // @ts-expect-error - terminal is not typed
      terminalRef.current.reset();
    }
    runCode(setupPy + '\n' + code);
  }, [code, runCode, setupPy]);

  const onInterrupt = React.useCallback(() => {
    if (terminalRef.current) {
      // @ts-expect-error - terminal is not typed
      terminalRef.current.reset();
    }
    interruptExecution();
  }, [interruptExecution]);

  const onReset = React.useCallback(() => {
    if (terminalRef.current) {
      // @ts-expect-error - terminal is not typed
      terminalRef.current.reset();
    }
    onResetCode();
  }, [onResetCode]);

  const onInput = React.useCallback(
    (input: string) => {
      sendInput(input);
    },
    [sendInput]
  );

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  return (
    <Wrapper>
      <Header text={headerText} />
      <Divider />
      <PythonEditor
        isLoading={false}
        code={code}
        isRunning={isRunning}
        onCodeChange={onCodeChange}
        isReadOnly={isReadOnly}
      />
      <Controls
        isAwaitingInput={isAwaitingInput}
        isRunning={isRunning}
        onStop={onInterrupt}
        onRun={onRunCode}
        onReset={onReset}
        canReset={!isReadOnly}
      />
      <Divider />
      <OutputWrapper>
        {base64EncodedPlot ? (
          <Tabs
            options={['Terminal', 'Plots']}
            selectedOptionIndex={selectedTabIndex}
            onClick={setSelectedTabIndex}
          />
        ) : (
          <SectionHeader>Terminal</SectionHeader>
        )}
        <div
          style={{
            visibility: selectedTabIndex != 0 ? 'hidden' : 'visible',
            height:
              selectedTabIndex != 0
                ? '0'
                : base64EncodedPlot
                ? 'CALC(100% - 40px)'
                : '100%',
          }}
        >
          <XTerm
            ref={terminalRef}
            isAwaitingInput={isAwaitingInput}
            onInput={onInput}
          />
        </div>
        <div
          style={{
            height: selectedTabIndex === 1 ? 'CALC(100% - 50px)' : 0,
            overflowY: 'scroll',
          }}
        >
          <img
            src={base64EncodedPlot}
            style={{ width: '90%', margin: '0% 5% 0% 5%' }}
          />
        </div>
      </OutputWrapper>
    </Wrapper>
  );
}

const PythonEditor = ({
  code,
  onCodeChange,
  isRunning,
  isLoading,
  isReadOnly,
}: {
  code: string;
  onCodeChange: (code: string) => void;
  isRunning: boolean;
  isLoading: boolean;
  isReadOnly: boolean;
}) => {
  const editorRef = React.useRef<null | ReactAce>(null);
  if (isLoading) {
    return (
      <EditorWrapper shouldCentre>
        <Loader />
      </EditorWrapper>
    );
  }

  return (
    <EditorWrapper>
      <StyledAceEditor
        ref={editorRef}
        name="python-editor"
        mode="python"
        // @ts-expect-error - ace editor is not properly typed
        theme="one_dark"
        placeholder="Write your Python code here"
        value={code}
        onChange={onCodeChange}
        commands={[]}
        onSelectionChange={() => {
          if (editorRef?.current) {
            document.dispatchEvent(
              new CustomEvent('editorselectionchange', {
                detail: {
                  selection: editorRef.current.editor.getSelectedText(),
                  section: editorRef.current.editor.getValue(),
                },
              })
            );
          }
        }}
        readOnly={isRunning || isReadOnly}
        tabSize={2}
        width={'100%'}
        height={'100%'}
        fontSize={15}
        showPrintMargin={false}
        editorProps={{ $blockScrolling: Infinity }}
        enableBasicAutocompletion
        enableLiveAutocompletion
        wrapEnabled
        focus
      />
    </EditorWrapper>
  );
};
