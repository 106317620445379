import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  PillButton,
  ProgressBar,
  ButtonsWrapper,
  CenteredContainer,
  Header,
  ReadableContainer,
} from './common';

import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';
import { THEORY_PREFERENCE_OPTIONS } from '../config';

export default function AskTheory() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_THEORY,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={5} />
        <LabelUppercase>Step 5</LabelUppercase>
        <Header>{THEORY_PREFERENCE_OPTIONS.question}</Header>
        <ButtonsWrapper>
          {Object.entries(THEORY_PREFERENCE_OPTIONS.options).map(([key, option]) => (
            <PillButton
              key={key}
              label={option.label}
              isSelected={state.context.theoryPreference === option.value}
              onClick={() => {
                send({
                  type: EVENTS.SELECT_THEORY_PREFERENCE,
                  theoryPreference: option.value,
                });
                send({
                  type: EVENTS.CONTINUE_ASK_THEORY,
                });
              }}
            />
          ))}
        </ButtonsWrapper>
      </CenteredContainer>
    </ReadableContainer>
  );
}
