import styled from 'styled-components';
import { LeftSection, EnkiLogo, Nav, NavbarWrapper, CareeristLogo } from '.';

const IS_CAREERIST = process.env.REACT_APP_IS_CAREERIST === 'true';

const CenteredSection = styled(LeftSection)`
  width: 100%;
  justify-content: center;
`;

export default function Navbar({ className }: { className?: string }) {
  return (
    <NavbarWrapper className={className}>
      <div className="inner">
        <Nav>
          <CenteredSection>
            {IS_CAREERIST ? <CareeristLogo /> : <EnkiLogo />}
          </CenteredSection>
        </Nav>
      </div>
    </NavbarWrapper>
  );
}
