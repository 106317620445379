import React from 'react';
import {
  getProsumerPublicUpsellData,
  StaticLearningPlanUnit,
  StaticPlanExplanationResponse,
  UserPayload,
} from '../hydra';
import useURLQuery from '../hooks/use-url-query';

type ProsumerPublicUpsellType = {
  emailToken: string | null;
  user: UserPayload | null;
  learningPlan: StaticLearningPlanUnit[] | null;
  planExplanation: StaticPlanExplanationResponse | null;
  loadProsumerPublicData: () => Promise<void>;
  isLoadingPlan: boolean;
  shouldGetTrial: boolean;
  hasSubscription: boolean;
};

const initialContext: ProsumerPublicUpsellType = {
  emailToken: null,
  user: null,
  learningPlan: null,
  planExplanation: null,
  loadProsumerPublicData: async () => {},
  isLoadingPlan: false,
  shouldGetTrial: true,
  hasSubscription: false,
};

export const ProsumerPublicUpsell =
  React.createContext<ProsumerPublicUpsellType>(initialContext);

export const ProsumerPublicUpsellProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const queryParams = useURLQuery();
  const queryParamEmailToken = queryParams.get('emailToken');

  const [emailToken] = React.useState<string | null>(queryParamEmailToken);
  const [user, setUser] = React.useState<UserPayload | null>(null);
  const [learningPlan, setLearningPlan] =
    React.useState<StaticLearningPlanUnit[] | null>(null);
  const [planExplanation, setPlanExplanation] =
    React.useState<StaticPlanExplanationResponse | null>(null);
  const [isLoadingPlan, setIsLoadingPlan] = React.useState(false);
  const [shouldGetTrial, setShouldGetTrial] = React.useState(false);
  const [hasSubscription, setHasSubscription] = React.useState(false);

  const loadProsumerPublicData = async () => {
    if (!emailToken) {
      throw new Error('No email token');
    }

    try {
      setIsLoadingPlan(true);
      const response = await getProsumerPublicUpsellData({
        emailToken,
      });

      setUser(response.user);
      setLearningPlan(response.learningPlan);
      setPlanExplanation(response.planExplanation);
      setShouldGetTrial(response.shouldGetTrial);
      setHasSubscription(response.hasSubscription);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLoadingPlan(false);
    }
  };

  return (
    <ProsumerPublicUpsell.Provider
      value={{
        emailToken,
        user,
        learningPlan,
        planExplanation,
        loadProsumerPublicData,
        isLoadingPlan,
        shouldGetTrial,
        hasSubscription,
      }}
    >
      {children}
    </ProsumerPublicUpsell.Provider>
  );
};
