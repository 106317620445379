import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import Button from './elements/Button';
import { LabelUppercase, P, PSmall } from './elements/Text';

export const CardHeader = styled(P)`
  color: ${prop('theme.colors.text')};
  font-weight: 700;
  margin-top: 0;
`;

export const CardLabelUppercase = styled(LabelUppercase)`
  margin-top: 17px;
  color: ${prop('theme.colors.text')};
  opacity: 0.6;
`;

export const CardPSmall = styled(PSmall)`
  opacity: 0.6;
  color: ${prop('theme.colors.text')};
  padding-bottom: 0px;
  margin-top: 0px;
`;

const CardLayout = styled.div<{ isLocked: boolean }>`
  padding: 0;
  display: flex;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ color }) =>
    color ? color : prop('theme.colors.bgActive')};
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  border: 1px solid ${prop('theme.colors.bgActive')};
  min-height: 250px;
  ${({ isLocked }) =>
    isLocked &&
    css`
      opacity: 0.6;
      cursor: default;
    `}
`;

export const CardIcon = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url});
  height: 32px;
  min-width: 32px;
  max-width: 32px;
  background-size: cover;
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const CardButton = styled(Button)<{
  isFirst: boolean;
  isLast: boolean;
}>`
  border-radius: 0;
  border-bottom: 0px;
  border-left: 0px;
  margin-top: 0px;

  flex: 1;

  ${({ isFirst }) => isFirst && `border-bottom-left-radius: 8px;`}
  ${({ isLast }) =>
    isLast && `border-bottom-right-radius: 8px; border-right: 0;`}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function ContentCard({
  children,
  buttonProps = [],
  color,
  isLocked,
}: {
  children: React.ReactNode;
  buttonProps:
    | {
        label: string;
        isDisabled: boolean;
        onClick: () => void;
        tooltip?: string;
      }[]
    | {
        label: string;
        href: string;
        tooltip?: string;
      }[];
  color?: string;
  isLocked?: boolean;
}) {
  return (
    <CardLayout color={color} isLocked={isLocked ?? false}>
      <ContentWrapper>{children}</ContentWrapper>
      <ButtonsWrapper>
        {buttonProps.map((props, i) => (
          <CardButton
            key={`card-button-${i}-${props.label}`}
            {...props}
            isFirst={i === 0}
            isLast={i === buttonProps.length - 1}
            color={'#FFFFFF'}
            type="secondary"
          />
        ))}
      </ButtonsWrapper>
    </CardLayout>
  );
}
