import React from 'react';
import styled from 'styled-components';
import {
  useHome,
  useGetProsumerProfile,
  useProjectsDashboard,
} from '../hooks/use-hydra';
import Button from '../components/elements/Button';
import { H1, H2, H3, P } from '../components/elements/Text';
import PageWrapper from '../components/PageWrapper';
import PageWithAsyncData from '../components/PageWithAsyncData';
import Navbar from '../components/Navbar';
import { Helmet } from 'react-helmet-async';
import {
  getActivityUrl,
  getAppliedProjectUrl,
  getHomePageTitle,
  getProsumerActivityUrl,
  getProsumerDashboardUrl,
  getTeamsDashboardUrl,
  projectsDashboardPath,
} from '../routing';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as Analytics from '../analytics';
import OnboardingTrackCard from '../components/Home/OnboardingTrackCard';
import {
  OnboardedTrackCard,
  ActiveTrackCard,
} from '../components/Home/TrackCard';
import ProsumerContentModuleCard from '../components/Home/ProsumerContentModuleCard';
import OnboardingModal from '../components/Onboarding';
import {
  ActiveTeamsTrackWithProgress,
  AppliedProjectStat,
  HomePayload,
  joinProsumerContent,
  LearningPlan as LearningPlanType,
  OnboardingTrack,
  ProsumerContentModule,
} from '../hydra';
import CTACard from '../components/Home/CTACard';
import StreakCard from '../components/Home/StreakCard';
import { AppliedProjects } from './ProjectsDashboard';
import { useHistory } from 'react-router-dom';
import LearningPlan from '../components/LearningPlan/LearningPlan';
import {
  LEARNING_PLAN_UNIT_TYPE,
  LearningPlanUnit,
} from '../components/LearningPlan/LearningPlanUnit';
import useAuth from '../hooks/use-auth';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

day.extend(utc);

const ScrollablePageWrapper = styled.div`
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const PageContentWrapper = styled(PageWrapper)`
  max-width: 1100px;
  margin: 0 auto 82px;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  gap: 32px;
  margin: 32px 0px;
`;

function Home() {
  const campQuery = useHome();
  const prosumerQuery = useGetProsumerProfile();
  const projectsDashboardState = useProjectsDashboard();

  Analytics.useTrackViewOnMount(Analytics.EVENTS.COMMON.VIEW_HOME);

  // we do "!campQuery.value" to check isloading such that we don't render the loader while re-fetching the data
  // this action makes the open onboarding modal to close when moving through the onboarding steps
  const isPageLoading =
    prosumerQuery.loading ||
    (campQuery.loading && !campQuery.value) ||
    projectsDashboardState.loading;

  return (
    <PageWithAsyncData
      isLoading={isPageLoading}
      error={
        campQuery.error || prosumerQuery.error || projectsDashboardState.error
      }
      retry={() => {
        campQuery.retry();
        prosumerQuery.retry();
      }}
      className={''}
    >
      <Helmet>
        <title>{getHomePageTitle()}</title>
      </Helmet>
      <Navbar
        breadCrumbs={[
          {
            label: 'Home',
            url: '/',
          },
        ]}
      />
      <Contents />
    </PageWithAsyncData>
  );
}

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  gap: 32px;
  margin-top: 36px;

  @media screen and (max-width: 664px) {
    display: flex;
    flex-direction: column;
  }
`;
const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
`;

const Contents = () => {
  const [openTeamsOnboarding, setOpenTeamsOnboarding] =
    React.useState<null | { task: string; track: OnboardingTrack }>(null);
  const { value: prosumerProfile, loading: isLoadingProsumer } =
    useGetProsumerProfile();
  const { value: teamsProfile, retry: refreshTeamsData } = useHome();
  const { value: projects, loading: isLoadingProjects } =
    useProjectsDashboard();

  const history = useHistory();
  const teamTracks = teamsProfile?.tracks ?? [];
  const onboardingTracks = teamsProfile?.onboardingTracks ?? [];
  const onboardedUnstartedTracks = onboardingTracks.filter(
    (oc) =>
      oc.onboarding.survey.every(({ isCompleted }) => isCompleted) &&
      oc.onboarding.slack.isCompleted
  );
  const tracksToOnboard = onboardingTracks.filter(
    (oc) =>
      oc.onboarding.survey.some(({ isCompleted }) => !isCompleted) ||
      !oc.onboarding.slack.isCompleted
  );

  const prosumerContentModules = prosumerProfile?.contentModules ?? [];
  const appliedProjects = projects ?? [];
  const [shouldShowAllModules, setShouldShowAllModules] = React.useState(false);

  const allTracks: Array<
    | { type: 'notonboarded-teams'; track: OnboardingTrack }
    | {
        type: 'team';
        track: ActiveTeamsTrackWithProgress;
      }
    | {
        type: 'onboarded-prosumer';
        track: OnboardingTrack;
      }
    | {
        type: 'prosumer';
        module: ProsumerContentModule;
      }
  > = [
    ...tracksToOnboard.map((track) => ({
      track,
      type: 'notonboarded-teams' as const,
    })),
    ...teamTracks.map((track) => ({ track, type: 'team' as const })),
    ...onboardedUnstartedTracks.map(
      (track) => ({ track, type: 'onboarded-prosumer' } as const)
    ),
    ...prosumerContentModules.map(
      (module) => ({ module, type: 'prosumer' } as const)
    ),
  ];

  const learningPlan = prosumerProfile?.profile.learning_plan;
  const shouldShowLearningPlan =
    prosumerProfile?.profile.learning_plan &&
    !isLoadingProjects &&
    !isLoadingProsumer;

  const plan = useGetLearningPlan({
    learningPlan,
    projects: appliedProjects,
    contentModules: prosumerContentModules,
  });

  return (
    <ScrollablePageWrapper>
      <PageContentWrapper>
        <H1>Welcome!</H1>

        <Progress
          plan={plan}
          teamsProfile={teamsProfile}
          setOpenTeamsOnboarding={setOpenTeamsOnboarding}
        />
        {shouldShowLearningPlan && (
          <LearningPlan
            infoText={`✨ This unique learning plan is based on your goals and profile. It will update as Enki learns more about you!`}
            learningPlan={plan}
          />
        )}

        {appliedProjects.length > 0 && (
          <>
            <H3>Projects</H3>
            <AppliedProjects
              appliedProjects={appliedProjects.slice(0, 3)}
              onGoToProject={(slug) => history.push(getAppliedProjectUrl(slug))}
            />

            <CenteredRow>
              <Button
                onClick={() => history.push(projectsDashboardPath)}
                label={'Show All Projects'}
              />
            </CenteredRow>
          </>
        )}
        <H3>Modules</H3>
        <Modules
          openTeamsOnboarding={openTeamsOnboarding}
          setOpenTeamsOnboarding={setOpenTeamsOnboarding}
          allTracks={allTracks.slice(0, shouldShowAllModules ? undefined : 3)}
          refreshTeamsData={refreshTeamsData}
        />
        {allTracks.length > 3 && (
          <CenteredRow>
            <Button
              onClick={() => setShouldShowAllModules(!shouldShowAllModules)}
              label={shouldShowAllModules ? 'Hide Modules' : 'Show All Modules'}
            />
          </CenteredRow>
        )}

        <Help />
      </PageContentWrapper>
    </ScrollablePageWrapper>
  );
};

function Progress({
  plan,
  teamsProfile,
  setOpenTeamsOnboarding,
}: {
  plan: LearningPlanUnit[];
  teamsProfile: HomePayload | null | undefined;
  setOpenTeamsOnboarding: (
    value: {
      task: string;
      track: OnboardingTrack;
    } | null
  ) => void;
}) {
  const history = useHistory();

  const [nextIncompleteOnboardingTrack] = (teamsProfile?.onboardingTracks ?? [])
    .filter((track) => day.utc(track.endedAt).isAfter())
    .sort((a, b) => (day.utc(a.endedAt).isBefore(day.utc(b.endedAt)) ? 1 : -1));

  if (nextIncompleteOnboardingTrack) {
    return (
      <Row>
        <CTACard
          title={nextIncompleteOnboardingTrack.name}
          nextItemTitle={`Onboard to ${nextIncompleteOnboardingTrack.contentTitle}`}
          description={nextIncompleteOnboardingTrack.description}
          imageUrl={nextIncompleteOnboardingTrack.imageUrl}
          dashboardUrl={null}
          progressText={`${nextIncompleteOnboardingTrack.lessonsCount} lessons`}
          primaryButton={{
            ctaLabel: 'Onboard',
            onCTAClick: () =>
              setOpenTeamsOnboarding({
                task: 'track-onboarding',
                track: nextIncompleteOnboardingTrack,
              }),
          }}
          secondaryButton={null}
        />
        <StreakCard />
      </Row>
    );
  }

  const [nextTrack] = (teamsProfile?.tracks ?? []).sort((a, b) =>
    day.utc(a.endedAt).isBefore(day.utc(b.endedAt)) ? 1 : -1
  );

  if (nextTrack) {
    const isCompleted =
      nextTrack.completedLessonsCount === nextTrack.lessonsCount;

    const progressDescription =
      nextTrack.completedLessonsCount === 0 ||
      nextTrack.completedLessonsCount === nextTrack.lessonsCount
        ? `${nextTrack.lessonsCount} lessons`
        : `${nextTrack.completedLessonsCount}/${nextTrack.lessonsCount} lessons`;

    return (
      <Row>
        <CTACard
          title={nextTrack.name}
          nextItemTitle={
            nextTrack.firstUnattemptedActivity?.activityName ||
            nextTrack.contentTitle
          }
          description={nextTrack.description}
          imageUrl={nextTrack.imageUrl}
          dashboardUrl={getTeamsDashboardUrl(nextTrack.slug)}
          progressText={progressDescription}
          primaryButton={{
            ctaLabel: nextTrack.firstUnattemptedActivity?.activitySlug
              ? nextTrack.hasSubmission
                ? 'Continue'
                : 'Start'
              : 'Completed!',
            onCTAClick: () => {
              if (nextTrack.firstUnattemptedActivity?.activitySlug) {
                history.push(
                  getActivityUrl(
                    nextTrack.slug,
                    nextTrack.firstUnattemptedActivity?.missionSlug,
                    nextTrack.firstUnattemptedActivity?.activitySlug
                  )
                );
              } else {
                history.push(getTeamsDashboardUrl(nextTrack.slug));
              }
            },
          }}
          secondaryButton={
            !isCompleted
              ? {
                  ctaLabel: 'Dashboard',
                  onCTAClick: () =>
                    history.push(getTeamsDashboardUrl(nextTrack.slug)),
                }
              : null
          }
        />
        <StreakCard />
      </Row>
    );
  }

  const nextPlanItem =
    plan.find((item) => !item.isCompleted) ?? plan[plan.length - 1];

  if (!nextPlanItem) {
    return <StreakCard />;
  }

  return (
    <Row>
      <CTACard
        title={nextPlanItem.moduleTitle}
        nextItemTitle={nextPlanItem.nextItemTitle}
        description={nextPlanItem.description}
        imageUrl={nextPlanItem.imageUrl}
        dashboardUrl={nextPlanItem.dashboardUrl}
        progressText={nextPlanItem.progressDescription}
        primaryButton={{
          ctaLabel: !nextPlanItem.isCompleted
            ? nextPlanItem.isInProgress
              ? 'Continue'
              : 'Start'
            : 'Dashboard',
          onCTAClick: nextPlanItem.onClick,
        }}
        secondaryButton={
          nextPlanItem.type === LEARNING_PLAN_UNIT_TYPE.CONTENT_MODULE &&
          !nextPlanItem.isCompleted
            ? {
                ctaLabel: 'Dashboard',
                onCTAClick: () => {
                  history.push(nextPlanItem.dashboardUrl ?? '');
                },
              }
            : null
        }
      />
      <StreakCard />
    </Row>
  );
}

function Modules({
  openTeamsOnboarding,
  setOpenTeamsOnboarding,
  allTracks,
  refreshTeamsData,
}: {
  openTeamsOnboarding: { task: string; track: OnboardingTrack } | null;
  setOpenTeamsOnboarding: (
    value: {
      task: string;
      track: OnboardingTrack;
    } | null
  ) => void;
  allTracks: Array<
    | {
        type: 'notonboarded-teams';
        track: OnboardingTrack;
      }
    | {
        type: 'team';
        track: ActiveTeamsTrackWithProgress;
      }
    | {
        type: 'onboarded-prosumer';
        track: OnboardingTrack;
      }
    | {
        type: 'prosumer';
        module: ProsumerContentModule;
      }
  >;
  refreshTeamsData: () => void;
}) {
  return (
    <CardsContainer>
      {openTeamsOnboarding && (
        <OnboardingModal
          onRefreshData={refreshTeamsData}
          onboardingTask={openTeamsOnboarding}
          onClose={() => setOpenTeamsOnboarding(null)}
        />
      )}
      {allTracks.map((t) => {
        if (t.type === 'notonboarded-teams') {
          return (
            <OnboardingTrackCard
              key={`onboarding-track-card-${t.track.slug}`}
              track={t.track}
              setOnboardingTrack={() =>
                setOpenTeamsOnboarding({
                  task: 'track-onboarding', // this is redundant atm, as the community onboarding has been deprecated
                  track: t.track,
                })
              }
            />
          );
        }
        if (t.type === 'team') {
          return (
            <ActiveTrackCard
              key={`track-card-${t.track.slug}`}
              track={t.track}
            />
          );
        }
        if (t.type === 'onboarded-prosumer') {
          return (
            <OnboardedTrackCard
              key={`track-card--${t.track.slug}`}
              track={t.track}
            />
          );
        }
        if (t.type === 'prosumer') {
          return (
            <ProsumerContentModuleCard
              key={`prosumer-content-module-${t.module.contentId}`}
              module={t.module}
            />
          );
        }
        return <p>hi</p>;
      })}
    </CardsContainer>
  );
}

function Help() {
  return (
    <>
      <hr style={{ width: '100%' }} />
      <H2>Have any questions?</H2>
      <P>E-mail us via the button below.</P>
      <div style={{ width: 'fit-content' }}>
        <Button
          label="Get in touch"
          type="primary"
          onClick={() => window.open('mailto:concierge@enki.com')}
        />
      </div>
    </>
  );
}

export default Home;

function useGetLearningPlan({
  learningPlan,
  projects,
  contentModules,
}: {
  learningPlan: LearningPlanType | null | undefined;
  projects: Array<AppliedProjectStat>;
  contentModules: Array<ProsumerContentModule>;
}): Array<LearningPlanUnit> {
  const { getTokenSilently } = useAuth();
  const history = useHistory();

  const joinModuleMutation = useMutation({
    mutationFn: async ({
      unit,
      module,
    }: {
      unit: LearningPlanType[0];
      module: ProsumerContentModule;
    }) => {
      if (!(unit.type === 'module')) {
        throw new Error('Invalid learning unit type');
      }
      try {
        const token = await getTokenSilently();
        const nextSlugs = await joinProsumerContent(token, {
          contentId: module.contentId,
        });
        return nextSlugs;
      } catch (err) {
        toast.error('Something went wrong');
        reportError(err);
      }
    },
  });

  const validLearningPlanUnits = (learningPlan ?? [])
    .map((unit) => {
      if (unit.type === 'project') {
        const project = projects.find((p) => p.slug === unit.slug);
        return project ? { unit, content: project } : null;
      }
      if (unit.type === 'module') {
        const module = contentModules.find((m) => m.contentSlug === unit.slug);
        return module ? { unit, content: module } : null;
      }
      return null;
    })
    .filter(
      (
        unit
      ): unit is
        | {
            unit: { type: 'project'; slug: string; reasoning: string };
            content: AppliedProjectStat;
          }
        | {
            unit: { type: 'module'; slug: string; reasoning: string };
            content: ProsumerContentModule;
          } => unit !== null
    );

  const learningPlanUnits = validLearningPlanUnits.map((plan) => {
    if (plan.unit.type === 'project') {
      const project = plan.content as AppliedProjectStat;
      const completedQuestions = project.completedQuestions ?? 0;
      const isCompleted = completedQuestions === project.question_count;
      const isInProgress =
        completedQuestions > 0 && completedQuestions < project.question_count;
      const progressDescription =
        completedQuestions === project.question_count || !completedQuestions
          ? `${project.question_count} exercises`
          : `${completedQuestions}/${project.question_count} questions`;

      return {
        type: LEARNING_PLAN_UNIT_TYPE.PROJECT,
        unitTitle: project?.name ?? '',
        nextItemTitle: project?.name ?? '',
        moduleTitle: project?.topic ?? '',
        subtitle: 'PROJECT',
        slug: plan.unit.slug,
        imageUrl: project?.image_url ?? '',
        description: project?.description ?? '',
        reasoning: plan.unit.reasoning,
        progressDescription,
        isCompleted,
        isInProgress,
        onClick: async () => {
          history.push(getAppliedProjectUrl(project.slug));
        },
        dashboardUrl: getAppliedProjectUrl(project.slug),
        isLocked: false,
      };
    }

    const module = plan.content as ProsumerContentModule;
    const completedLessons = module.completedLessonsCount ?? 0;
    const isCompleted = completedLessons === module.lessonsCount;
    const isInProgress =
      completedLessons > 0 && completedLessons < module.lessonsCount;

    const progressDescription =
      completedLessons === module.lessonsCount || !completedLessons
        ? `${module.lessonsCount} lessons`
        : `${completedLessons}/${module.lessonsCount} lessons`;

    return {
      type: LEARNING_PLAN_UNIT_TYPE.CONTENT_MODULE,
      unitTitle: module.contentTitle,
      nextItemTitle: module.nextActivityName || module.contentTitle,
      moduleTitle: module.contentTitle,
      subtitle: 'MODULE',
      slug: plan.unit.slug,
      imageUrl: module.contentImageUrl ?? '',
      description: module.contentDescription ?? '',
      reasoning: plan.unit.reasoning,
      progressDescription,
      isCompleted,
      isInProgress,
      onClick: async () => {
        if (!module.isJoined) {
          const nextSlugs = await joinModuleMutation.mutateAsync({
            unit: plan.unit,
            module,
          });
          if (nextSlugs?.next_activity_slug && nextSlugs?.next_mission_slug) {
            history.push(
              getProsumerActivityUrl(
                nextSlugs.next_mission_slug,
                nextSlugs.next_activity_slug
              )
            );
          }
        } else if (module.nextMissionSlug && module.nextActivitySlug) {
          history.push(
            getProsumerActivityUrl(
              module.nextMissionSlug,
              module.nextActivitySlug
            )
          );
        } else {
          // When they have already completed the entire module
          history.push(getProsumerDashboardUrl(module.contentSlug));
        }
      },
      dashboardUrl: getProsumerDashboardUrl(module.contentSlug),
      isLocked: false,
    };
  });

  return learningPlanUnits;
}
