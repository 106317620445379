import {
  ActivityContextFreeTextSQLPlaygroundQuestionAnswer,
  ActivityContextMultipleChoiceQuestionAnswer,
  ActivityContextType,
} from '..';
import { QUESTION_TYPE } from '../../../components/Activity/Question';
import { ActivitySubmission } from '../../../hydra';

const secondsBetweenTimestamps = (start: Date, end: Date) =>
  (end.getTime() - start.getTime()) / 1000;

const getSecondsSpent = (activity: ActivityContextType) =>
  activity.pageFocusTimestampIntervals.reduce(
    (total, [start, end = new Date()]) =>
      total + secondsBetweenTimestamps(start, end),
    0
  );

const parseSubmission = (activity: ActivityContextType): ActivitySubmission => {
  return {
    difficulty_rating: activity.submission.difficultyRating,
    feedback: activity.submission.feedback,
    activity_session_id: activity.mentorReview.sessionId,
    seconds_spent: getSecondsSpent(activity),
    activity_id: activity.id,
    answer_states: Object.fromEntries(
      Object.values(activity.submission.questionSlugToAnswer).map((answer) => {
        if (
          [
            QUESTION_TYPE.SQL_MULTIPLE_CHOICE,
            QUESTION_TYPE.PYTHON_MULTIPLE_CHOICE,
            QUESTION_TYPE.FREE_TEXT_SQL_PLAYGROUND,
          ].includes(answer.questionType)
        ) {
          const answerContext = answer as
            | ActivityContextMultipleChoiceQuestionAnswer
            | ActivityContextFreeTextSQLPlaygroundQuestionAnswer;
          return [
            answer.questionSlug,
            {
              value: answer.value,
              is_correct: answer.isCorrect,
              is_submitted: answer.isAnswered,
              submitted_at: null,
              working_code: answerContext.working_code,
            },
          ];
        } else if (answer.questionType === QUESTION_TYPE.LLM_PLAYGROUND) {
          return [
            answer.questionSlug,
            {
              value: answer.value,
              is_correct: answer.isCorrect,
              is_submitted: answer.isAnswered,
              submitted_at: null,
              result: answer.result,
              review: answer.review,
            },
          ];
        } else {
          return [
            answer.questionSlug,
            {
              value: answer.value,
              is_correct: answer.isCorrect,
              is_submitted: answer.isAnswered,
              submitted_at: null,
            },
          ];
        }
      })
    ),
  };
};

export default parseSubmission;
