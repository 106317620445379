import React, { useEffect } from 'react';
import { ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import FreeTextQuestion from './FreeText';
import SQLPlaygroundQuestion from './SQLPlaygroundQuestion';
import PythonPlaygroundQuestion from './PythonPlaygroundQuestion';

import { ActivityPayload } from '../../../hydra';
import FreeTextSQLPlaygroundQuestion from './FreeTextSQLPlaygroundQuestion';
import FreeTextSQLCodeQuestion from './FreeTextSQLCode';
import FreeTextPowerBIExternalQuestion from './FreeTextPowerBIExternal';
import FreeTextLookerEmbedQuestion from './FreeTextLookerEmbed';
import SheetsExternalQuestion from './SheetsExternal';
import LookerExternalQuestion from './LookerExternal';
import TableauExternalQuestion from './TableauExternal';
import FreeTextSheetsEmbedQuestion from './FreeTextSheetsEmbed';
import PythonMultipleChoiceQuestion from './PythonMultipleChoiceQuestion';
import SQLMultipleChoiceQuestion from './SQLMultipleChoiceQuestion';
import {
  AIWorkflow,
  MESSAGE_CONTEXT_TYPES,
} from '../../../contexts/ai-workflow';
import LLMPlaygroundQuestion from './LLMPlaygroundQuestion';
import StrictTextQuestion from './StrictText';
import FreeTextAIVerifiedQuestion from './FreeTextAIVerified';
import FreeTextSelfCheck from './FreeTextSelfCheck';

export enum QUESTION_TYPE {
  FREE_TEXT = 'FREE_TEXT',
  STRICT_TEXT = 'STRICT_TEXT',
  FREE_TEXT_AI_VERIFIED = 'FREE_TEXT_AI_VERIFIED',
  SQL_PLAYGROUND = 'PLAYGROUND',
  FREE_TEXT_SQL_PLAYGROUND = 'FREE_TEXT_PLAYGROUND',
  PYTHON_PLAYGROUND = 'PYTHON_PLAYGROUND',
  FREE_TEXT_SQL_CODE = 'FREE_TEXT_SQL_CODE',
  FREE_TEXT_POWERBI_EXTERNAL = 'FREE_TEXT_POWERBI_EXTERNAL',
  FREE_TEXT_LOOKER_EMBED = 'FREE_TEXT_LOOKER_EMBED',
  FREE_TEXT_SHEETS_EMBED = 'FREE_TEXT_GOOGLE_SHEETS_EXTERNAL',
  SHEETS_EXTERNAL = 'GOOGLE_SHEETS_EXTERNAL',
  LOOKER_EXTERNAL = 'LOOKER_EXTERNAL',
  FREE_TEXT_TABLEAU_EMBED = 'FREE_TEXT_TABLEAU_EMBED',
  TABLEAU_EXTERNAL = 'TABLEAU_EXTERNAL',
  PYTHON_MULTIPLE_CHOICE = 'PYTHON_MULTIPLE_CHOICE',
  SQL_MULTIPLE_CHOICE = 'SQL_MULTIPLE_CHOICE',
  LLM_PLAYGROUND = 'LLM_PLAYGROUND',
  FREE_TEXT_SELF_CHECK = 'FREE_TEXT_SELF_CHECK',
}

export type QuestionProps = {
  question: ActivityPayload['exercise']['questions'][0];
  getActivity: () => Promise<ActivityPayload>;
  submitAnswer: ({
    value,
    questionSlug,
  }: {
    value: string;
    questionSlug: string;
  }) => Promise<void>;
};

export default function ActivityQuestion({
  getActivity,
  submitAnswer,
}: {
  getActivity: QuestionProps['getActivity'];
  submitAnswer: QuestionProps['submitAnswer'];
}) {
  const [activity] = React.useContext(ActivityContext);
  const { setMessageContext } = React.useContext(AIWorkflow);
  const currentStep = activity.currentStep;
  const question =
    currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.exercise?.questions[currentStep?.questionIndex]
      : null;
  const answer =
    currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.submission.questionSlugToAnswer[currentStep?.questionSlug]
      : null;

  useEffect(() => {
    if (answer && currentStep?.type === ACTIVITY_STEP.QUESTION) {
      setMessageContext({
        context_type: MESSAGE_CONTEXT_TYPES.WORKING_QUESTION,
        context_content: {
          question_index: currentStep.questionIndex,
          attempted_answer: answer.value,
          is_correct: answer.isCorrect,
          working_code: { working_code: undefined, ...answer }.working_code,
        },
      });
    } else {
      setMessageContext(null);
    }
  }, [answer, currentStep, setMessageContext]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [question?.id]);

  if (!question || !answer) {
    // should not happen
    return null;
  }
  const questionProps = {
    key: question.id,
    question,
    getActivity,
    submitAnswer,
  };

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT) {
    return <FreeTextQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_AI_VERIFIED) {
    return <FreeTextAIVerifiedQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.STRICT_TEXT) {
    return <StrictTextQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.SQL_PLAYGROUND) {
    return <SQLPlaygroundQuestion {...questionProps} answer={answer} />;
  }
  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_SQL_PLAYGROUND) {
    return <FreeTextSQLPlaygroundQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.PYTHON_PLAYGROUND) {
    return <PythonPlaygroundQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_SQL_CODE) {
    return <FreeTextSQLCodeQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_POWERBI_EXTERNAL) {
    return (
      <FreeTextPowerBIExternalQuestion {...questionProps} answer={answer} />
    );
  }

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_LOOKER_EMBED) {
    return <FreeTextLookerEmbedQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_SHEETS_EMBED) {
    return <FreeTextSheetsEmbedQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.SHEETS_EXTERNAL) {
    return <SheetsExternalQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.LOOKER_EXTERNAL) {
    return <LookerExternalQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.TABLEAU_EXTERNAL) {
    return <TableauExternalQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.PYTHON_MULTIPLE_CHOICE) {
    return <PythonMultipleChoiceQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.SQL_MULTIPLE_CHOICE) {
    return <SQLMultipleChoiceQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.LLM_PLAYGROUND) {
    return <LLMPlaygroundQuestion {...questionProps} answer={answer} />;
  }

  if (answer?.questionType === QUESTION_TYPE.FREE_TEXT_SELF_CHECK) {
    return <FreeTextSelfCheck {...questionProps} answer={answer} />;
  }

  return null;
}
