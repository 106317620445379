import React from 'react';
import { ProsumerPublicUpsell } from '../../contexts/prosumer-public-upsell';
import PageWrapper from '../../components/PageWrapper';
import { StaticLearningPlan } from '../../components/LearningPlan/StaticLearningPlan';
import { publicUpsellBenefitsPath } from '../../routing';
import { useHistory } from 'react-router-dom';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';
import styled from 'styled-components';

const Page = styled(PageWrapper)`
  padding: 0 !important;
`;

export default function ProsumerPublicUpsellLearningPlan() {
  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_LEARNING_PLAN, {
    flowType: 'public_token',
  });

  const { learningPlan, planExplanation } =
    React.useContext(ProsumerPublicUpsell);

  const history = useHistory();

  if (!learningPlan) {
    return null;
  }
  return (
    <Page>
      <StaticLearningPlan
        learningPlan={learningPlan}
        planExplanation={planExplanation}
        onNext={() => {
          history.push(publicUpsellBenefitsPath);
          window.scrollTo(0, 0);
        }}
      />
    </Page>
  );
}
