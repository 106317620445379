import React, { useState, useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import useAuth from '../../hooks/use-auth';
import { v4 as uuidv4 } from 'uuid';

import FloatingButton from './FloatingButton';
import FloatingChat from './FloatingChat';
import { websoketURL } from '../../hydra';
import { EVENTS, trackEvent } from '../../analytics';
import { AIWorkflow } from '../../contexts/ai-workflow';

import { useUpdateEffect } from 'react-use';
export const STATES = {
  CONNECTING: 'CONNECTING',
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
};
const IS_CAREERIST = process.env.REACT_APP_IS_CAREERIST === 'true';
const CLIENT_ID = IS_CAREERIST ? 'CRST' : 'ENKI';

export default function AIChat({
  contentId,
  missionId,
  activityId,
  projectId,
  discussionContext,
  shouldShowChatIcon = true,
  className = '',
}) {
  const {
    messageContext,
    pendingUserMessage,
    setPendingUserMessage,
    pendingAiMessage,
    setPendingAiMessage,
    clearTempMessageContext,
    isChatOpen,
    setIsChatOpen,
    chat,
    setChat,
  } = React.useContext(AIWorkflow);

  const [didReceiveInitialMessages, setDidReceiveInitialMessages] =
    useState(false);

  const { user, getTokenSilently } = useAuth();

  const [inputMessage, setInputMessage] = useState('');
  const [isReceivingMessage, setIsReceivingMessage] = useState(false);

  const onInputChange = (value) => {
    setInputMessage(value);
    if (!value) {
      clearTempMessageContext();
    }
  };

  const getSocketUrl = useCallback(() => {
    async function generateSocketUrl() {
      const token = await getTokenSilently();
      return `${websoketURL}/?token=${token}&clientId=${CLIENT_ID}`;
    }
    return generateSocketUrl();
  }, [getTokenSilently]);

  const { sendJsonMessage, readyState } = useWebSocket(getSocketUrl, {
    share: true,
    queryParams: {
      ...(contentId && { contentId }),
      ...(missionId && { missionId }),
      ...(activityId && { activityId }),
      ...(projectId && { projectId }),
      discussionContext,
    },
    onMessage: (messageEvent) => {
      const data = JSON.parse(messageEvent.data);
      if (data.type === 'get-messages') {
        setChat({
          ...chat,
          id: data.chatId,
          messages: [...data.messages],
          dailyMessagesCount: data.dailyMessagesCount,
        });
        setDidReceiveInitialMessages(true);
      } else if (data.type === 'get-message-chunk') {
        const lastMessage = chat.messages[chat.messages.length - 1];

        setChat({
          ...chat,
          id: data.chatId,
          messages: [
            ...chat.messages.slice(0, chat.messages.length - 1),
            {
              ...lastMessage,
              content: data.message.content,
              is_error: data.message.is_error,
            },
          ],
        });
      } else if (data.type === 'get-message-chunk-end') {
        setChat({
          ...chat,
          dailyMessagesCount: chat.dailyMessagesCount + 1,
        });
        setIsReceivingMessage(false);
      } else {
        console.warn('Unknown message type', data);
      }
    },
    onOpen: () => {
      setIsReceivingMessage(false);
    },
    onClose: () => {
      setIsReceivingMessage(false);
    },
    onError: (event) => {
      setIsReceivingMessage(false);
      console.log('connection error', event);
    },
    shouldReconnect: () => true,
  });

  const sendMessage = useCallback(
    async (input) => {
      setInputMessage('');

      const messageToSend = {
        chat_id: chat.id,
        content: input,
        sender_user_id: user.id,
        is_bot: false,
        is_error: false,
        vote: 0,
        feedback: null,
        id: chat.messages.length + 1, // temp id for avoiding duplicate keys
        message_context: messageContext,
      };
      const messageToPopulate = {
        chat_id: chat.id,
        content: '',
        sender_user_id: null,
        is_bot: true,
        is_error: false,
        vote: 0,
        feedback: null,
        id: chat.messages.length + 2, // temp id for avoiding duplicate keys
      };
      setChat({
        ...chat,
        messages: [...chat.messages, messageToSend, messageToPopulate],
      });

      trackEvent({
        event: EVENTS.AI.MESSAGE.SEND,
        properties: {
          contentId,
          missionId,
          activityId,
          discussionContext,
          messageContextType: messageContext?.context_type ?? null,
          messageContextContent: messageContext?.context_content ?? null,
        },
      });

      setIsReceivingMessage(true);

      sendJsonMessage({
        action: 'send-message',
        chatId: chat.id,
        message: input,
        messageContext: messageContext,
        clientId: CLIENT_ID,
      });
      clearTempMessageContext();
    },
    [
      contentId,
      missionId,
      activityId,
      discussionContext,
      sendJsonMessage,
      setChat,
      chat,
      user.id,
      messageContext,
      clearTempMessageContext,
    ]
  );

  useUpdateEffect(() => {
    if (pendingUserMessage && !isReceivingMessage) {
      setInputMessage(pendingUserMessage);
      sendMessage(pendingUserMessage);
      setPendingUserMessage();
    }
  }, [pendingUserMessage]);

  React.useEffect(() => {
    if (pendingAiMessage) {
      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          {
            chat_id: chat.id,
            content: pendingAiMessage,
            sender_user_id: null,
            is_bot: true,
            is_error: false,
            vote: 0,
            feedback: null,
            id: uuidv4(),
            message_context: messageContext,
          },
        ],
      });
      setPendingAiMessage();
    }
  }, [pendingAiMessage, chat, setPendingAiMessage, setChat, messageContext]);

  if (shouldShowChatIcon && !isChatOpen) {
    return <FloatingButton onOpen={() => setIsChatOpen(true)} />;
  }

  if (!isChatOpen) {
    return null;
  }

  function getConnectionState() {
    if (readyState === ReadyState.OPEN && didReceiveInitialMessages) {
      return STATES.OPEN;
    }
    // if (reconnectionAttempt === maxReconnectionAttempts) {
    //   return STATES.CLOSED;
    // }
    return STATES.CONNECTING;
  }

  return (
    <FloatingChat
      className={className}
      onClose={() => {
        setIsChatOpen(false);
        clearTempMessageContext();
      }}
      inputMessage={inputMessage}
      setInputMessage={onInputChange}
      connnectionState={getConnectionState()}
      chat={chat}
      sendMessage={sendMessage}
      isReceivingMessage={isReceivingMessage}
      shouldLock={false}
      dailyMessagesCount={chat.dailyMessagesCount}
    />
  );
}
