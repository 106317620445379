export const TOPICS = {
  PYTHON: 'PYTHON',
  SHEETS: 'SHEETS',
  SQL: 'SQL',
  DATA_LITERACY: 'DATA_LITERACY',
  LOOKER: 'LOOKER',
  TABLEAU: 'TABLEAU',
  POWER_BI: 'POWER_BI',
  GEN_AI: 'GEN_AI',
};

export const PROFILES = {
  question: 'What describes you best?',
  options: {
    TECHNICAL: {
      value: 'TECHNICAL',
      label: 'Technical',
    },
    NON_TECHNICAL: {
      value: 'NON_TECHNICAL',
      label: 'Non-technical',
    },
    MANAGER: {
      value: 'MANAGER/EXECUTIVE',
      label: 'Manager / Exec',
    },
    STUDENT: {
      value: 'STUDENT',
      label: 'Student',
    },
    OTHER: {
      value: 'OTHER',
        label: 'Other',
    },
  },
};

export const GOAL_CATEGORIES = {
  OPTIMIZE: 'OPTIMIZE',
  START: 'START',
};

export const GOALS = {
  question: 'What\'s your top learning-related goal?',
  options: {
    OPTIMIZE_SKILLS: {
      value: 'OPTIMIZE_SKILLS',
      label: 'Be more productive at work or school',
      category: GOAL_CATEGORIES.OPTIMIZE,
    },
    SWITCH_TECHNICAL_ROLE: {
      value: 'SWITCH_TECHNICAL_ROLE',
      label: 'Switch to a technical role',
      category: GOAL_CATEGORIES.START,
    },
    PREPARE_FOR_INTERVIEWS: {
      value: 'PREPARE_FOR_INTERVIEWS',
      label: 'Prepare for interviews',
      category: GOAL_CATEGORIES.START,
    },
    ACCELERATE_CAREER: {
      value: 'ACCELERATE_CAREER',
      label: 'Advance my career',
      category: GOAL_CATEGORIES.OPTIMIZE,
    },
    FIND_A_JOB: {
      value: 'FIND_A_JOB',
      label: 'Find a job',
      category: GOAL_CATEGORIES.START,
    },
    BUILD_LEARNING_HABIT: {
      value: 'BUILD_LEARNING_HABIT',
      label: 'Build a learning habit',
      category: GOAL_CATEGORIES.OPTIMIZE,
    },
    EXPLORE_TEAMS: {
      value: 'EXPLORE_TEAMS',
      label: "I'm testing Enki for my team",
      category: GOAL_CATEGORIES.OPTIMIZE,
    },
    OTHER: {
      value: 'OTHER',
      label: 'Other',
      category: GOAL_CATEGORIES.OPTIMIZE,
    },
  }
};

export const ROLES = {
  question: {
    [GOAL_CATEGORIES.OPTIMIZE]: 'What describes you best now?',
    [GOAL_CATEGORIES.START]: 'What role are you aiming for?'
  },
  options: {
    SOFTWARE_ENGINEER: {
      value: 'SOFTWARE_ENGINEER',
      label: 'Software Engineer',
    },
    DATA_ANALYST: {
      value: 'DATA_ANALYST',
      label: 'Data Analyst',
    },
    DESIGNER: {
      value: 'DESIGNER',
      label: 'Designer',
    },
    MARKETING: {
      value: 'MARKETING',
      label: 'Marketing',
    },
    PRODUCT_MANAGER: {
      value: 'PRODUCT_MANAGER',
      label: 'Product Manager',
    },
    SALES: {
      value: 'SALES',
      label: 'Sales',
    },
    FINANCE: {
      value: 'FINANCE',
      label: 'Finance',
    },
    OPERATIONS: {
      value: 'OPERATIONS',
      label: 'Operations',
    },
    SUPPORT: {
      value: 'SUPPORT',
      label: 'Support',
    },
    QA: {
      value: 'QA',
      label: 'QA',
    },
    OTHER: {
      value: 'OTHER',
      label: 'Other',
    },
  }
};

export const TIME_EFFORT = {
  questionFormat: 'How much time can you dedicate to learning {topic}?',
  options: {
    UNSURE: {
      value: 'UNSURE',
      label: 'Unsure',
    },
    MINUTES_5_10: {
      value: 'MINUTES_5_10',
      label: '5-10 minutes / day',
    },
    MINUTES_20_30: {
      value: 'MINUTES_20_30',
      label: '20-30 minutes / day',
    },
    MORE: {
      value: 'MORE',
      label: 'More',
    },
  }
};
export const COMPLETE_BEGINNER_PLACEMENT_OPTION = 'complete_beginner';
export const KNOWS_SOME_PLACEMENT_OPTION = 'knows_some';
export const COMFORTABLE_WITH_PLACEMENT_OPTION = 'comfortable_with';
export const ADVANCED_PLACEMENT_OPTION = 'advanced';
export const THEORY_PREFERENCE_OPTIONS = {
  question: 'Do you prefer starting from theory or practice?',
  options: {
    THEORY: { value: 'theory', label: 'I want a theory refresher' },
    PRACTICE: { value: 'practice', label: 'Dive into practice' },
  }
};

// Note!
// The values of the options must match the tags in the backend
// The tags at the moment don't have ids and instead are unique defined by their labels,
// so for now we're using the labels as the values.
export const APPLICATIONS = {
  questionFormat: 'What applications of {topic} are most relevant to you?',
  options: {
    ANALYZE_DATA: { value: 'analyze data', label: 'analyze data' },
    BUILD_REPORTS: { value: 'build reports and dashboards', label: 'build reports and dashboards' },
    DEVELOP_STRATEGIES: { value: 'develop business strategies', label: 'develop business strategies' },
    AUTOMATE_TASKS: { value: 'automate tasks and workflows', label: 'automate tasks and workflows' },
    CREATE_CONTENT: { value: 'create content', label: 'create content' },
    MEASURE_PERFORMANCE: { value: 'measure performance', label: 'measure performance' },
  },
};

export const TOPICS_DATA = {
  primaryTopicQuestion: 'What do you want to learn first?',
  options: {
    [TOPICS.PYTHON]: {
      label: 'Python',
      subtitle: 'Coding and data analysis',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Python'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Python?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Python'),
      knowledgeQuestion: 'What describes your Python knowledge best?',
      faIcon: 'fa-solid fa-code',
      topic: TOPICS.PYTHON,
      iconUrl: 'https://img.enkipro.com/dbe4a7588876258aef7966c62dc67e68.png',
      coloredIconUrl:
        'https://img.enkipro.com/02f06ab40ceb0797206fdade3903f009.png',
      placementTest: {
        header: 'Choose all that are true about your Python knowledge',
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: 'I know some Python.',
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm confident writing Python programs",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
    [TOPICS.SQL]: {
      label: 'SQL',
      subtitle: 'Managing and extracting data',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'SQL'),
      secondaryTopicQuestion: 'Do you want to learn other skills after SQL?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'SQL'),
      knowledgeQuestion: 'What describes your SQL knowledge best?',
      faIcon: 'fa-solid fa-database',
      topic: TOPICS.SQL,
      iconUrl: 'https://img.enkipro.com/80a6a34160ca3ad5e31ccba0cdcb653b.png',
      placementTest: {
        header: 'What is your experience with SQL?',
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: 'I know some SQL.',
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm comfortable writing SQL queries.",
            value: COMFORTABLE_WITH_PLACEMENT_OPTION,
          },
          {
            label: 'I can handle complex SQL easily.',
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
    [TOPICS.GEN_AI]: {
      label: 'Generative AI',
      subtitle: 'Improve workflows with AI',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Generative AI'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Generative AI?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Generative AI'),
      knowledgeQuestion: 'What describes your Generative AI knowledge best?',
      faIcon: 'fa-solid fa-robot',
      topic: TOPICS.GEN_AI,
      iconUrl: 'https://img.enkipro.com/a30c0770136afec29306d5728d17fb3d.png',
      placementTest: {
        header: 'What is your experience with Generative AI?',
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: "I've written a few prompts before.",
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm comfortable writing complex prompts.",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
      applications: [
        'automating workflows',
        'copywriting',
        'finance',
        'marketing',
        'Other',
      ],
    },
    [TOPICS.SHEETS]: {
      label: 'Spreadsheets',
      subtitle: 'Working with table data',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Spreadsheets'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Spreadsheets?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Spreadsheets'),
      knowledgeQuestion: 'What describes your Spreadsheets knowledge best?',
      faIcon: 'fa-solid fa-file-spreadsheet',
      topic: TOPICS.SHEETS,
      iconUrl: 'https://img.enkipro.com/3b081c6024bde04a199fbbb0416a0e14.png',
      placementTest: {
        header: 'What is your experience with Spreadsheets?',
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: 'I can do basic tasks like creating tables or formulas.',
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm comfortable with spreadsheets.",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
    [TOPICS.DATA_LITERACY]: {
      label: 'Data Analytics',
      subtitle: 'Data best practices',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Data Analytics'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Data Analytics?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Data Analytics'),
      knowledgeQuestion: 'What describes your Data Analytics knowledge best?',
      faIcon: 'fa-solid fa-chart-line',
      topic: TOPICS.DATA_LITERACY,
      iconUrl: 'https://img.enkipro.com/91c86556f4b8645ea64753c8301ddd95.png',
      placementTest: {
        header: "What's your experience with data analysis?",
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: "I've worked with data a little, like making basic observations.",
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm confident analyzing and interpreting data effectively.",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
    [TOPICS.TABLEAU]: {
      label: 'Tableau',
      subtitle: 'Visual Data Analysis',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Tableau'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Tableau?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Tableau'),
      knowledgeQuestion: 'What describes your Tableau knowledge best?',
      faIcon: 'fa-solid fa-chart-bar',
      topic: TOPICS.TABLEAU,
      iconUrl: 'https://img.enkipro.com/d4807185a2a162aec22f868044b47585.png',
      placementTest: {
        header: "What's your experience with Tableau?",
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: "I've created a few simple visualizations",
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm confident building dashboards in Tableau.",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
    [TOPICS.POWER_BI]: {
      label: 'Power BI',
      subtitle: 'Business Intelligence',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Power BI'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Power BI?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Power BI'),
      knowledgeQuestion: 'What describes your Power BI knowledge best?',
      faIcon: 'fa-solid fa-chart-pie',
      topic: TOPICS.POWER_BI,
      iconUrl: 'https://img.enkipro.com/f470316005d0ee81790c12de3edb6820.png',
      placementTest: {
        header: "What's your experience with Power BI?",
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: "I've built a few simple reports or dashboards.",
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm comfortable creating complex Power BI dashboards.",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
    [TOPICS.LOOKER]: {
      label: 'Looker',
      subtitle: 'Explore business data',
      applicationQuestion: APPLICATIONS.questionFormat.replace('{topic}', 'Looker'),
      secondaryTopicQuestion: 'Do you want to learn other skills after Looker?',
      effortQuestion: TIME_EFFORT.questionFormat.replace('{topic}', 'Looker'),
      knowledgeQuestion: 'What describes your Looker knowledge best?',
      faIcon: 'fa-solid fa-search',
      iconUrl: 'https://img.enkipro.com/071e973f2b87d1f37e6eb60c20692926.png',
      topic: TOPICS.LOOKER,
      placementTest: {
        header: "What's your experience with Looker?",
        options: [
          {
            label: 'Beginner!',
            value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          },
          {
            label: "I've explored some Looker reports or data.",
            value: KNOWS_SOME_PLACEMENT_OPTION,
          },
          {
            label: "I'm confident creating reports in Looker.",
            value: ADVANCED_PLACEMENT_OPTION,
          },
        ],
      },
    },
  }
};