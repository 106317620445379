import { useMutation } from 'react-query';
import { ActivityPayload } from '../hydra';
import React from 'react';
import { ACTIVITY_ACTION, ActivityActionType } from '../contexts/activity';
import { reportError } from '../crash-reporter';

const TIMEOUT = 1000 * 20; // 20 seconds
const POLL_INTERVAL = 1000 * 2; // 2 seconds

export function useFreeTextValidationPoller({
  getActivity,
  dispatch,
}: {
  getActivity: () => Promise<ActivityPayload>;
  dispatch: (value: ActivityActionType) => void;
}) {
  const [isPolling, setIsPolling] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const pollMutation = useMutation({
    mutationFn: async () => {
      const newActivityPayload = await getActivity();

      return newActivityPayload;
    },
  });

  const pollActivity = React.useCallback(
    async (questionSlug: string) => {
      setIsPolling(true);
      setError(null);
      let hasUpdatedReview = false;
      let timePassed = 0;

      while (!hasUpdatedReview && timePassed < TIMEOUT) {
        const newestActivityPayload = await pollMutation.mutateAsync();

        await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
        timePassed += POLL_INTERVAL;
        if (
          reviewIsDefined({ activity: newestActivityPayload, questionSlug })
        ) {
          hasUpdatedReview = true;
          dispatch({
            type: ACTIVITY_ACTION.REFRESH_SUBMISSION,
            payload: {
              activity: newestActivityPayload,
            },
          });
        }

        if (timePassed >= TIMEOUT && !error) {
          setError(
            'Something went wrong while validating your answer. Please try again.'
          );
          reportError(new Error('Timeout while polling for validation'));
        }
      }

      setIsPolling(false);
    },
    [dispatch, pollMutation, setError, error, setIsPolling]
  );

  return {
    pollActivity,
    isPolling,
    error,
  };
}

export function useLLMPlaygroundValidationPoller({
  getActivity,
  dispatch,
}: {
  getActivity: () => Promise<ActivityPayload>;
  dispatch: (value: ActivityActionType) => void;
}) {
  const [isPolling, setIsPolling] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const pollMutation = useMutation({
    mutationFn: async () => {
      const newActivityPayload = await getActivity();

      return newActivityPayload;
    },
  });

  const pollActivity = React.useCallback(
    async (questionSlug: string) => {
      setIsPolling(true);
      setError(null);
      let hasUpdatedResult = false;
      let hasUpdatedReview = false;
      let timePassed = 0;

      while (!hasUpdatedResult && timePassed < TIMEOUT) {
        const newestActivityPayload = await pollMutation.mutateAsync();

        await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
        timePassed += POLL_INTERVAL;
        if (
          resultIsDefined({ activity: newestActivityPayload, questionSlug })
        ) {
          hasUpdatedResult = true;
          dispatch({
            type: ACTIVITY_ACTION.REFRESH_SUBMISSION,
            payload: {
              activity: newestActivityPayload,
            },
          });
        }
      }
      if (timePassed >= TIMEOUT) {
        setError(
          'Something went wrong while generating the output. Please try again.'
        );
        reportError(new Error('Timeout while polling for validation'));
        setIsPolling(false);
        return;
      }

      while (!hasUpdatedReview && timePassed < TIMEOUT) {
        const newestActivityPayload = await pollMutation.mutateAsync();

        await new Promise((resolve) => setTimeout(resolve, 3000));
        timePassed += POLL_INTERVAL;
        if (
          reviewIsDefined({ activity: newestActivityPayload, questionSlug })
        ) {
          hasUpdatedReview = true;
          dispatch({
            type: ACTIVITY_ACTION.REFRESH_SUBMISSION,
            payload: {
              activity: newestActivityPayload,
            },
          });
        }

        if (timePassed >= TIMEOUT && !error) {
          setError(
            'Something went wrong while validating your answer. Please try again.'
          );
          reportError(new Error('Timeout while polling for validation'));
        }
      }

      setIsPolling(false);
    },
    [dispatch, pollMutation, setError, error, setIsPolling]
  );

  return {
    pollActivity,
    isPolling,
    error,
  };
}

function resultIsDefined({
  activity,
  questionSlug,
}: {
  activity: ActivityPayload;
  questionSlug: string;
}) {
  const latestQuestionSubmission = activity.latest_submission?.answer_states?.[questionSlug];
  return !!latestQuestionSubmission?.result;
}

function reviewIsDefined({
  activity,
  questionSlug,
}: {
  activity: ActivityPayload;
  questionSlug: string;
}) {
  const latestQuestionSubmission = activity.latest_submission?.answer_states?.[questionSlug];
  return !!latestQuestionSubmission?.review;
}
