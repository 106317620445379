import { QuestionProps } from '.';
import { BottomSpacer, SplitScreenQuestion, getQuestionText } from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import { H1 } from '../../elements/Text';
import React, { useCallback } from 'react';
import {
  AIWorkflow,
  MESSAGE_CONTEXT_TYPES,
} from '../../../contexts/ai-workflow';
import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextLLMPlaygroundQuestionAnswer,
} from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import MentorReview from '../MentorComment';
import LLMPlayground from '../../LLMPlayground';
import { usePreviousDistinct, useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import IncorrectAnswerToast from '../../Toasts/TryAgain';
import { useLLMPlaygroundValidationPoller } from '../../../hooks/use-activity-question-validation-polling';

export default function LLMPlaygroundQuestion({
  question,
  answer,
  getActivity,
  submitAnswer,
}: QuestionProps & { answer: ActivityContextLLMPlaygroundQuestionAnswer }) {
  const { setMessageContext } = React.useContext(AIWorkflow);
  const [activity, dispatch] = React.useContext(ActivityContext);
  const answerValue = answer.value;

  const prevAnswer = usePreviousDistinct(answer);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  const [isLoading, setIsLoading] = React.useState(false);

  const onPromptChange = React.useCallback(
    (prompt: string) => {
      dispatch({
        type: ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_LLM_PLAYGROUND,
        payload: {
          question,
          value: prompt
        },
      });
    },
    [dispatch, question]
  );

  const { pollActivity, error: validationError } =
    useLLMPlaygroundValidationPoller({
      getActivity,
      dispatch,
    });
  const onSubmitPrompt = React.useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dispatch({
      type: ACTIVITY_ACTION.CLEAR_RESULT_AND_REVIEW,
      payload: {
        question,
      },
    });

    await submitAnswer({
      value: answerValue,
      questionSlug: question.slug,
    });

    await pollActivity(answer.questionSlug);
    setIsLoading(false);
  }, [
    dispatch,
    setIsLoading,
    question,
    pollActivity,
    answer,
    answerValue,
    submitAnswer,
    isLoading,
  ]);

  const onHint = React.useCallback(() => {
    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.QUESTION_HINT,
      context_content: {
        question_index: qIndex,
        attempted_answer: answer.value,
        is_correct: answer.isCorrect,
        result: answer.result as string | null,
      },
    });
  }, [answer, qIndex, setMessageContext]);

  useUpdateEffect(() => {
    if (answer.questionSlug === prevAnswer?.questionSlug) {
      if (answer.isCorrect) {
        toast.success(
          answer.doesSatisfyAllRequirements === true
            ? 'Great job!'
            : 'Pretty good! See review for suggestions.'
        );
      } else if (answer.isCorrect === false) {
        toast.warn(() => <IncorrectAnswerToast onHint={onHint} />);
      }
    }
  }, [answer.isCorrect]);

  const onReset = useCallback(() => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_LLM_PLAYGROUND,
      payload: {
        question,
        value: ''
      },
    });
  }, [dispatch, question]);

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        <ReadableMarkdown md={getQuestionText(question)} />
        <BottomSpacer />
      </>
      <>
        <LLMPlayground
          userPrompt={answerValue}
          onPromptChange={onPromptChange}
          result={answer.result as string | null}
          review={answer.review as string | null}
          onSubmitPrompt={onSubmitPrompt}
          onReset={onReset}
          isLoading={isLoading}
          validationError={validationError}
        />
      </>
    </SplitScreenQuestion>
  );
}
