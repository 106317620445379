import React from 'react';
import useAuth from './use-auth';

export default function useAuthenticateSilently() {
  const {
    isAuthenticating,
    isAuthenticated,
    didAuthenticate,
    isInitialised,
    reAuthSilently,
  } = useAuth();

  const reAuth = React.useCallback(async () => {
    try {
      await reAuthSilently();
    } catch (error) {
      // @ts-expect-error - weird auth0 error
      if (error?.code === 'login_required') {
        return;
      }
      console.error(error);
    }
  }, [reAuthSilently]);

  React.useEffect(() => {
    if (isAuthenticating) {
      return;
    }

    if (!isInitialised || isAuthenticated || didAuthenticate) {
      return;
    }

    reAuth();
  }, [
    isAuthenticating,
    isAuthenticated,
    reAuth,
    isInitialised,
    didAuthenticate,
  ]);

  return null;
}
