// import { set } from 'es-cookie';
import { Auth0DecodedHash } from 'auth0-js';
import { trackPixelEvent } from '../../facebook';
import {
  checkEmailAvailability,
  createProsumerLearnModePaymentIntent,
  createProsumerLearnModeSubscription,
  finishSoloOnboarding,
} from '../../hydra';
import { saveSoloOnboarding } from '../../hydra';
import {
  APPLICATIONS,
  GOALS,
  GOAL_CATEGORIES,
  PROFILES,
  ROLES,
  THEORY_PREFERENCE_OPTIONS,
  TIME_EFFORT,
  TOPICS_DATA,
} from './config';
import { ContextType, OnboardQuestion } from './state-machine';

export enum SERVICES {
  HANDLE_PAYMENT_CALLBACK = 'HANDLE_PAYMENT_CALLBACK',
  HANDLE_AUTH_CALLBACK = 'HANDLE_SIGNUP_CALLBACK',
  INIT_PAYMENT = 'INIT_PAYMENT',
  SAVE_ONBOARDING_ANSWERS = 'SAVE_ONBOARDING_ANSWERS',
  CHECK_EMAIL_AVAILABILITY = 'CHECK_EMAIL_AVAILABILITY',
}

export const parameterisedServices = {
  [SERVICES.HANDLE_AUTH_CALLBACK]:
    ({
      handleAuthCallback,
      getTokenSilently,
    }: {
      handleAuthCallback: () => Promise<Auth0DecodedHash | null>;
      getTokenSilently: () => Promise<string>;
    }) =>
    async () => {
      await handleAuthCallback();
      const token = await getTokenSilently();
      const result = await finishSoloOnboarding(token);
      return {
        partnerCode: result.partnerSignupCode,
        partnerWelcomeMessage: result.partnerWelcomeMessage,
        hasPartnerCode: result.hasPartnerCode,
      };
    },
};

export default {
  [SERVICES.HANDLE_PAYMENT_CALLBACK]: async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentIntentId = urlParams.get('payment_intent');

    if (!paymentIntentId)
      throw new Error('Setup intent not found in query params');

    const { email, isTrial, currency, value, metaEventId } =
      await createProsumerLearnModeSubscription({
        paymentIntentId,
      });

    if (isTrial) {
      trackPixelEvent(
        'StartTrial',
        {
          currency,
          value,
        },
        metaEventId
      );
    }

    return { email };
  },

  [SERVICES.INIT_PAYMENT]: async (context: ContextType) => {
    // call hydra api to create stripe intent and apply discount code
    if (!context.email) {
      throw new Error('Sorry, we need your email first.');
    }

    const { paymentIntentClientSecret, discountPercentage } =
      await createProsumerLearnModePaymentIntent({
        userEmail: context.email,
        isMonthlySubscription: context.isMonthlySubscription,
      });

    return { paymentIntentClientSecret, discountPercentage };
  },

  [SERVICES.SAVE_ONBOARDING_ANSWERS]: async (context: ContextType) => {
    if (!context.email) {
      throw new Error('Email is required to save onboarding answers');
    }

    if (!context.topic) {
      throw new Error('Topic is required to save onboarding answers');
    }
    if (!context.goal) {
      throw new Error('Goal is required to save onboarding answers');
    }
    if (!context.role) {
      throw new Error('Role is required to save onboarding answers');
    }
    if (!context.applications) {
      throw new Error('Applications is required to save onboarding answers');
    }

    const topics = Object.values(TOPICS_DATA.options);
    const profileOptions = Object.values(PROFILES.options);
    const roleOptions = Object.values(ROLES.options);
    const goalOptions = Object.values(GOALS.options);
      const goalCategory =
    Object.values(GOALS.options).find((g) => g.value === context.goal)
      ?.category ?? GOAL_CATEGORIES.START;
    const applicationOptions = Object.values(APPLICATIONS.options);

    const selectedApplications = context.applications;
    const theoryOptions = Object.values(THEORY_PREFERENCE_OPTIONS.options);

    const topicKnowledgeOptions = [
      context.topic,
      ...context.secondaryTopics,
    ].map((t) => ({
      topic: t,
      question: TOPICS_DATA.options[t].knowledgeQuestion,
      options: TOPICS_DATA.options[t].placementTest.options,
    }));

    const timeEffortOptions = Object.values(TIME_EFFORT.options);

    const topicData = TOPICS_DATA.options[context.topic];

    const onboardingSteps: OnboardQuestion[] = [
      {
        id: 'prosumer-ask-primary-topic',
        question: TOPICS_DATA.primaryTopicQuestion,
        options: topics.map((t) => ({ value: t.topic, label: t.label })),
        answers: topics.map((t) => t.topic).filter((topic) => topic === context.topic),
      },
      {
        id: 'prosumer-ask-goal',
        question: GOALS.question,
        options: goalOptions,
        answers: goalOptions.map(({ value }) => value).filter((value) => value === context.goal),
      },
      {
        id: 'prosumer-ask-profile',
        question: PROFILES.question,
        options: profileOptions,
        answers: profileOptions.map(({ value }) => value).filter((value) => value === context.profile),
      },
      {
        id: 'prosumer-ask-role',
        question: ROLES.question[goalCategory],
        options: roleOptions,
        answers: roleOptions.map(({ value }) => value).filter((value) => value === context.role),
      },
      {
        id: 'prosumer-ask-applications',
        question: topicData.applicationQuestion,
        options: applicationOptions,
        answers: applicationOptions.map(({ value }) => value).filter((value) => selectedApplications.includes(value)),
      },
      {
        id: 'prosumer-ask-secondary-topics',
        question: topicData.secondaryTopicQuestion,
        options: topics.filter(({ topic }) => topic !== context.topic).map((t) => ({ value: t.topic, label: t.label })),
        answers: topics.filter(({ topic }) => context.secondaryTopics.includes(topic)).map((t) => t.topic),
      },
      ...topicKnowledgeOptions.map((topicOptions) => {
        return {
          id: `prosumer-ask-module-knowledge`,
          question: topicOptions.question,
          options: topicOptions.options,
          customData: {
            topic: topicOptions.topic,
          },
          answers: topicOptions.options.filter(({value}) => context.knowledgeOfTopics.find(
            (c) => c?.topic === topicOptions.topic && c?.knowledge === value
          )).map(({value}) => value)
        };
      }),
      {
        id: 'prosumer-ask-theory-preference',
        question: THEORY_PREFERENCE_OPTIONS.question,
        options: theoryOptions,
        answers: theoryOptions.map(({value}) => value).filter((value) => value === context.theoryPreference),
      },
      {
        id: 'prosumer-ask-time-effort',
        options: timeEffortOptions,
        question: topicData.effortQuestion,
        answers: timeEffortOptions.map(({value}) => value).filter((value) => value === context.timeEffort),
      },
    ];

    // eslint-disable-next-line no-debugger
    debugger;

    const onboardingResult = await saveSoloOnboarding({
      email: context.email,
      onboardingSteps: onboardingSteps,
      partnerSignupCode: context.partnerCode,
      projectSlug: context.projectSlug,
    });

    return onboardingResult;
  },
  
  [SERVICES.CHECK_EMAIL_AVAILABILITY]: async (context: ContextType) => {
    if (!context.email) {
      throw new Error('Email is required to check email availability');
    }

    return await checkEmailAvailability({ email: context.email });
  },
};
